import axiosInstance from '../RestClient';

const BASE_URI = '/creditUpdates';

class CreditUpdatesService {
	async list() {
		const uri = `${BASE_URI}/`;
		const response = await axiosInstance.get(uri);
		return response;
	}
	async listFromCreditId(recordId) {
		const uri = `${BASE_URI}/${recordId}`;
		const response = await axiosInstance.get(uri);
		return response;
	}
}
export default new CreditUpdatesService();