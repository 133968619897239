import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { MdOutlinePayments } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import CustomTypeahead from '../../components/CustomTypeahead';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import creditService from '../../services/credit.service';
import creditPaymentService from '../../services/creditPayment.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_DEBTS_REQUEST':
		return { ...state, loadingDebts: true };
	case 'FETCH_DEBTS_SUCCESS':
		return { ...state, credits: action.payload, loadingDebts: false };
	case 'FETCH_DEBTS_FAIL':
		return { ...state, loadingDebts: false, error: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, creditPayment: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function CreditPaymentDetail() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;

	const [{ loading, error, creditPayment, loadingDebts, credits }, dispatch] = useReducer(reducer, {
		credits: [],
		loadingDebts: true,
		creditPayment: {},
		loading: true,
		error: '',
	});

	const currencies = ['USD', 'UYU'];

	const [amount, setAmount] = useState();
	const [credit, setCredit] = useState();
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();
	const [formatedDate, setFormatedDate] = useState();
	const [comprobante, setComprobante] = useState();
	const [creditDue, setCreditDue] = useState();
	const [currency, setCurrency] = useState();
	const [generatedCreditDues] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await creditPaymentService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				setAmount(result.data.amount);
				setCredit(result.data.credit);
				setFolder(result.data.folder);
				setObservations(result.data.observations);
				setFormatedDate(result.data.date.substring(0, 10));
				setComprobante(result.data.comprobante);
				setCreditDue([result.data.creditDue]);
				setCurrency(result.data.currency);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
			// setdebts(result.data);
		};
		const fetchCredits = async () => {
			dispatch({ type: 'FETCH_DEBTS_REQUEST' });
			try {
				const result = await creditService.list();
				dispatch({ type: 'FETCH_DEBTS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchCredits();
		fetchData();
	}, [id]);

	async function editPaymentHandler() {
		navigate(`/AdminScreen/creditPayments/editCreditPayment/${id}`);
	}
	async function verComprobante() {
		if (comprobante) {
			window.open(comprobante.fileLink, '_blank', 'noopener,noreferrer');
		}
	}
	return (
		<div>
			{(loading || loadingDebts) ? (
				<LoadingBox></LoadingBox>
			) : (
				error ? (
					<MessageBox variant='danger'>{error}</MessageBox>
				) : null
			)}
			<div className='container admin-con align-items-center'>
				<Row style={{ margin: '0 0 2.5% 0' }}>
					<Col style={{ alignSelf: 'center' }}>
						<h2>
							<MdOutlinePayments></MdOutlinePayments>Pago:{creditPayment.customId}
						</h2>
					</Col>
					<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							style={{ backgroundColor: 'transparent!important' }}
							id='back-btn'
							className='btn m-2'
							value='back'
							onClick={() => navigate(-1)}
						>
							Atras
						</Button>
						<Button
							className='btn btn-dark m-2'
							value='Crear Cuenta'
							onClick={() => editPaymentHandler()}
						>
							Editar Pago
						</Button>
					</Col>
				</Row>
				<div className='jumbotron'>
					<Form onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Form.Group className='mb-3 col-6' controlId='amount'>
								<Form.Label>Monto</Form.Label>
								<Form.Control
									type='number'
									defaultValue={amount}
									disabled
									onChange={(e) => setAmount(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3 col-6' controlId='date'>
								<Form.Label>Fecha</Form.Label>
								<Form.Control
									type='date'
									disabled
									defaultValue={formatedDate}
								></Form.Control>
							</Form.Group>
							{credit ? (
								<Form.Group className='mb-3 col-6'>
									<Form.Label>Pasividad vinculada</Form.Label>
									<Form.Select value={credit._id} disabled>
										{credits.map((credit) => {
											return (
												<option key={credit._id} value={credit._id}>
													{credit.owner ? credit.customId : credit.customId}
												</option>
											);
										})}
										<option key='blankChoice' hidden value>
											{' '}
											Elige la Pasividad vinculada
										</option>
									</Form.Select>
								</Form.Group>
							) : (
								<Form.Group className='mb-3 col-6'>
									<Form.Label>Pasividad</Form.Label>
									<Form.Select value={credit} disabled>
										{credits.map((credit) => {
											return (
												<option key={credit._id} value={credit._id}>
													{credit.owner ? credit.customId : credit.customId}
												</option>
											);
										})}
										<option key='blankChoice' hidden value>
											{' '}
											No tiene Pasividad vinculada
										</option>
									</Form.Select>
								</Form.Group>
							)}
							<Form.Group className='mb-3 col-6 form-group required'>
								<Form.Label>Cuota Deuda</Form.Label>
								<CustomTypeahead
									id='credits'
									labelKey={(option) => `${option.customId}`}
									selected={creditDue}
									options={generatedCreditDues ? generatedCreditDues : []}
									placeholder='Buscar pasividad...'
									disabled
									linkToCreate=''
									newOption={null}
									newSelectionPrefix={null}
								/>
							</Form.Group>
							<Row>
								<Form.Group as={Col} className='mb-3 col-3' controlId='currency'>
									<Form.Label>Moneda</Form.Label>
									<Form.Select
										as='select'
										onChange={(e) => setCurrency(e.target.value)}
										value={currency}
										disabled
									>
										<option key='default'>Elige Moneda</option>
										{currencies.map((currency, index) => {
											return (
												<option key={index} value={currency}>
													{currency}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>
								<Form.Group as={Col} className='mb-3 col-6' controlId='folder'>
									<Form.Label>Carpeta</Form.Label>
									<Form.Control
										value={folder}
										disabled
										onChange={(e) => setFolder(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Button
									className='col-2'
									style={{ height: '30px', marginTop: '32px', padding: '0' }}
									disabled={!comprobante}
									onClick={() => verComprobante(creditPayment)}
								>
									Ver Comprobante
								</Button>
							</Row>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Observaciones</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									disabled
									value={observations}
									rows={10}
									onChange={(e) => setObservations(e.target.value)}
								></Form.Control>
							</Form.Group>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
}
export default CreditPaymentDetail;
