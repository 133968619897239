import React, { useReducer, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import { MdLibraryAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import GenericDropdown from '../../components/SelectDropdown';
import AccountService from '../../services/account.service';
import { getError, getUserInfo } from '../../utils';
import SpinnerOverlay from '../../components/SpinnerOverlay/SpinnerOverlay';

const reducer = (state, action) => {
	switch (action.type) {
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function AddAccount() {
	const navigate = useNavigate();
	const userInfo = getUserInfo();

	const types = ['Persona', 'Empresa'];
	const currencies = ['USD', 'UYU'];
	const createdBy = userInfo._id;
	const [selectedType, setSelectedType] = useState();
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [address, setAddress] = useState();
	const [phone, setPhone] = useState();
	const [folder, setFolder] = useState();
	const [contactName, setContactName] = useState();
	const [rut, setRut] = useState();
	const [currency, setCurrency] = useState();
	const [observations, setObservations] = useState();


	const [{ loadingCreate }, dispatch] = useReducer(reducer, {
		loadingCreate: false,
		error: '',
	});


	const addAccountHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await AccountService.create({
				type: selectedType,
				name,
				observations,
				createdBy,
				email,
				phone,
				address,
				folder,
				rut, //only empresas
				currency, //only empresas?
				contactName: contactName || name,
			});
			//crear contacto con la info
			dispatch({ type: 'CREATE_SUCCESS' });
			toast.success('Cuenta creada');
			navigate(-1);
		} catch (ex) {
			// handleClose();
			console.error(getError(ex));
			toast.error('Error al crear cuenta');
		}
	};

	// const [isInvalid, setIsInvalid] = useState(false);

	// const handleContactChange = (selected) => {
	//   const selectedContact = selected[0];
	//   const isValid = selectedContact ? contacts.includes(selectedContact) : false;
	//   setIsInvalid(!isValid);
	//   setMainContact(selected[0])
	// };

	const handleSelectedType = (e) => {
		setSelectedType(e.value);
	};

	const handleSelectedCurrency = (e) => {
		setCurrency(e.value);
	};
	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{loadingCreate && <SpinnerOverlay/>}
				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Crear Cuenta</title>
					</Helmet>
					
					<>
						<h1>
							<MdLibraryAdd></MdLibraryAdd>Crear Cuenta
						</h1>
						<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
							<div className='row align-items-center'>
								<Row className='mb-3'>
									<Form.Group className='mb-3 col-4 form-group required'>
										<GenericDropdown
											label='Tipo'
											items={types}
											selected={selectedType}
											onChange={handleSelectedType}
										/>
									</Form.Group>
								</Row>
								{selectedType === 'Persona' && (
									<>
										<Row className='mb-3'>
											<Form.Group
												as={Col}
												className='mb-3 col-6 form-group required'
												controlId='name'
											>
												<Form.Label>Nombre</Form.Label>
												<Form.Control onChange={(e) => setName(e.target.value)}></Form.Control>
											</Form.Group>
											<Form.Group as={Col} className='mb-3 col-6 form-group' controlId='email'>
												<Form.Label>Email</Form.Label>
												<Form.Control onChange={(e) => setEmail(e.target.value)}></Form.Control>
											</Form.Group>
										</Row>
										<Row>
											<Form.Group className='mb-3 col-6' controlId='address'>
												<Form.Label>Direccion</Form.Label>
												<Form.Control onChange={(e) => setAddress(e.target.value)}></Form.Control>
											</Form.Group>
											<Form.Group as={Col} className='mb-3 col-6' controlId='phone'>
												<Form.Label>Telefono</Form.Label>
												<Form.Control onChange={(e) => setPhone(e.target.value)}></Form.Control>
											</Form.Group>
											<Form.Group as={Col} className='mb-3 col-6' controlId='currency'>
												<GenericDropdown
													label='Moneda'
													items={currencies}
													selected={currency}
													onChange={handleSelectedCurrency}
												/>
											</Form.Group>
											<Form.Group as={Col} className='mb-3 col-12' controlId='folder'>
												<Form.Label>Carpeta</Form.Label>
												<Form.Control onChange={(e) => setFolder(e.target.value)}></Form.Control>
											</Form.Group>
											<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
												<Form.Label>Observaciones</Form.Label>
												<Form.Control
													type='text'
													as='textarea'
													rows={10}
													onChange={(e) => setObservations(e.target.value)}
												></Form.Control>
											</Form.Group>
										</Row>
									</>
								)}
								{selectedType === 'Empresa' && (
									<>
										<Row className='mb-3'>
											<Form.Group
												as={Col}
												className='mb-3 col-6 form-group required'
												controlId='name'
											>
												<Form.Label>Nombre de Cuenta</Form.Label>
												<Form.Control onChange={(e) => setName(e.target.value)}></Form.Control>
											</Form.Group>
											<Form.Group as={Col} className='mb-3 col-6 form-group required' controlId='rut'>
												<Form.Label>Rut</Form.Label>
												<Form.Control onChange={(e) => setRut(e.target.value)}></Form.Control>
											</Form.Group>
											<Form.Group
												as={Col}
												className='mb-3 col-6 form-group required'
												controlId='email'
											>
												<Form.Label>Nombre de Contacto</Form.Label>
												<Form.Control onChange={(e) => setContactName(e.target.value)}></Form.Control>
											</Form.Group>
											<Form.Group as={Col} className='mb-3 col-6 form-group' controlId='email'>
												<Form.Label>Email</Form.Label>
												<Form.Control onChange={(e) => setEmail(e.target.value)}></Form.Control>
											</Form.Group>
											<Form.Group className='mb-3 col-12 form-group' controlId='address'>
												<Form.Label>Direccion</Form.Label>
												<Form.Control onChange={(e) => setAddress(e.target.value)}></Form.Control>
											</Form.Group>
											<Form.Group as={Col} className='mb-3 col-6' controlId='phone'>
												<Form.Label>Telefono</Form.Label>
												<Form.Control onChange={(e) => setPhone(e.target.value)}></Form.Control>
											</Form.Group>
											<Form.Group as={Col} className='mb-3 col-6' controlId='currency'>
												<GenericDropdown
													label='Moneda'
													items={currencies}
													selected={currency}
													onChange={handleSelectedCurrency}
												/>
											</Form.Group>
											<Form.Group as={Col} className='mb-3 col-12' controlId='folder'>
												<Form.Label>Carpeta</Form.Label>
												<Form.Control onChange={(e) => setFolder(e.target.value)}></Form.Control>
											</Form.Group>
											<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
												<Form.Label>Observaciones</Form.Label>
												<Form.Control
													type='text'
													as='textarea'
													rows={10}
													onChange={(e) => setObservations(e.target.value)}
												></Form.Control>
											</Form.Group>
										</Row>
									</>
								)}
							</div>
							<Row className='modal-btns'>
								<Col>
									<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
									</Button>
								</Col>
								<Col>
									<Button className='guardar' onClick={addAccountHandler}>
									Agregar
									</Button>
								</Col>
							</Row>
						</Form>
					</>
				</div>
			</Modal>
		</div>
	);
}
export default AddAccount;
