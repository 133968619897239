import React, { useState, useEffect } from 'react';
import { Col, Dropdown, ButtonGroup, Button } from 'react-bootstrap';

const ListButtons = ({ buttonItems }) => {
	const [dropdownButtons, setDropdownButtons] = useState([]);

	const calculateButtonsInDropdown = () => {
		const windowWidth = window.innerWidth;
		if (windowWidth < 767) {
			setDropdownButtons(buttonItems);
		} else {
			setDropdownButtons(buttonItems.slice(2));
		}
	};

	const handleWindowResize = () => {
		calculateButtonsInDropdown();
	};

	useEffect(() => {
		calculateButtonsInDropdown();
		window.addEventListener('resize', handleWindowResize);
	}, [buttonItems]);

	return (
		<Col className='col-lg-6 d-flex justify-content-end'>
			<ButtonGroup>
				{window.innerWidth >= 767 &&
					buttonItems.slice(0, 2).map((button, index) => (
						<Button key={index} className='btn btn-dark m-2 btn-sm' onClick={button.onClick}>
							{button.label}
						</Button>
					))}

				{/* Render dropdown with the rest of the buttons */}
				{dropdownButtons.length > 0 && (
					<Dropdown>
						<Dropdown.Toggle className='btn btn-dark m-2 btn-sm'>Acciones</Dropdown.Toggle>
						<Dropdown.Menu>
							{/* Render dropdown buttons */}
							{dropdownButtons.map((button, index) => (
								<Dropdown.Item className='btn btn-sm' key={index} onClick={button.onClick}>
									{button.label}
								</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				)}
			</ButtonGroup>
		</Col>
	);
};

export default ListButtons;
