import axiosInstance from '../RestClient';

const BASE_URI = '/generatedCredits';

class GeneratedCreditService {
	async list() {
		const uri = `${BASE_URI}/`;
		const response = await axiosInstance.get(uri);
		return response;
	}
	async listFuture(query) {
		const uri = `${BASE_URI}/futureDues/search?${query}`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async create(payload) {
		const response = await axiosInstance.post(`${BASE_URI}/`, payload);
		return response;
	}
	async search(query) {
		const uri = `${BASE_URI}/search?${query}`;
		const response = await axiosInstance.get(uri);
		return response;
	}
}

export default new GeneratedCreditService();
