import React, { useState } from 'react';
import { Table, DropdownButton, Dropdown, Form, Modal, Row, Col, Button } from 'react-bootstrap';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import LoadingTableBox from '../components/LoadingTableBox';
import MessageBox from '../components/MessageBox';
import { Link } from 'react-router-dom';
import { showCurrency } from '../utils';
import Paginator from '../screens/Paginator/Paginator';
import moment from 'moment';
import Pagination from './Pagination';
import { downloadExcel } from 'react-export-table-to-excel';
import { FaFileExcel } from 'react-icons/fa';

const DynamicListTable = ({
	dataName, //dataName to get the columns names
	data, //database
	loading,
	error,
	count, //for paginator
	onPageChange, //for paginator
	page, //for paginator
	pageSize, //for paginator
	handleSort, //for central lists, ordering function
	direction,
	sortColumn,
	actionButtons, //seeDetails,editHandler,seeAttachment and deleteHandler
	showCheckboxColumn, //first column with checkboxes
	links, //enable links, in this case owner.name, name, and custom id
	frontPaginator, //frontPaginator
	exportOption, //export button, with modal
}) => {
	//para variables inside variables
	function getNestedValue(item, column) {
		const keys = column.split('.');
		let nestedValue = item;
		for (const key of keys) {
			if (
				nestedValue &&
				nestedValue[key] !== 'undefined' &&
				nestedValue[key] !== 0 &&
				nestedValue[key] !== null &&
				nestedValue[key] !== ''
			) {
				nestedValue = nestedValue[key];
			} else {
				return 'N/A';
			}
		}
		return nestedValue;
	}

	//to show date in format (DD-MM-YYYY)
	const formatValue = (value) => {
		if (value === true) {
			return 'Si';
		} else if (value === false) {
			return 'No';
		}

		const isValidDateFormat = moment.utc(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid();
		if (isValidDateFormat) {
			return moment.utc(value).format('DD-MM-YYYY');
		} else if (!value) {
			return 'N/A';
		} else {
			return value;
		}
	};

	//lista de columnas con traducciones a mostrar
	const columnMap = {
		accounts: {
			name: 'Nombre',
			type: 'Tipo',
			currency: 'moneda',
			folder: 'Carpeta',
		},
		contacts: {
			'account.name': 'Cuenta',
			name: 'Nombre',
			email: 'Email',
			address: 'Dirección',
			phone: 'Teléfono',
			folder: 'Carpeta',
		},
		contracts: {
			customId: 'Inquilino:Cuenta',
			amount: 'Monto',
			periodInit: 'Inicio',
			periodEnd: 'Fin',
			'owner.name': 'Inquilino',
			folder: 'Carpeta',
		},
		debts: {
			customId: 'Cuenta:Concepto',
			'owner.name': 'Deudor',
			capital: 'Capital',
			concept: 'Concepto',
			dues: 'Cuotas',
			amount: 'Monto cuota',
			date: 'Fecha',
			endDate: 'Fecha vto',
			periodicity: 'Periodicidad',
		},
		credits: {
			customId: 'Cuenta:Concepto',
			'owner.name': 'Acreedor',
			capital: 'Capital',
			concept: 'Concepto',
			dues: 'Cuotas',
			amount: 'Monto cuota',
			date: 'Fecha',
			endDate: 'Fecha vto',
			periodicity: 'Periodicidad',
			guaranteedAmount: 'Total Garantizado',
		},
		assets: {
			customId: 'Activo Fijo',
			price: 'Precio/Valor',
			type: 'Tipo',
			ubication: 'Ubicación',
			description: 'Descripción',
			'owner.name': 'Propietario',
			folder: 'Carpeta',
		},
		payments: {
			customId: 'ID',
			'debtDue.customId': 'Cuota',
			amount: 'monto',
			date: 'Fecha',
			folder: 'carpeta',
		},
		creditPayments: {
			customId: 'ID',
			'creditDue.customId': 'Cuota',
			amount: 'Monto',
			date: 'Fecha',
			folder: 'carpeta',
		},
		relWarranties: {
			'warranty.option': 'Opción',
			'warranty.debt.customId': 'Deuda',
			'warranty.asset.customId': 'Activo Fijo',
			'warranty.amount': 'Monto',
		},
		debtDues: {
			customId: 'Cuota',
			date: 'Fecha',
			interest: 'Interes',
			'debt.currency': 'Moneda',
			amortization: 'Amortización',
			amount: 'Monto',
			expiration: 'Vto',
			balance: 'Saldo',
			unpaidAmount: 'Monto pendiente',
			paid: 'Pago?',
		},
		creditDues: {
			customId: 'Cuota',
			date: 'Fecha',
			interest: 'Interes',
			'credit.currency': 'Moneda',
			amortization: 'Amortización',
			amount: 'Monto',
			expiration: 'Vto',
			unpaidAmount: 'Monto pendiente',
			balance: 'Saldo',
			paid: 'Pago?',
		},
		bills: {
			customId: 'Id',
			name: 'Nombre',
			amount: 'Monto',
			expirationDate: 'Vencimiento',
			type: 'Tipo',
			periodicity: 'Periodicidad',
			paid: 'Pago?',
		},
		assetSales: {
			customId: 'Id',
			lot: 'Padrón',
			amount: 'monto',
			'purchaser.name': 'Comprador',
			sumDebts: 'Total Deudas',
		},
	};

	const columnMapToExport = {
		accounts: {
			name: 'Nombre',
			type: 'Tipo',
			currency: 'moneda',
			folder: 'Carpeta',
		},
		contacts: {
			'account.name': 'Cuenta',
			name: 'Nombre',
			email: 'Email',
			address: 'Dirección',
			phone: 'Teléfono',
			folder: 'Carpeta',
		},
		contracts: {
			customId: 'Inquilino:Cuenta',
			currency: 'Moneda',
			amount: 'Monto',
			periodInit: 'Inicio',
			periodEnd: 'Fin',
			'owner.name': 'Inquilino',
			folder: 'Carpeta',
			previousDebt: 'Saldo Anterior',
			previousDebtDate: 'Fecha de saldo Anterior',
			field: 'detalles',
			paymentMethod: 'Método de pago',
			observations: 'Observaciones',
		},
		debts: {
			customId: 'Cuenta:Concepto',
			'owner.name': 'Deudor',
			capital: 'Capital',
			concept: 'Concepto',
			dues: 'Cuotas',
			currency: 'Moneda',
			amount: 'Monto cuota',
			date: 'Fecha',
			endDate: 'Fecha vto',
			periodicity: 'Periodicidad',
			previousDebt: 'Deuda Anterior',
			previousDebtDate: 'Fecha de Deuda Anterior',
			interest: '% Interés',
		},
		credits: {
			customId: 'Cuenta:Concepto',
			'owner.name': 'Acreedor',
			capital: 'Capital',
			concept: 'Concepto',
			dues: 'Cuotas',
			currency: 'Moneda',
			amount: 'Monto cuota',
			date: 'Fecha',
			endDate: 'Fecha vto',
			periodicity: 'Periodicidad',
			guaranteedAmount: 'Total Garantizado',
		},
		relWarranties: {
			'warranty.option': 'Opción',
			'warranty.debt.customId': 'Deuda',
			'warranty.debt.currency': 'Moneda Deuda',
			'warranty.asset.customId': 'Activo Fijo',
			'warranty.asset.currency': 'Moneda Activo',
			'warranty.amount': 'Monto',
		},
		debtDues: {
			'debt.customId': 'Deudor',
			'debt.concept': 'Concepto',
			customId: 'Cuota',
			'debt.currency': 'Moneda',
			amount: 'Monto',
			expiration: 'Vencimiento',
			unpaidAmount: 'Monto pendiente',
			paid: 'Pago',
		},
		payments: {
			customId: 'ID',
			'debtDue.customId': 'Cuota',
			'debt.currency': 'Moneda',
			amount: 'monto',
			date: 'Fecha',
			folder: 'carpeta',
			observations: 'Observaciones',
		},
		creditDues: {
			date: 'Fecha',
			'credit.customId': 'Deudor',
			'credit.concept': 'Concepto',
			customId: 'Cuota',
			'credit.currency': 'Moneda',
			amount: 'Monto',
			expiration: 'Vencimiento',
			unpaidAmount: 'Monto pendiente',
			paid: 'Pago',
		},
		creditPayments: {
			customId: 'ID',
			amount: 'Monto',
			date: 'Fecha',
			folder: 'carpeta',
			'credit.currency': 'Moneda',
			observations: 'Observaciones',
		},
		assets: {
			customId: 'Activo Fijo',
			currency: 'Moneda',
			price: 'Precio/Valor',
			type: 'Tipo',
			ubication: 'Ubicación',
			description: 'Descripción',
			'owner.name': 'Propietario',
			folder: 'Carpeta',
			observations: 'Observaciones',
		},
		bills: {
			customId: 'Id',
			name: 'Nombre',
			'asset.currency': 'Moneda',
			amount: 'Monto',
			expirationDate: 'Vencimiento',
			type: 'Tipo',
			periodicity: 'Periodicidad',
			paid: 'Pago?',
			observations: 'Observaciones',
		},
		assetSales: {
			customId: 'Id',
			lot: 'Padrón',
			'asset.currency': 'Moneda',
			amount: 'monto',
			'purchaser.name': 'Comprador',
			sumDebts: 'Total Deudas',
		},
	};
	const currencyColumns = [
		'amount',
		'capital',
		'interest',
		'amortization',
		'balance',
		'warranty.amount',
		'sumDebts',
	]; //lista de variables que tienen moneda
	const columnsToExport = Object.keys(columnMapToExport[dataName] || {});

	const columns = Object.keys(columnMap[dataName] || {});

	//checkboxes , se usa en el caso de alquileres
	async function toggleAllCheckboxes() {
		let allCheckboxes = document.getElementById('allCheckboxes');
		let checkboxes = document.getElementsByName(`checked${dataName}`);
		for (let i = 0; i < checkboxes.length; i++) {
			allCheckboxes.checked ? (checkboxes[i].checked = true) : (checkboxes[i].checked = false);
		}
	}

	//manage frontend pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(5); //maxPostsperPage
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

	//change page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	//to export
	const [selectedFields, setSelectedFields] = useState(['customId']);
	const [exportName, setExportName] = useState('');
	const todayDate = moment.utc().format('DD-MM-YYYY');
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [show, setShow] = useState(false);

	const handleDownloadExcel = () => {
		const selectedColumnMap = columnMapToExport[dataName];
		const header = selectedFields.map((field) => selectedColumnMap[field]);
		const body = data.map((item) =>
			selectedFields.map((field) => formatValue(getNestedValue(item, field))),
		);
		downloadExcel({
			fileName: exportName ? `${exportName}` : `${todayDate}- ${dataName}`,
			sheet: 'react-export-table-to-excel',
			tablePayload: {
				header,
				body,
			},
		});
		handleClose();
	};

	//show two colums of checkboxes in modal
	const columnsPerRow = 2;

	const rows = Array.from(
		{ length: Math.ceil(columnsToExport.length / columnsPerRow) },
		(_, rowIndex) =>
			columnsToExport.slice(rowIndex * columnsPerRow, (rowIndex + 1) * columnsPerRow),
	);

	return (
		<>
			{exportOption ? (
				<div className='container m-3'>
					<Row>
						<Col className='d-flex justify-content-end'>
							<Button
								size={'sm'}
								className='mb-1 '
								onClick={handleShow}
								style={{ backgroundColor: 'Green' }}
							>
								<FaFileExcel></FaFileExcel> Exportar
							</Button>
						</Col>
					</Row>
					<Modal show={show} onHide={handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>Exportar datos</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form.Group as={Row} className='mb-3 align-items-center'>
								<Form.Label column md={6}>
									Nombre del archivo:
								</Form.Label>
								<Col>
									<Form.Control
										type='text'
										placeholder='Ingrese el nombre'
										value={exportName}
										onChange={(e) => setExportName(e.target.value)}
									/>
								</Col>
							</Form.Group>
							<>
								<p>Marque los campos que desea incluir</p>
								{rows.map((row, rowIndex) => (
									<Row key={rowIndex}>
										{row.map((column) => (
											<Col key={column} md={6}>
												<Form.Group
													as={Row}
													className='mb-3 align-items-center'
													controlId={`fieldsToInclude_${column}`}
												>
													<Form.Check
														className='col-8'
														type='checkbox'
														value={column}
														label={columnMapToExport[dataName][column]}
														defaultChecked={column === 'customId'}
														disabled={column === 'customId'}
														onChange={(e) => {
															const selected = e.target.checked;
															setSelectedFields((prevSelectedFields) =>
																selected
																	? [...prevSelectedFields, column]
																	: prevSelectedFields.filter((field) => field !== column),
															);
														}}
													/>
												</Form.Group>
											</Col>
										))}
									</Row>
								))}
							</>
						</Modal.Body>
						<Modal.Footer>
							<Button variant='secondary' onClick={handleClose}>
								Cancelar
							</Button>
							<Button variant='primary' onClick={handleDownloadExcel}>
								Exportar XLS
							</Button>
						</Modal.Footer>
					</Modal>
				</div>
			) : (
				''
			)}
			<Table bordered hover responsive size='sm'>
				<thead>
					<tr>
						{showCheckboxColumn ? (
							<th className='last-col'>
								<Form.Check
									type={'checkbox'}
									id='allCheckboxes'
									onClick={() => toggleAllCheckboxes()}
								></Form.Check>
							</th>
						) : (
							''
						)}
						{columns.map((column) => (
							<th key={column} onClick={frontPaginator ? null : () => handleSort(column)}>
								{columnMap[dataName][column]}
								{column === sortColumn && (
									<span>{direction === 'desc' ? <FaSortUp /> : <FaSortDown />}</span>
								)}
							</th>
						))}
						{actionButtons && <th className='last-col text-center'></th>}
					</tr>
				</thead>
				<tbody>
					{loading ? (
						<tr>
							<td colSpan={5}>
								<LoadingTableBox loading={loading} />
							</td>
						</tr>
					) : error ? (
						<tr colSpan={5}>
							<td colSpan={5}>
								<MessageBox variant='danger'>{error}</MessageBox>
							</td>
						</tr>
					) : data.length === 0 ? (
						<tr colSpan={10}>
							<td colSpan={10}>
								{/* <MessageBox variant="light">No hay resultados, si desea crear haga click <Link className="alert-link" to="/AdminScreen/newAccount">aquí</Link></MessageBox> */}
							</td>
						</tr>
					) : frontPaginator ? (
						currentPosts.map((item) => (
							<tr key={item._id}>
								{showCheckboxColumn && (
									<td>
										<Form.Check
											type={'checkbox'}
											name={`checked${dataName}`}
											value={item._id}
											id={`checked${dataName}`}
										/>
									</td>
								)}
								{columns.map((column) => (
									<td key={column}>
										{currencyColumns.includes(column) &&
											getNestedValue(item, column) !== undefined &&
											getNestedValue(item, column) !== 0 ? (
												showCurrency(
													dataName === 'relWarranties'
														? getNestedValue(item.warranty.debt || item.warranty.asset, 'currency')
														: dataName === 'bills'
															? getNestedValue(item.asset, 'currency')
															: dataName === 'debtDues'
																? getNestedValue(item.debt, 'currency')
																: dataName === 'creditDues'
																	? getNestedValue(item.credit, 'currency')
																	: getNestedValue(item, 'currency'),
													getNestedValue(item, column),
													dataName,
												) +
											' ' +
											getNestedValue(item, column)
											) : (column === 'customId' || column === 'name') &&
											links &&
											getNestedValue(item, column) !== 'N/A' ? (
													<Link
														to={`/AdminScreen/${dataName}/${dataName.slice(0, -1)}/${getNestedValue(item, '_id')}`}
													>
														{getNestedValue(item, column)}
													</Link>
												) : column === 'owner.name' &&
											links &&
											getNestedValue(item, column) !== 'N/A' ? (
														<Link
															to={`/AdminScreen/accounts/account/${getNestedValue(item, 'owner._id')}`}
														>
															{getNestedValue(item, column)}
														</Link>
													) : (
														formatValue(getNestedValue(item, column))
													)}
									</td>
								))}
								{actionButtons && (
									<td>
										<DropdownButton drop='start' title=''>
											{actionButtons.seeDetails && (
												<Dropdown.Item eventKey='1' onClick={() => actionButtons.seeDetails(item)}>
													Ver Detalles
												</Dropdown.Item>
											)}
											{actionButtons.editHandler && (
												<Dropdown.Item eventKey='2' onClick={() => actionButtons.editHandler(item)}>
													Editar
												</Dropdown.Item>
											)}
											{(actionButtons.seeAttachment && item.escritura) ||
												(item.comprobante && (
													<Dropdown.Item
														eventKey='3'
														onClick={() => actionButtons.seeAttachment(item)}
													>
														Ver Documento
													</Dropdown.Item>
												))}
											{actionButtons.deleteHandler && (
												<Dropdown.Item
													eventKey='3'
													onClick={() => actionButtons.deleteHandler(item)}
												>
													Eliminar
												</Dropdown.Item>
											)}
										</DropdownButton>
									</td>
								)}
							</tr>
						))
					) : data && !frontPaginator ? (
						data.map((item) => (
							<tr key={item._id}>
								{showCheckboxColumn && (
									<td>
										<Form.Check
											type={'checkbox'}
											name={`checked${dataName}`}
											value={item._id}
											id={`checked${dataName}`}
										/>
									</td>
								)}
								{columns.map((column) => (
									<td key={column}>
										{currencyColumns.includes(column) &&
											getNestedValue(item, column) !== undefined &&
											getNestedValue(item, column) !== 0 &&
											getNestedValue(item, column) !== null ? (
												showCurrency(
													getNestedValue(item, 'currency'),
													getNestedValue(item, column),
													dataName,
												) +
											' ' +
											getNestedValue(item, column)
											) : (column === 'customId' || column === 'name') &&
											links &&
											getNestedValue(item, column) !== 'N/A' ? (
													<Link
														to={`/AdminScreen/${dataName}/${dataName.slice(0, -1)}/${getNestedValue(item, '_id')}`}
													>
														{getNestedValue(item, column)}
													</Link>
												) : column === 'owner.name' &&
											links &&
											getNestedValue(item, column) !== 'N/A' ? (
														<Link
															to={`/AdminScreen/accounts/account/${getNestedValue(item, 'owner._id')}`}
														>
															{getNestedValue(item, column)}
														</Link>
													) : (
														formatValue(getNestedValue(item, column))
													)}
									</td>
								))}
								{actionButtons && (
									<td>
										<DropdownButton drop='start' title=''>
											{actionButtons.seeDetails && (
												<Dropdown.Item eventKey='1' onClick={() => actionButtons.seeDetails(item)}>
													Ver Detalles
												</Dropdown.Item>
											)}
											{actionButtons.editHandler && (
												<Dropdown.Item eventKey='2' onClick={() => actionButtons.editHandler(item)}>
													Editar
												</Dropdown.Item>
											)}
											{(actionButtons.seeAttachment && item.escritura) ||
												(item.comprobante && (
													<Dropdown.Item
														eventKey='3'
														onClick={() => actionButtons.seeAttachment(item)}
													>
														Ver Documento
													</Dropdown.Item>
												))}
											{actionButtons.deleteHandler && (
												<Dropdown.Item
													eventKey='3'
													onClick={() => actionButtons.deleteHandler(item)}
												>
													Eliminar
												</Dropdown.Item>
											)}
										</DropdownButton>
									</td>
								)}
							</tr>
						))
					) : (
						''
					)}
				</tbody>
			</Table>
			{frontPaginator ? (
				<Pagination
					postsPerPage={postsPerPage}
					totalPosts={data.length}
					paginate={paginate}
				></Pagination>
			) : (
				<Paginator
					className='pagination-bar'
					totalCount={count}
					onPageChange={onPageChange}
					currentPage={parseInt(page)}
					pageSize={pageSize}
				></Paginator>
			)}
		</>
	);
};

export default DynamicListTable;
