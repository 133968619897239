import React, { useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DynamicListTable from '../../components/DynamicListTable';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import AssetSaleService from '../../services/assetSale.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, assetSales: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function AssetSales() {
	const [{ loading, error, assetSales, successDelete }, dispatch] = useReducer(reducer, {
		assetSales: [],
		loading: true,
		error: '',
	});

	const params = useParams();
	const { id } = params;

	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await AssetSaleService.getSalesForProperty(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
				console.error(err);
				toast.error('No se pudo obtener lista');
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	async function deletePaymentHandler(assetSale) {
		if (window.confirm('Seguro desea eliminar el pago?')) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await AssetSaleService.delete(assetSale._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Venta Eliminada');
			} catch (err) {
				dispatch({ type: 'DELETE_FAIL' });
				console.error(getError(err));
				toast.error('Error al eliminar venta');
			}
		}
	}

	//   async function editPaymentHandler(contract) {
	//     navigate(`/AdminScreen/contracts/editContract/${contract._id}`)
	//   }
	async function seeDetails(assetSale) {
		navigate(`/AdminScreen/assetSales/assetSale/${assetSale._id}`);
	}
	//   async function newPaymentHandler() {
	//     navigate("/AdminScreen/newContract")
	//   }
	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Ventas de Activo</title>
			</Helmet>

			<div className='container admin-con'>
				<div className='row adminProdBtnRow align-items-center'>
					<DynamicListTable
						data={assetSales}
						loading={loading}
						error={error}
						actionButtons={{
							seeDetails: seeDetails,
							// editHandler: editPaymentHandler,
							deleteHandler: deletePaymentHandler,
						}}
						dataName='assetSales'
						showCheckboxColumn={false}
						links={true}
						frontPaginator={true}
						exportOption={true}
					/>
				</div>
			</div>
		</div>
	);
}

export default AssetSales;
