import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import axiosInstance from '../../RestClient';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, gotasettings: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

const AdminInfo = () => {
	const [{ loading, error }, dispatch] = useReducer(reducer, {
		loading: true,
		error: '',
	});

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [companyName, setCompanyName] = useState();
	const [companysubName, setCompanysubName] = useState();
	const [companyAddress, setCompanyAddress] = useState();
	const [companyPhone, setCompanyPhone] = useState();
	const [companyCity, setCompanyCity] = useState();
	const [image, setImage] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await axios.get('/config/public');
				setCompanyName(data.companyName);
				setCompanysubName(data.companysubName);
				setCompanyAddress(data.companyAddress);
				setCompanyPhone(data.companyPhone);
				setCompanyCity(data.companyCity);
				if (data.image) {
					setImage(data.image);
				}
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, []);

	const setGotasettings = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const { data } = await axiosInstance.put(
				'/config',
				{
					companyName,
					companysubName,
					companyAddress,
					companyPhone,
					image,
					companyCity,
				},
				{
					headers: { Authorization: `Bearer ${userInfo.token}` },
				},
			);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'UPDATE_SUCCESS', payload: data });
			toast.success('Empresa actualizada Correctamente');
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL' });
			toast.error(error.message);
		}
	};
	const uploadPicture = async (event) => {
		const file = event.target.files[0];
		const formData = new FormData();
		let r = Math.random().toString(36).substring(7);
		formData.append('file', file, file.name.replaceAll(' ') + r);
		if (image) {
			formData.append('documentId', image._id);
		}
		const multiPart = { 'content-type': 'multipart/form-data' };
		const response = await axios.post('/api/config/uploadSettingsImg', formData, {
			...multiPart,
			headers: { Authorization: `Bearer ${userInfo.token}` },
		});
		setImage(response.data);
		toast.success('imagen agregada correctamente');
	};

	return (
		<div className='Admin-info'>
			<div className='container  ps-0 mt-2'>
				{(loading) ? (
					<LoadingBox></LoadingBox>
				) : (
					error ? (
						<MessageBox variant='danger'>{error}</MessageBox>
					) : null
				)}
				<Form>
					<Row>
						<Form.Group className='mb-3 col-6' controlId='companyName'>
							<Form.Label>Nombre de Empresa</Form.Label>
							<Form.Control
								onChange={(e) => setCompanyName(e.target.value)}
								defaultValue={companyName}
							></Form.Control>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group className='mb-3 col-6' controlId='companysubName'>
							<Form.Label>Frase/tipo de Empresa</Form.Label>
							<Form.Control
								onChange={(e) => setCompanysubName(e.target.value)}
								defaultValue={companysubName}
							></Form.Control>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group className='mb-3 col-6' controlId='companyAdress'>
							<Form.Label>Direccion de Empresa</Form.Label>
							<Form.Control
								onChange={(e) => setCompanyAddress(e.target.value)}
								defaultValue={companyAddress}
							></Form.Control>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group className='mb-3 col-6' controlId='companyCity'>
							<Form.Label>Ciudad-Pais</Form.Label>
							<Form.Control
								onChange={(e) => setCompanyCity(e.target.value)}
								defaultValue={companyCity}
							></Form.Control>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group className='mb-3 col-6' controlId='companyPhone'>
							<Form.Label>Telefono</Form.Label>
							<Form.Control
								onChange={(e) => setCompanyPhone(e.target.value)}
								defaultValue={companyPhone}
							></Form.Control>
						</Form.Group>
					</Row>
					<Row>
						<Col>
							<Form.Group className='mb-3 col-6' controlId=''>
								<Form.Label>Subir Logo</Form.Label>
								<Form.Control type='file' onChange={uploadPicture} name='logo'></Form.Control>
							</Form.Group>
						</Col>
						<Col>
							{image && image.fileLink ? (
								<img
									alt='logo_photo'
									src={image.fileLink}
									style={{ maxWidth: '100px', maxHeight: '100px' }}
								/>
							) : (
								<img
									alt='logo_photo'
									src='/images/emptyPhoto.png'
									style={{ maxWidth: '100px', maxHeight: '100px' }}
								/>
							)}
						</Col>
					</Row>
					<Row>
						<Col>
							<Button md={2} onClick={setGotasettings}>
								Actualizar
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	);
};
export default AdminInfo;
