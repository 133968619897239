import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { MdOutlineEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { getError } from '../../utils';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useNavigate, useParams } from 'react-router-dom';
import GenericDropdown from '../../components/SelectDropdown';
import AccountService from '../../services/account.service';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, account: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'UPDATE_REQUEST':
		return { ...state, loading: true };
	case 'UPDATE_SUCCESS':
		return { ...state, loading: false };
	case 'UPDATE_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function EditAccount() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, error }, dispatch] = useReducer(reducer, {
		loading: true,
		error: '',
	});
	const {dispatch: ctxDispatch } = useContext(Store);

	//TODO: hacer esto dinamico
	const types = ['Persona', 'Empresa'];
	const currencies = ['USD', 'UYU'];
	const [selectedType, setSelectedType] = useState();
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [address, setAddress] = useState();
	const [phone, setPhone] = useState();
	const [folder, setFolder] = useState();
	const [rut, setRut] = useState();
	const [selectedCurrency, setSelectedCurrency] = useState();

	const [observations, setObservations] = useState();
	const [createdBy, setCreatedBy] = useState();

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await AccountService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				setSelectedType(result.data.type);
				setName(result.data.name);
				setEmail(result.data.email);
				setAddress(result.data.adress);
				setPhone(result.data.phone);
				setFolder(result.data.folder);
				setRut(result.data.rut);
				setSelectedCurrency(result.data.currency);
				setObservations(result.data.observations);
				setCreatedBy(result.data.createdBy);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				console.error(getError(error));
				toast.error('No se pudo obtener cuenta');
			}
		};
		fetchData();
	}, [id]);

	const editAccountHandler = async () => {
		if (selectedType === 'Empresa' && !rut) {
			toast.error('Debe completar RUT');
		}
		try {
			dispatch({ type: 'UPDATE_REQUEST' });
			const { data } = await AccountService.edit(id, {
				type: selectedType,
				name,
				observations,
				createdBy,
				email,
				phone,
				address,
				folder,
				rut, //only empresas
				currency: selectedCurrency, //only empresas?
			});
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'PRODUCT_UPDATE_SUCCESS', payload: data });
			localStorage.setItem('account', JSON.stringify(data));
			toast.success('Cuenta actualizada Correctamente');
		} catch (error) {
			dispatch({ type: 'UPDATE_FAIL' });
			console.error(getError(error));
		} finally {
			navigate(-1);
		}
	};

	const handleSelectedType = (selected) => {
		setSelectedType(selected.value);
	};
	const handleSelectedCurrency = (selected) => {
		setSelectedCurrency(selected.value);
	};

	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{loading ? (
					<LoadingBox></LoadingBox>
				) : (
					error ? (
						<MessageBox variant='danger'>{error}</MessageBox>
					) : null
				)}				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Editar Cuenta</title>
					</Helmet>
					<h1>
						<MdOutlineEdit></MdOutlineEdit>Editar Cuenta
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Row className='mb-3'>
								<Form.Group className='mb-3 col-4 form-group required'>
									<GenericDropdown
										label='Tipo'
										items={types}
										selected={selectedType}
										onChange={handleSelectedType}
									/>
								</Form.Group>
							</Row>
							{selectedType === 'Persona' && (
								<>
									<Row className='mb-3'>
										<Form.Group
											as={Col}
											className='mb-3 col-6 form-group required'
											controlId='name'
										>
											<Form.Label>Nombre</Form.Label>
											<Form.Control
												onChange={(e) => setName(e.target.value)}
												defaultValue={name}
											></Form.Control>
										</Form.Group>
										<Form.Group as={Col} className='mb-3 col-6 form-group' controlId='email'>
											<Form.Label>Email</Form.Label>
											<Form.Control
												onChange={(e) => setEmail(e.target.value)}
												defaultValue={email}
											></Form.Control>
										</Form.Group>
									</Row>
									<Row>
										<Form.Group className='mb-3 col-6' controlId='address'>
											<Form.Label>Direccion</Form.Label>
											<Form.Control
												onChange={(e) => setAddress(e.target.value)}
												defaultValue={address}
											></Form.Control>
										</Form.Group>
										<Form.Group as={Col} className='mb-3 col-6' controlId='phone'>
											<Form.Label>Telefono</Form.Label>
											<Form.Control
												onChange={(e) => setPhone(e.target.value)}
												defaultValue={phone}
											></Form.Control>
										</Form.Group>
										<Form.Group className='mb-3 col-6 form-group required'>
											<GenericDropdown
												label='Moneda'
												items={currencies}
												selected={selectedCurrency}
												onChange={handleSelectedCurrency}
											/>
										</Form.Group>
										<Form.Group as={Col} className='mb-3 col-12' controlId='folder'>
											<Form.Label>Carpeta</Form.Label>
											<Form.Control
												onChange={(e) => setFolder(e.target.value)}
												defaultValue={folder}
											></Form.Control>
										</Form.Group>
										<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
											<Form.Label>Observaciones</Form.Label>
											<Form.Control
												type='text'
												as='textarea'
												rows={10}
												onChange={(e) => setObservations(e.target.value)}
												defaultValue={observations}
											></Form.Control>
										</Form.Group>
									</Row>
								</>
							)}
							{selectedType === 'Empresa' && (
								<>
									<Row className='mb-3'>
										<Form.Group
											as={Col}
											className='mb-3 col-6 form-group required'
											controlId='name'
										>
											<Form.Label>Nombre</Form.Label>
											<Form.Control
												onChange={(e) => setName(e.target.value)}
												defaultValue={name}
											></Form.Control>
										</Form.Group>
										<Form.Group as={Col} className='mb-3 col-6 form-group required' controlId='rut'>
											<Form.Label>Rut</Form.Label>
											<Form.Control
												onChange={(e) => setRut(e.target.value)}
												defaultValue={rut}
											></Form.Control>
										</Form.Group>
										<Form.Group as={Col} className='mb-3 col-6 form-group' controlId='email'>
											<Form.Label>Email</Form.Label>
											<Form.Control
												onChange={(e) => setEmail(e.target.value)}
												defaultValue={email}
											></Form.Control>
										</Form.Group>
										<Form.Group className='mb-3 col-6' controlId='address'>
											<Form.Label>Direccion</Form.Label>
											<Form.Control
												onChange={(e) => setAddress(e.target.value)}
												defaultValue={address}
											></Form.Control>
										</Form.Group>
										<Form.Group as={Col} className='mb-3 col-6' controlId='phone'>
											<Form.Label>Telefono</Form.Label>
											<Form.Control
												onChange={(e) => setPhone(e.target.value)}
												defaultValue={phone}
											></Form.Control>
										</Form.Group>
										<Form.Group className='mb-3 col-6 form-group required'>
											<GenericDropdown
												className='col-6 mb-3'
												label='Moneda'
												items={currencies}
												selected={selectedCurrency}
												onChange={handleSelectedCurrency}
											/>
										</Form.Group>
										<Form.Group as={Col} className='mb-3 col-12' controlId='folder'>
											<Form.Label>Carpeta</Form.Label>
											<Form.Control
												onChange={(e) => setFolder(e.target.value)}
												defaultValue={folder}
											></Form.Control>
										</Form.Group>
										<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
											<Form.Label>Observaciones</Form.Label>
											<Form.Control
												type='text'
												as='textarea'
												rows={10}
												defaultValue={observations}
												onChange={(e) => setObservations(e.target.value)}
											></Form.Control>
										</Form.Group>
									</Row>
								</>
							)}
						</div>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={editAccountHandler}>
									Guardar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default EditAccount;
