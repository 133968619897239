import React, { useEffect, useReducer, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Helmet } from 'react-helmet-async';
import { MdMonetizationOn } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination';
import interestService from '../../services/interest.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, interests: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function InterestList() {
	const [{ loading, error, interests, successDelete }, dispatch] = useReducer(reducer, {
		interests: [],
		loading: true,
		error: '',
	});

	const navigate = useNavigate();
	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(15); //maxPostsperPage
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = interests.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await interestService.list();
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	async function deletePaymentHandler(interest) {
		if (window.confirm('Seguro desea eliminar el interes?')) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await interestService.delete(interest._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Pago Eliminado');
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}

	async function editPaymentHandler(interest) {
		navigate(`/AdminScreen/interests/editinterest/${interest._id}`);
	}
	async function seeDetails(interest) {
		navigate(`/AdminScreen/interests/interest/${interest._id}`);
	}
	async function newPaymentHandler() {
		navigate('/AdminScreen/newInterest');
	}
	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Intereses</title>
			</Helmet>

			<div className='container admin-con'>
				<div className='row adminProdBtnRow align-items-center'>
					{/* <button className='btn btn-info btn-prod-admin'><FaFilter></FaFilter>Filtrar</button> */}
					{/* <button className='btn btn-danger btn-prod-admin'><Link to='/AdminScreen/AgregarProducto'><FaPlusCircle></FaPlusCircle>Agregar Productos</Link></button> */}
				</div>
				<div>
					<Row style={{ margin: '5% 0 2.5%' }}>
						<Col>
							<h1 className='text-right'>
								<MdMonetizationOn></MdMonetizationOn>Lista de Intereses
							</h1>
						</Col>
						<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<Button
								className='btn btn-dark m-2'
								value='Crear Cuenta'
								onClick={() => newPaymentHandler()}
							>
								Agregar Interes
							</Button>
						</Col>
					</Row>
					<Table bordered hover responsive size='sm'>
						<thead>
							<tr>
								<th>ID</th>
								<th>Porcentaje</th>
								<th>Tipo</th>
								<th>Deuda</th>
								<th>Carpeta</th>
								<th className='col-1'>Opciones</th>
							</tr>
						</thead>
						{interests
							? currentPosts.map((interest) => (
								<tbody key={interest._id}>
									<tr key={interest._id}>
										<td>
											<Link to={`interest/${interest._id}`}>{interest.internalId}</Link>
										</td>
										<td>{interest.percentage}</td>
										<td>{interest.type}</td>
										{interest.debt ? (
											<td>
												<Link to={`/AdminScreen/debts/debt/${interest.debt._id}`}>
													{interest.debt.internalId}
												</Link>
											</td>
										) : (
											<td></td>
										)}
										<td>{interest.folder}</td>
										<td>
											<DropdownButton drop='start' title=''>
												<Dropdown.Item eventKey='0' onClick={() => seeDetails(interest)}>
														Ver Detalles
												</Dropdown.Item>
												<Dropdown.Item eventKey='1' onClick={() => editPaymentHandler(interest)}>
														Editar
												</Dropdown.Item>
												<Dropdown.Item
													eventKey='2'
													onClick={() => deletePaymentHandler(interest)}
												>
														Eliminar
												</Dropdown.Item>
											</DropdownButton>
										</td>
									</tr>
								</tbody>
							))
							: ''}
					</Table>
					<Pagination
						postsPerPage={postsPerPage}
						totalPosts={interests.length}
						paginate={paginate}
					></Pagination>
				</div>
			</div>
		</div>
	);
}

export default InterestList;
