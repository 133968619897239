import React, { useEffect, useReducer, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import { getError } from '../utils';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/LoadingBox';
import { Helmet } from 'react-helmet-async';
import Pagination from '../components/Pagination';
import UserService from '../services/user.service';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, users: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function AdminUsers() {
	const [{ loading, error, users }, dispatch] = useReducer(reducer, {
		users: [],
		loading: true,
		error: '',
	});

	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(15); //maxPostsperPage
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = users.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const data = await UserService.list();
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		fetchData();
	}, []);
	return (
		<div>
			<Helmet>
				<title>Lista de Usuarios</title>
			</Helmet>

			<div className='container admin-con'>
				<h1 className='my-3 text-center'>Lista de usuarios</h1>
				<div className='row adminProdBtnRow align-items-center'>
					{/* <button className='btn btn-info btn-prod-admin'><FaFilter></FaFilter>Filtrar</button> */}
					{/* <button className='btn btn-danger btn-prod-admin'><Link to='/AdminScreen/AgregarProducto'><FaPlusCircle></FaPlusCircle>Agregar Productos</Link></button> */}
				</div>
				<div>
					{loading ? (
						<LoadingBox></LoadingBox>
					) : error ? (
						<MessageBox variant='danger'>{error}</MessageBox>
					) : (
						<ListGroup>
							<ListGroup.Item>
								<Row className='align-items-center'>
									<Col md={3}>
										<span>ID</span>
									</Col>
									<Col md={2}>
										<span>Nombre</span>
									</Col>
									<Col md={3}>Email</Col>
									<Col md={2}>Es Administrador?</Col>
									<Col md={1}>Actions</Col>
									<Col md={2}></Col>
								</Row>
							</ListGroup.Item>
						</ListGroup>
					)}
				</div>
				<div className='con-data'>
					{currentPosts.map((user) => (
						<div className='Container' id='data' key={user._id}>
							<ListGroup>
								<ListGroup.Item key={user._id}>
									<Row className='align-items-center'>
										<Col md={3}>
											<span>{user._id}</span>
										</Col>
										<Col md={2}>{user.name}</Col>
										<Col md={3}>{user.email}</Col>
										<Col md={2}>{user.isAdmin ? 'SI' : 'NO'}</Col>
										<Col md={2}>
											{/* <Button variant="light">
                                                <i className="fas fa-trash"></i>
                                            </Button> */}
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>
						</div>
					))}
				</div>
				<Pagination
					postsPerPage={postsPerPage}
					totalPosts={users.length}
					paginate={paginate}
				></Pagination>
			</div>
		</div>
	);
}

export default AdminUsers;
