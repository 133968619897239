import React, { useEffect, useReducer } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { MdAddCircleOutline, MdOutlineAccountTree, MdOutlineCorporateFare } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import AccountService from '../../services/account.service';
import { getError } from '../../utils';
import AccountAssets from '../Accounts/AccountAssets';
import AccountContacts from '../Accounts/AccountContacts';
import AccountContracts from '../Accounts/AccountContracts';
import AccountCredits from '../Accounts/AccountCredits';
import AccountDebts from '../Accounts/AccountDebts';
import AccountInfo from '../Accounts/AccountInfo';
import './AccountDetail.css';
import MessageBox from '../../components/MessageBox';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, account: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'FETCH_LENGTHS_REQUEST':
		return { ...state, loadingOthers: true };
	case 'FETCH_LENGTHS_SUCCESS':
		return {
			...state,
			assetsLength: action.payload.assetsLength,
			billsLength: action.payload.billsLength,
			contactsLength: action.payload.contactsLength,
			contractsLength: action.payload.contractsLength,
			debtsLength: action.payload.debtsLength,
			paymentsLength: action.payload.paymentsLength,
			accountCreditsLength: action.payload.accountCreditsLength,
			loadingOthers: false,
		};
	case 'FETCH_LENGTHS_FAIL':
		return { ...state, loadingOthers: false, error: action.payload };
	default:
		return state;
	}
};

export default function AccountDetail() {
	const [{ account, loading, error}, dispatch] = useReducer(reducer, {
		account: {},
		loading: true,
		error: '',
	});

	const params = useParams();
	const navigate = useNavigate();
	const { id } = params;

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await AccountService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				console.error(error);
				toast.error('No se pudo obtener lista');
			}
		};
		fetchData();
	}, []);

	async function editAccountHandler() {
		navigate(`/AdminScreen/accounts/editAccount/${id}`);
	}
	async function newContactHandler(e) {
		navigate(`/AdminScreen/accounts/account/${id}/newContact`);
		e.stopPropagation();
	}

	async function newDebtHandler(e) {
		navigate(`/AdminScreen/accounts/account/${id}/newDebt`);
		e.stopPropagation();
	}
	async function newCreditHandler(e) {
		navigate(`/AdminScreen/accounts/account/${id}/newCredit`);
		e.stopPropagation();
	}
	async function newContractHandler(e) {
		navigate(`/AdminScreen/accounts/account/${id}/newContract`);
		e.stopPropagation();
	}
	async function newAssetHandler(e) {
		navigate(`/AdminScreen/accounts/account/${id}/newAsset`);
		e.stopPropagation();
	}

	return (
		<>
			{loading && <LoadingBox></LoadingBox>}
			<div className='container admin-con align-items-center'>
				<Row style={{ margin: '0 0 2.5% 0' }}>
					<Col style={{ alignSelf: 'center' }}>
						<h2>
							<MdOutlineCorporateFare></MdOutlineCorporateFare>Cuenta:{account.name}
						</h2>
					</Col>
					<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							style={{ backgroundColor: 'transparent!important' }}
							id='back-btn'
							className='btn m-2'
							value='back'
							onClick={() => navigate(-1)}
						>
							Atras
						</Button>
						<Button
							className='btn btn-dark m-2'
							value='Crear Cuenta'
							onClick={() => editAccountHandler()}
						>
							Editar Cuenta
						</Button>
					</Col>
				</Row>
				<div className='container'>
					{ !error ? 
						<div className='jumbotron'>
							<AccountInfo account={account}></AccountInfo>
						</div> :
						<MessageBox variant = "danger">
							{error}
						</MessageBox>
					}
					<div className='jumbotron related-data'>
						<h2 style={{ marginBottom: '2.5%' }}>
							<MdOutlineAccountTree></MdOutlineAccountTree>Datos Relacionados
						</h2>
						<Accordion>
							{account.type == 'Empresa' && (
								<Accordion.Item eventKey='1'>
									<Accordion.Header>
										Contactos
										<Button id='add-btn' onClick={(e) => newContactHandler(e)}>
											<MdAddCircleOutline></MdAddCircleOutline>Agregar
										</Button>
									</Accordion.Header>
									<Accordion.Body>
										<AccountContacts></AccountContacts>
									</Accordion.Body>
								</Accordion.Item>
							)}
						</Accordion>
						<Accordion>
							<Accordion.Item eventKey='2'>
								<Accordion.Header>
									Deudas
									<Button id='add-btn' onClick={(e) => newDebtHandler(e)}>
										<MdAddCircleOutline></MdAddCircleOutline>Agregar
									</Button>
								</Accordion.Header>
								<Accordion.Body>
									<AccountDebts></AccountDebts>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
						<Accordion>
							<Accordion.Item eventKey='3'>
								<Accordion.Header>
									Pasividades
									<Button id='add-btn' onClick={(e) => newCreditHandler(e)}>
										<MdAddCircleOutline></MdAddCircleOutline>Agregar
									</Button>
								</Accordion.Header>
								<Accordion.Body>
									<AccountCredits></AccountCredits>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
						<Accordion></Accordion>
						<Accordion>
							<Accordion.Item eventKey='4'>
								<Accordion.Header>
									Alquileres
									<Button id='add-btn' onClick={(e) => newContractHandler(e)}>
										<MdAddCircleOutline></MdAddCircleOutline>Agregar
									</Button>
								</Accordion.Header>
								<Accordion.Body>
									<AccountContracts></AccountContracts>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey='5'>
								<Accordion.Header>
									Activos Fijos
									<Button id='add-btn' onClick={(e) => newAssetHandler(e)}>
										<MdAddCircleOutline></MdAddCircleOutline>Agregar
									</Button>
								</Accordion.Header>
								<Accordion.Body>
									<AccountAssets></AccountAssets>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
				</div>
			</div>
		</>
	);
}
