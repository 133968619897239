import axiosInstance from '../RestClient';

const BASE_URI = '/debtUpdates';

class DebtUpdatesService {
	async list() {
		const uri = `${BASE_URI}/`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async listFromDebtId(recordId) {
		const uri = `${BASE_URI}/${recordId}`;
		const response = await axiosInstance.get(uri);
		return response;
	}
}
export default new DebtUpdatesService();