import axiosInstance from '../RestClient';

const BASE_URI = '/creditpayments';
class CreditPaymentService {
	async list() {
		const uri = `${BASE_URI}/`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async getDetails(recordId) {
		const uri = `${BASE_URI}/${recordId}`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async create(payload) {
		const response = await axiosInstance.post(`${BASE_URI}/`, payload);
		return response;
	}

	async edit(recordId, payload) {
		const response = await axiosInstance.put(`${BASE_URI}/${recordId}`, payload);
		return response;
	}

	async delete(recordId) {
		const uri = `${BASE_URI}/${recordId}`;
		const response = await axiosInstance.delete(uri);
		return response;
	}

	async getCreditsForAccount(accountId) {
		const uri = `${BASE_URI}/mine/${accountId}`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async getPaymentsForcredit(creditId) {
		const uri = `${BASE_URI}/minedebt/${creditId}`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async uploadComprobante(formData) {
		const multiPart = { 'content-type': 'multipart/form-data' };
		const uri = `${BASE_URI}/uploadComprobante`;
		const response = await axiosInstance.post(uri, formData, {
			...multiPart,
		});
		return response;
	}

	async deleteBillsForAsset(assetId) {
		const uri = `${BASE_URI}/minedebt/${assetId}`;
		const response = await axiosInstance.delete(uri);
		return response;
	}
}
export default new CreditPaymentService();
