import React, { useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { MdAddchart } from 'react-icons/md';
import GenericDropdown from '../components/SelectDropdown';
import FilterForm from '../components/FilterForm';
import { getError } from '../utils';
import DynamicTable from '../components/DynamicTable';
import Paginator from './Paginator/Paginator';
import GeneratedDebtService from '../services/generatedDebts.service';
import GeneratedCreditService from '../services/generatedCredits.service';
import LoadingBox from '../components/LoadingBox';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_DEBTSDUES_REQUEST':
		return { ...state, loadingDebtsDues: true };
	case 'FETCH_DEBTSDUES_SUCCESS':
		return {
			...state,
			debtsDues: action.payload.debtsDues,
			allDebtsDues: action.payload.allDebtsDues,
			page: action.payload.page,
			pages: action.payload.pages,
			countDebtsDues: action.payload.countDebtsDues,
			pageSize: action.payload.pageSize,
			toCollectUSD: action.payload.toCollectUSD,
			toCollectUYU: action.payload.toCollectUYU,
			loadingDebtsDues: false,
		};
	case 'FETCH_CREDITSDUES_REQUEST':
		return { ...state, loadingCreditsDues: true };
	case 'FETCH_CREDITSDUES_SUCCESS':
		return {
			...state,
			creditsDues: action.payload.creditsDues,
			allCreditDues: action.payload.allCreditDues,
			page: action.payload.page,
			pages: action.payload.pages,
			countCreditsDues: action.payload.countCreditsDues,
			pageSize: action.payload.pageSize,
			toCollectUSD: action.payload.toCollectUSD,
			toCollectUYU: action.payload.toCollectUYU,
			loadingCreditsDues: false,
		};
	case 'FETCH_FAIL':
		return {
			...state,
			loadingDebtDues: false,
			loadingCreditsDues: false,
			error: action.payload,
		};
	default:
		return state;
	}
};

function Predictions() {
	const [
		{
			error,
			debtsDues,
			allDebtsDues,
			loadingDebtsDues,
			pageSize,
			countDebtsDues,
			creditsDues,
			allCreditDues,
			loadingCreditsDues,
			countCreditsDues,
			toCollectUSD,
			toCollectUYU,
		},
		dispatch,
	] = useReducer(reducer, {
		debtsDues: [],
		allDebtsDues: [],
		loadingDebtsDues: false,
		error: '',
		countDebtsDues: 0,
		creditsDues: [],
		allCreditDues: [],
		loadingCreditsDues: false,
		countCreditsDues: 0,
		pageSize: 0,
	});

	const { search } = useLocation();

	const predictions = ['Previsión de Deudas', 'Previsión de Creditos'];
	const [selectedReport, setSelectedReport] = useState();
	const [filterOptions, setFilterOptions] = useState({});
	const navigate = useNavigate();

	const sp = new URLSearchParams(search);
	let page = sp.get('page') || 1;
	const concept = sp.get('concept') || 'all';
	const currency = sp.get('currency') || 'all';
	const dateFrom = sp.get('dateFrom') || '';
	const dateTo = sp.get('dateTo') || '';
	const query = sp.get('query') || 'all';
	const queryString = search.substring(1);

	const [filtered, setFiltered] = useState(false);

	const onPageChange = (page) => {
		navigate(getFilterUrl({ page: page }));
	};

	function handleInputReportChange(e) {
		setSelectedReport(e.value);
		if (e.value === 'Previsión de Deudas') {
			setFilterOptions({
				formType: 'CuotasDeudas',
				concept: concept,
				dateFrom: dateFrom,
				dateTo: dateTo,
				currency: currency,
				query: query,
				page: page,
			});
		}
		if (e.value === 'Previsión de Creditos') {
			setFilterOptions({
				formType: 'CuotasCreditos',
				concept: concept,
				dateFrom: dateFrom,
				dateTo: dateTo,
				currency: currency,
				query: query,
				page: page,
			});
		}
	}

	const handleClearFilter = () => {
		if (selectedReport === 'Previsión de Deudas') {
			setFilterOptions({
				formType: 'CuotasDeudas',
				concept: 'all',
				dateFrom: '',
				dateTo: '',
				currency: 'all',
				query: 'all',
			});
			setFiltered(false);
		}
		if (selectedReport === 'Previsión de Creditos') {
			setFilterOptions({
				formType: 'CuotasCreditos',
				concept: 'all',
				dateFrom: '',
				dateTo: '',
				currency: 'all',
				query: 'all',
			});
			setFiltered(false);
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFilterOptions((prevOptions) => ({
			...prevOptions,
			[name]: value,
			formType: filterOptions.formType,
		}));
	};

	const handleApplyFilter = () => {
		const filterUrl = getFilterUrl(filterOptions);
		navigate(filterUrl);
		setFiltered(true);
	};

	const getFilterUrl = (filter) => {
		const { concept, query, dateFrom, dateTo, currency, paid } = filterOptions;
		const filterConcept = concept || concept;
		const filterQuery = filter.query || query;
		const filterPage = filter.page || 1;
		const filterDateFrom = dateFrom || '';
		const filterDateTo = dateTo || '';
		const filterCurrency = currency || '';
		const filterPaid = paid || '';

		const params = new URLSearchParams();

		if (filterOptions.formType === 'CuotasDeudas') {
			params.append('concept', filterConcept);
			params.append('dateFrom', filterDateFrom);
			params.append('dateTo', filterDateTo);
			params.append('paid', filterPaid);
			params.append('currency', filterCurrency);
			params.append('query', filterQuery);
			params.append('page', filterPage);
			return `/AdminScreen/predictions/debtDues/search?${params.toString()}`;
		}
		if (filterOptions.formType === 'CuotasCreditos') {
			params.append('concept', filterConcept);
			params.append('dateFrom', filterDateFrom);
			params.append('dateTo', filterDateTo);
			params.append('paid', filterPaid);
			params.append('currency', filterCurrency);
			params.append('query', filterQuery);
			params.append('page', filterPage);
			return `/AdminScreen/predictions/creditDues/search?${params.toString()}`;
		}

		return '/AdminScreen/predictions';
	};

	useEffect(() => {
		const fetchDataBasedOnURL = async () => {
			try {
				if (
					filterOptions.formType === 'CuotasDeudas' &&
					filterOptions.dateTo &&
					filterOptions.dateFrom
				) {
					dispatch({ type: 'FETCH_DEBTSDUES_REQUEST' });
					const { data } = await GeneratedDebtService.listFuture(queryString);
					dispatch({ type: 'FETCH_DEBTSDUES_SUCCESS', payload: data });
				}
				if (
					filterOptions.formType === 'CuotasCreditos' &&
					filterOptions.dateTo &&
					filterOptions.dateFrom
				) {
					dispatch({ type: 'FETCH_CREDITSDUES_REQUEST' });
					const { data } = await GeneratedCreditService.listFuture(queryString);
					dispatch({ type: 'FETCH_CREDITSDUES_SUCCESS', payload: data });
				}
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchDataBasedOnURL();
	}, [search, page, filterOptions.formType, selectedReport]);

	useEffect(() => {
		handleApplyFilter();
	}, [filtered]);

	return (
		<div className='container admin-con align-items-center'>
			<Row style={{ margin: '5% 0 2.5%' }}>
				<Col className='d-flex align-items-start'>
					<h1 className='text-right m-0'>
						<MdAddchart></MdAddchart> Generación de Previsiones
					</h1>
				</Col>
				<Col>
					<GenericDropdown
						label='Prevision a Crear'
						items={predictions}
						selected={selectedReport}
						onChange={handleInputReportChange}
						name='prevision'
					/>
				</Col>
			</Row>
			<Row>
				<div>
					<h2 className='mb-3'>Filtrar {selectedReport} por:</h2>
					<FilterForm
						filterOptions={filterOptions}
						handleInputChange={handleInputChange}
						// isOpen={isOpen}
						// handleDropdownToggle={handleDropdownToggle}
						clearFilter={() => handleClearFilter(filterOptions.formType)}
						handleApplyFilter={() => handleApplyFilter(filterOptions.formType)}
					/>
				</div>
			</Row>
			{selectedReport === 'Previsión de Deudas' &&
			filterOptions.dateFrom &&
			filterOptions.dateTo &&
			debtsDues &&
			debtsDues.length !== 0 ? (
					loadingDebtsDues ? (
						<LoadingBox></LoadingBox>
					) : (
						<Row className=''>
							<DynamicTable
								data={debtsDues}
								allData={allDebtsDues}
								toCollectUSD={toCollectUSD}
								toCollectUYU={toCollectUYU}
								loading={loadingDebtsDues}
								error={error}
								dataName='debtsDues'
							/>
							<Paginator
								className='pagination-bar'
								totalCount={countDebtsDues}
								onPageChange={onPageChange}
								currentPage={parseInt(page)}
								pageSize={pageSize}
							/>
						</Row>
					)
				) : null}
			{selectedReport === 'Previsión de Creditos' &&
			filterOptions.dateFrom &&
			filterOptions.dateTo &&
			creditsDues &&
			creditsDues.length !== 0 ? (
					loadingCreditsDues ? (
						<LoadingBox></LoadingBox>
					) : (
						<Row className=''>
							<DynamicTable
								data={creditsDues}
								allData={allCreditDues}
								toCollectUSD={toCollectUSD}
								toCollectUYU={toCollectUYU}
								loading={loadingCreditsDues}
								error={error}
								dataName='creditDues'
							/>
							<Paginator
								className='pagination-bar'
								totalCount={countCreditsDues}
								onPageChange={onPageChange}
								currentPage={parseInt(page)}
								pageSize={pageSize}
							/>
						</Row>
					)
				) : null}
		</div>
	);
}
export default Predictions;
