import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import { getError } from '../../utils';

import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import contractService from '../../services/contract.service';
import userService from '../../services/user.service';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_CONTACTS_REQUEST':
		return { ...state, loadingContacts: true };
	case 'FETCH_CONTACTS_SUCCESS':
		return { ...state, contacts: action.payload, loadingContacts: false };
	case 'FETCH_CONTACTS_FAIL':
		return { ...state, loadingContacts: false, error: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, user: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function EditUser() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, user, loadingContacts, 
		// contacts 
	}, dispatch] = useReducer(reducer, {
		// contacts: [],
		loadingContacts: true,
		user: {},
		loading: true,
		error: '',
	});
	const { dispatch: ctxDispatch } = useContext(Store);

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	// const [password, setPassword] = useState('');
	// const [isAdmin, setIsAdmin] = useState('');
	// const [contact, setContact] = useState('');
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await userService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				setName(result.data.name);
				setEmail(result.data.email);
				// setPassword(result.data.password);
				// setIsAdmin(result.data.isAdmin);
				// setContact(result.data.contact);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
			// setAccounts(result.data);
		};
		const fetchContacts = async () => {
			dispatch({ type: 'FETCH_CONTACTS_REQUEST' });
			try {
				const result = await contractService.list();
				dispatch({ type: 'FETCH_CONTACTS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchContacts();
		fetchData();
	}, [id]);

	const editUserHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const { data } = await userService.edit(user._id, {
				name,
				email,
			});
			// deletePictureHandler(image);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'USER_UPDATE_SUCCESS', payload: data });
			localStorage.setItem('user', JSON.stringify(data));

			toast.success('Usuario actualizado Correctamente');
			navigate('/AdminScreen/users');
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL' });
			toast.error(getError(err));
		}
	};

	return (
		<div>
			{(loading || loadingContacts) && <LoadingBox></LoadingBox>}
			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<Modal size='lg' show={showModal} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Guardar Cambios</Modal.Title>
				</Modal.Header>
				<Modal.Body>Seguro desea guardar los cambios?</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleClose}>
						Cancelar
					</Button>
					<Button onClick={editUserHandler}>Confirmar</Button>
				</Modal.Footer>
			</Modal>
			<div className='container admin-con align-items-center'>
				<Helmet>
					<title>Editar Usuario</title>
				</Helmet>
				<h1 className='my-3'>Editar Usuario</h1>
				<Form onSubmit={(e) => e.preventDefault()}>
					<div className='row align-items-center'>
						<Form.Group className='mb-3 col-3' controlId='name'>
							<Form.Label>Nombre</Form.Label>
							<Form.Control
								type='text'
								defaultValue={name}
								onChange={(e) => setName(e.target.value)}
							></Form.Control>
						</Form.Group>
						<Form.Group className='mb-3 col-3' controlId='email'>
							<Form.Label>Email</Form.Label>
							<Form.Control
								type='text'
								defaultValue={email}
								onChange={(e) => setEmail(e.target.value)}
							></Form.Control>
						</Form.Group>
						{/* <Form.Group className='mb-3 col-3' controlId='psw'>
							<Form.Label>Password</Form.Label>
							<Form.Control
								type='password'
								// onChange={(e) => setPassword(bcrypt.hashSync(e.target.value))}
							></Form.Control>
						</Form.Group>
						<Form.Group className='mb-3 col-3' controlId='psw'>
							<Form.Label>Es Administrador?</Form.Label>
							<Form.Check type='checkbox' onChange={(e) => setIsAdmin(e.target.value)}></Form.Check>
						</Form.Group>

						<Form.Group className='mb-3 col-3' controlId='contact'>
							<Form.Label>Contacto asociado</Form.Label>
							<Form.Select onChange={(e) => setContact(e.target.value)}>
								{contacts.map((contact) => {
									return (
										<option key={contact._id} value={contact._id}>
											{contact.name}
										</option>
									);
								})}
							</Form.Select>
						</Form.Group> */}
					</div>
					{/* <div className="row align-items-centers">
            <div
              style={{
                padding: 0,
                width: "300px",
                height: "300px",
                margin: "auto",
                border: "1px solid black",
                backgroundImage:
                  image && image.fileLink
                    ? "url(" + image.fileLink + ")"
                    : "url(/images/emptyPhoto.png)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% auto",
                backgroundPosition: "center top",
              }}
            >
              <AvatarImageCropper apply={apply} text="Adjuntar la imagen" />
            </div>
          </div> */}
					<div className='mb-3'>
						<button id='goBack' onClick={() => navigate(-1)}>
							<BsArrowLeftCircle /> Atras
						</button>
						<Button onClick={handleShow}>Guardar</Button>
					</div>
				</Form>
			</div>
		</div>
	);
}
export default EditUser;
