import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet-async';
import { MdLibraryAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomTypeahead from '../../components/CustomTypeahead';
import FormatedNumberInput from '../../components/FormatedNumberInput';
import LoadingBox from '../../components/LoadingBox';
import GenericDropdown from '../../components/SelectDropdown';
import AssetService from '../../services/asset.service';
import BillService from '../../services/bill.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return { ...state, assets: action.payload, loadingFetch: false };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function AddBill() {
	const navigate = useNavigate();
	const [name, setName] = useState();
	const [amount, setAmount] = useState();
	const [expirationDate, setExpirationDate] = useState();
	const [asset, setAsset] = useState();
	const [type, setType] = useState();
	const types = ['Periodico', 'Eventual'];
	const [periodicity, setPeriodicity] = useState();
	const [period, setPeriod] = useState(new Date());
	const tenYearsAgo = new Date();
	tenYearsAgo.setFullYear(tenYearsAgo.getFullYear() - 5);
	const tenYearsLater = new Date();
	tenYearsLater.setFullYear(tenYearsLater.getFullYear() + 5);

	const periods = ['Mensual', 'Anual'];
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();


	const [{ loadingFetch, loadingCreate, assets }, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		assets: [],
		loadingCreate: false,
		error: '',
	});

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await AssetService.list();
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, []);

	const addPaymentHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			if (!asset) {
				toast.error('Debe seleccionar un activo.');
				return;
			}
			if (type == 'Periodico' && periodicity != 'Mensual' && periodicity != 'Anual') {
				toast.error('Debe seleccionar periodicidad.');
				return;
			}

			if (periodicity === 'Periodico' && (period === null || period === undefined)) {
				toast.error('Debe seleccionar un periodo.');
				return;
			}
			const requestData = {
				name,
				amount,
				expirationDate,
				asset: asset[0],
				type,
				folder,
				observations,
			};
			if (type === 'Periodico') {
				requestData.periodicity = periodicity;
			}
			if (type === 'Periodico' && period !== null) {
				requestData.period = period;
			}

			await BillService.create(requestData);
			toast.success('Gasto creado');
			navigate(-1);
		} catch (ex) {
			toast.error(getError(ex));
		}
	};

	const selectedDate = (date) => {
		setPeriod(date);
	};

	const [isInvalid, setIsInvalid] = useState(false);

	const handleAssetChange = (selected) => {
		const selectedAsset = selected[0];
		const isValid = selectedAsset ? assets.includes(selectedAsset) : false;
		setIsInvalid(!isValid);
		setAsset(selected);
	};

	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{loadingCreate && loadingFetch && <LoadingBox></LoadingBox>}
				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Agregar Gasto</title>
					</Helmet>
					<h1>
						<MdLibraryAdd></MdLibraryAdd>Agregar Gasto
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Row>
								<Form.Group className='mb-3 col-6 form-group required ' controlId='name'>
									<Form.Label>Nombre</Form.Label>
									<Form.Control
										type='text'
										onChange={(e) => setName(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3 col-6 form-group required' controlId='amount'>
									<Form.Label>Monto</Form.Label>
									<FormatedNumberInput
										type='number'
										onChange={(e) => setAmount(e.target.value)}
										min={0}
									></FormatedNumberInput>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group className='mb-3 col-6 form-group required' controlId='date'>
									<Form.Label>Vencimiento</Form.Label>
									<Form.Control
										type='date'
										onChange={(e) => setExpirationDate(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3 col-6 form-group required'>
									<Form.Label>Activo Vinculado</Form.Label>
									<CustomTypeahead
										id='assets'
										labelKey={(option) => `${option.customId}`}
										onChange={handleAssetChange}
										options={assets}
										placeholder='Buscar Activo...'
										clearButton={true}
										linkToCreate='/AdminScreen/newAsset'
										newOption='nuevo Activo'
									/>
									{isInvalid && <div style={{ color: 'red' }}>Activo invalido</div>}
								</Form.Group>
							</Row>
							<Row>
								<Form.Group className='mb-3 col-4 form-group required' id='type'>
									<GenericDropdown
										label='Tipo'
										items={types}
										selected={type}
										onChange={(e) => setType(e.value)}
									/>
								</Form.Group>
								{type === 'Periodico' && (
									<Form.Group className='mb-3 col-4 form-group required' id='other'>
										<GenericDropdown
											label='Periodicidad'
											items={periods}
											selected={periodicity}
											onChange={(e) => setPeriodicity(e.value)}
										/>
									</Form.Group>
								)}
								{type === 'Periodico' && periodicity == 'Anual' && (
									<Form.Group className='mb-3 col-4 form-group required'>
										<Form.Label>Año:</Form.Label>
										<DatePicker
											selected={period}
											onChange={selectedDate}
											dateFormat='yyyy'
											showYearPicker
										/>
									</Form.Group>
								)}
								{type === 'Periodico' && periodicity == 'Mensual' && (
									<Form.Group className='mb-3 col-4 form-group required'>
										<Form.Label>Mes/año:</Form.Label>
										<DatePicker
											selected={period}
											onChange={selectedDate}
											showMonthYearPicker
											dateFormat='MM/yyyy'
										/>
									</Form.Group>
								)}
								<Form.Group as={Col} className='mb-3 col-6' controlId='folder'>
									<Form.Label>Carpeta</Form.Label>
									<Form.Control onChange={(e) => setFolder(e.target.value)}></Form.Control>
								</Form.Group>
							</Row>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Observaciones</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									rows={10}
									onChange={(e) => setObservations(e.target.value)}
								></Form.Control>
							</Form.Group>
						</div>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={addPaymentHandler}>
									Agregar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default AddBill;
