import React, { useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DynamicListTable from '../../components/DynamicListTable';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import AssetService from '../../services/asset.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, assets: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function AccountAssets() {
	const [{ loading, error, assets, successDelete }, dispatch] = useReducer(reducer, {
		assets: [],
		loading: true,
		error: '',
	});

	const params = useParams();
	const navigate = useNavigate();
	const { id } = params;


	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await AssetService.listAssetsByAccount(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
				toast.error('No se pudo obtener lista');
			}
		};
		fetchData();
	}, [successDelete]);

	async function deleteDebtHandler(asset) {
		if (
			window.confirm(
				'Seguro desea eliminar el activo? Si elimina el activo, seran eliminados todos los gastos relacionados',
			)
		) {
			// deleteRelatedPayments(debt)
			// deleteRelatedInterests(debt)
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await AssetService.delete(asset._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Activo Eliminado');
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
				console.error(getError(error));
				toast.error('Error al eliminar acreedor');
			}
		}
	}

	async function editDebtHandler(asset) {
		navigate(`/AdminScreen/assets/editAsset/${asset._id}`);
	}

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Activos Fijo</title>
			</Helmet>

			<div className='container admin-con'>
				<div className='row adminProdBtnRow align-items-center'>
					{/* <button className='btn btn-info btn-prod-admin'><FaFilter></FaFilter>Filtrar</button> */}
					{/* <button className='btn btn-danger btn-prod-admin'><Link to='/AdminScreen/AgregarProducto'><FaPlusCircle></FaPlusCircle>Agregar Productos</Link></button> */}
				</div>
				<div>
					<DynamicListTable
						data={assets}
						loading={loading}
						error={error}
						actionButtons={{
							editHandler: editDebtHandler,
							deleteHandler: deleteDebtHandler,
						}}
						dataName='assets'
						showCheckboxColumn={false}
						links={true}
						frontPaginator={true}
						exportOption={true}
					/>
				</div>
			</div>
		</div>
	);
}

export default AccountAssets;
