import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet-async';
import { MdOutlineEdit } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import CustomTypeahead from '../../components/CustomTypeahead';
import FormatedNumberInput from '../../components/FormatedNumberInput';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import GenericDropdown from '../../components/SelectDropdown';
import AssetService from '../../services/asset.service';
import BillService from '../../services/bill.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_ASSETS_REQUEST':
		return { ...state, loadingAssets: true };
	case 'FETCH_ASSETS_SUCCESS':
		return { ...state, assets: action.payload, loadingAssets: false };
	case 'FETCH_ASSETS_FAIL':
		return { ...state, loadingAssets: false, error: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, bill: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function EditBills() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, error, bill, loadingAssets, assets }, dispatch] = useReducer(reducer, {
		assets: [],
		loadingAssets: true,
		bill: {},
		loading: true,
		error: '',
	});
	const { dispatch: ctxDispatch } = useContext(Store);
	const [name, setName] = useState();
	const [amount, setAmount] = useState();
	const [expirationDate, setExpirationDate] = useState('');
	const [asset, setAsset] = useState('');
	const [type, setType] = useState();
	const types = ['Periodico', 'Eventual'];
	const [periodicity, setPeriodicity] = useState();
	const [period, setPeriod] = useState(new Date());
	const periods = ['Mensual', 'Anual'];
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();
	const [formatedDate, setFormatedDate] = useState();

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await BillService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				setName(result.data.name);
				setAmount(result.data.amount);
				setExpirationDate(result.data.expirationDate);
				setAsset([result.data.asset]);
				setType(result.data.type);
				setPeriodicity(result.data.periodicity);
				if (result.data.period) {
					setPeriod(Date.parse(result.data.period));
				}
				setFolder(result.data.folder);
				setObservations(result.data.observations);
				setFormatedDate(result.data.expirationDate.substring(0, 10));
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
			// setdebts(result.data);
		};
		const fetchAssets = async () => {
			dispatch({ type: 'FETCH_ASSETS_REQUEST' });
			try {
				const result = await AssetService.list();
				dispatch({ type: 'FETCH_ASSETS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchAssets();
		fetchData();
	}, [id]);

	const editPaymentHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			if (!asset) {
				toast.error('Debe seleccionar un activo.');
				return;
			}
			if (type == 'Periodico' && periodicity != 'Mensual' && periodicity != 'Anual') {
				toast.error('Debe seleccionar periodicidad.');
				return;
			}

			if (periodicity === 'Periodico' && (period === null || period === undefined)) {
				toast.error('Debe seleccionar un periodo.');
				return;
			}
			const requestData = {
				name,
				amount,
				expirationDate,
				asset: asset[0],
				type,
				folder,
				observations,
			};
			if (type === 'Periodico') {
				requestData.periodicity = periodicity;
			} else {
				requestData.periodicity = '';
			}
			if (type === 'Periodico' && period !== null) {
				requestData.period = period;
			}
			const { data } = await BillService.edit(bill._id, requestData);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'CONTACT_UPDATE_SUCCESS', payload: data });
			toast.success('Pago actualizado Correctamente');
			navigate(-1);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL' });
			toast.error(getError(err));
		}
	};
	const selectedDate = (date) => {
		setPeriod(date);
	};
	const [isInvalid, setIsInvalid] = useState(false);

	const handleAssetChange = (selected) => {
		const selectedAsset = selected[0];
		const isValid = selectedAsset ? assets.includes(selectedAsset) : false;
		setIsInvalid(!isValid);
		setAsset(selected);
	};
	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{(loading || loadingAssets) ? (
					<LoadingBox></LoadingBox>
				) : (
					error ? (
						<MessageBox variant='danger'>{error}</MessageBox>
					) : null
				)}				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Editar Gasto</title>
					</Helmet>
					<h1>
						<MdOutlineEdit></MdOutlineEdit>Editar Gasto
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Row>
								<Form.Group className='mb-3 col-6 form-group required' controlId='name'>
									<Form.Label>Nombre</Form.Label>
									<Form.Control
										type='text'
										defaultValue={name}
										onChange={(e) => setName(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3 col-6 form-group required' controlId='amount'>
									<Form.Label>Monto</Form.Label>
									<FormatedNumberInput
										type='number'
										defaultValue={amount}
										onChange={(e) => setAmount(e.target.value)}
										min={0}
									></FormatedNumberInput>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group className='mb-3 col-6 form-group required' controlId='date'>
									<Form.Label>Vencimiento</Form.Label>
									<Form.Control
										type='date'
										defaultValue={formatedDate}
										onChange={(e) => setExpirationDate(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3 col-6 form-group required'>
									<Form.Label>Activo vinculado</Form.Label>
									{bill.asset && bill.asset != null ? (
										<>
											<CustomTypeahead
												id='assets'
												labelKey={(option) => `${option.customId}`}
												onChange={handleAssetChange}
												options={assets}
												selected={asset}
												placeholder='Buscar Activo...'
												clearButton={true}
												linkToCreate='/AdminScreen/newAsset'
												newOption='nuevo Activo'
											/>
											{isInvalid && (
												<div style={{ color: 'red' }}>Activo no seleccionado o invalido</div>
											)}
										</>
									) : (
										<>
											<CustomTypeahead
												id='assets'
												labelKey={(option) => `${option.customId}`}
												onChange={handleAssetChange}
												options={assets}
												placeholder='Buscar Activo...'
												clearButton={true}
												linkToCreate='/AdminScreen/newAsset'
												newOption='nuevo Activo'
											/>
											{isInvalid && (
												<div style={{ color: 'red' }}>Activo no seleccionado invalido</div>
											)}
										</>
									)}
								</Form.Group>
							</Row>
							<Row>
								<Form.Group className='mb-3 col-3 form-group required' id='type'>
									<GenericDropdown
										label='Tipo'
										items={types}
										selected={type}
										onChange={(e) => setType(e.value)}
									/>
								</Form.Group>
								{type === 'Periodico' && (
									<Form.Group className='mb-3 col-3 form-group required' id='other'>
										<GenericDropdown
											label='Periodicidad'
											items={periods}
											selected={periodicity}
											onChange={(e) => setPeriodicity(e.value)}
										/>
									</Form.Group>
								)}
								{type === 'Periodico' && periodicity == 'Anual' && (
									<Form.Group className='mb-3 col-3 form-group required'>
										<Form.Label>Año:</Form.Label>
										<DatePicker
											selected={period}
											dateFormat='yyyy'
											onChange={selectedDate}
											showYearPicker
										/>
									</Form.Group>
								)}

								{type === 'Periodico' && periodicity == 'Mensual' && (
									<Form.Group className='mb-3 col-3 form-group required'>
										<Form.Label>Mes/año:</Form.Label>
										<DatePicker
											selected={period}
											showMonthYearPicker
											onChange={selectedDate}
											dateFormat='MM/yyyy'
										/>
									</Form.Group>
								)}
								<Form.Group as={Col} className='mb-3 col-3' controlId='folder'>
									<Form.Label>Carpeta</Form.Label>
									<Form.Control
										value={folder}
										onChange={(e) => setFolder(e.target.value)}
									></Form.Control>
								</Form.Group>
							</Row>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Observaciones</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									value={observations}
									rows={10}
									onChange={(e) => setObservations(e.target.value)}
								></Form.Control>
							</Form.Group>
						</div>
						<Row className='modal-btns'>
							{/* <button id="goBack" onClick={() => navigate(-1)}>
              <BsArrowLeftCircle /> Atras
            </button> */}
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={editPaymentHandler}>
									Guardar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default EditBills;
