import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import React, { useContext, useState, useReducer, useLayoutEffect } from 'react';
import axios from 'axios';
import { Store } from '../Store';
import { LinkContainer } from 'react-router-bootstrap';
import '../App.css';
import './AdminNavbar.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUserInfo } from '../utils';
import LoadingBox from './LoadingBox';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_COMPANY_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_COMPANY_SUCCESS':
		return { ...state, GotaSettings: action.payload, loading: false };
	case 'FETCH_COMPANY_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function AdminNavbar() {
	const [{ loading }, dispatch] = useReducer(reducer, {
		loading: true,
		error: '',
	});
	const [imageURL, setImageURL] = useState();

	useLayoutEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_COMPANY_REQUEST' });
				const { data } = await axios.get('/api/config/public');
				setImageURL(data.image.fileLink);
				dispatch({ type: 'FETCH_COMPANY_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_COMPANY_FAIL', payload: err.message });
			}
		};
		fetchData();
	}, []);

	const { dispatch: ctxDispatch } = useContext(Store);
	let userInfo = getUserInfo();
	const navigate = useNavigate();

	// const signoutHandler = () => {
	//   ctxDispatch({ type: "USER_SIGNOUT" });
	//   localStorage.removeItem("userInfo");
	//   navigate("/signin");
	// };
	const signoutHandler = async () => {
		const refreshToken = localStorage.getItem('refreshToken');
		const userInfo = JSON.parse(localStorage.getItem('userInfo'));
		try {
			const res = await axios.post(
				'/api/auth/logout',
				{
					refreshToken,
					userId: userInfo._id,
				},
				{
					headers: { Authorization: `Bearer ${userInfo.token}` },
				},
			);
			if (res.status === 200) {
				ctxDispatch({ type: 'USER_SIGNOUT' });
				localStorage.removeItem('userInfo');
				localStorage.removeItem('refreshToken');
				navigate('/signin');
			}
		} catch (error) {
			toast.error('Ocurrio un error al cerrar sesion', error);
		}
	};
	return (
		<div>
			{loading ? <LoadingBox /> : <></>}
			<ToastContainer position='bottom-center' limit={1}></ToastContainer>
			<header className='header-admin'>
				<Navbar className='container-fluid admin-navbar' expand='d-none '>
					<Navbar.Brand className='col-1  navbar-left'>
						<Link className='' to='/'>
							<img src={imageURL} alt='Company logo' />
						</Link>
					</Navbar.Brand>

					<div className='admin-navbar-right d-inline-flex'>
						{userInfo ? (
							<>
								<NavDropdown title={userInfo.name} id='basic-nav-dropdown'>
									<LinkContainer to='/profile'>
										<NavDropdown.Item>Mi Perfil</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to='/AdminScreen/Config'>
										<NavDropdown.Item>Configuracion</NavDropdown.Item>
									</LinkContainer>
									<NavDropdown.Divider />
									<li className='rightlinks'>
										{' '}
										<Link
											className='dropdown-item nav-link-nav'
											to='/signin'
											onClick={signoutHandler}
										>
											<i className='fa fa-sign-out ' aria-hidden='true'></i>
											<span>Salir</span>
										</Link>
									</li>
								</NavDropdown>
							</>
						) : (
							<li className='rightlinks'>
								{' '}
								<Link className='nav-link-nav' to='/signin'>
									<i className='fa fa-user' aria-hidden='true'></i>
									<span className='d-none d-lg-block'>Ingresar</span>
								</Link>
							</li>
						)}
					</div>
				</Navbar>
			</header>
		</div>
	);
}

export default AdminNavbar;
