import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { MdOutlineEdit } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import CustomTypeahead from '../../components/CustomTypeahead';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import accountService from '../../services/account.service';
import contactService from '../../services/contact.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_ACCOUNTS_REQUEST':
		return { ...state, loadingAccounts: true };
	case 'FETCH_ACCOUNTS_SUCCESS':
		return { ...state, accounts: action.payload, loadingAccounts: false };
	case 'FETCH_ACCOUNTS_FAIL':
		return { ...state, loadingAccounts: false, error: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, contact: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function EditContact() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, error, contact, loadingAccounts, accounts }, dispatch] = useReducer(reducer, {
		accounts: [],
		loadingAccounts: true,
		contact: {},
		loading: true,
		error: '',
	});
	const { dispatch: ctxDispatch } = useContext(Store);
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [address, setAddress] = useState();
	const [phone, setPhone] = useState();
	const [account, setAccount] = useState();
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();


	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await contactService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				setName(result.data.name);
				setEmail(result.data.email);
				setAddress(result.data.address);
				setPhone(result.data.phone);
				setAccount([result.data.account]);
				setFolder(result.data.folder);
				setObservations(result.data.observations);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
			// setAccounts(result.data);
		};
		const fetchAccounts = async () => {
			dispatch({ type: 'FETCH_ACCOUNTS_REQUEST' });
			try {
				const result = await accountService.list();
				dispatch({ type: 'FETCH_ACCOUNTS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchAccounts();
		fetchData();
	}, [id]);

	const editContactHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const { data } = await contactService.edit(contact._id, {
				name,
				email,
				address,
				phone,
				account: account[0],
				folder,
				observations,
			});
			// deletePictureHandler(image);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'CONTACT_UPDATE_SUCCESS', payload: data });
			localStorage.setItem('contact', JSON.stringify(data));

			toast.success('Contacto actualizado Correctamente');
			navigate(-1);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL' });
			toast.error(getError(err));
		}
	};

	const [isInvalid, setIsInvalid] = useState(false);

	const handleAccountChange = (selected) => {
		const selectedAccount = selected[0];
		const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
		setIsInvalid(!isValid);
		setAccount(selected);
	};

	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{(loading || loadingAccounts) ? (
					<LoadingBox></LoadingBox>
				) : (
					error ? (
						<MessageBox variant='danger'>{error}</MessageBox>
					) : null
				)}				<Helmet>
					<title>Editar Contacto</title>
				</Helmet>
				<div className='container admin-con align-items-center'>
					<h1>
						<MdOutlineEdit></MdOutlineEdit>Editar Contacto
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<Row>
							<Form.Group className='mb-3 col-6 form-group required' controlId='name'>
								<Form.Label>Nombre</Form.Label>
								<Form.Control
									type='text'
									defaultValue={name}
									onChange={(e) => setName(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3 col-6' controlId='email'>
								<Form.Label>Email</Form.Label>
								<Form.Control
									type='text'
									defaultValue={email}
									onChange={(e) => setEmail(e.target.value)}
								></Form.Control>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group className='mb-3 col-6' controlId='address'>
								<Form.Label>Direccion</Form.Label>
								<Form.Control
									type='text'
									defaultValue={address}
									onChange={(e) => setAddress(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3 col-6' controlId='phone'>
								<Form.Label>Telefono</Form.Label>
								<Form.Control
									type='text'
									defaultValue={phone}
									onChange={(e) => setPhone(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3 col-6'>
								<Form.Label>Cuenta de Contacto</Form.Label>
								{contact.account && contact.account != null ? (
									<>
										<CustomTypeahead
											id='accounts'
											labelKey={(option) => `${option.name}`}
											onChange={handleAccountChange}
											options={accounts}
											placeholder='Buscar Cuenta... '
											selected={account}
											isInvalid={isInvalid}
											linkToCreate='/AdminScreen/newAccount'
											newOption='nueva cuenta'
										/>
										{isInvalid && (
											<div style={{ color: 'red' }}>Cuenta no seleccionada o invalida</div>
										)}
									</>
								) : (
									<>
										<CustomTypeahead
											id='accounts'
											labelKey={(option) => `${option.name}`}
											onChange={handleAccountChange}
											options={accounts}
											placeholder='Buscar Cuenta... '
											isInvalid={isInvalid}
											linkToCreate='/AdminScreen/newAccount'
											newOption='nueva cuenta'
										/>
										{isInvalid && (
											<div style={{ color: 'red' }}>Cuenta no seleccionada o invalida</div>
										)}
									</>
								)}
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-6' controlId='folder'>
								<Form.Label>Carpeta</Form.Label>
								<Form.Control
									value={folder}
									onChange={(e) => setFolder(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Observaciones</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									value={observations}
									rows={10}
									onChange={(e) => setObservations(e.target.value)}
								></Form.Control>
							</Form.Group>
						</Row>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={editContactHandler}>
									Actualizar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default EditContact;
