import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import './AdminFooter.css';
import { Nav } from 'react-bootstrap';

function TabNav() {
	const location = useLocation();
	const route = location.pathname;
	if (!route.includes('AdminScreen')) return null;
	const [key] = useState(route);

	return (
		<div>
			<Nav className='tabNav-nav' variant='tabs' fill activeKey={key} defaultActiveKey={key}>
				{/* <Nav.Item>
<Nav.Link href="/AdminScreen" eventKey="/AdminScreen" title="/AdminScreen">Home

</Nav.Link>
</Nav.Item> */}
				<Nav.Item>
					<Nav.Link
						eventKey='/AdminScreen/accounts'
						href='/AdminScreen/accounts'
						title='/AdminScreen/accounts'
					>
						Cuentas
					</Nav.Link>
				</Nav.Item>
				{/* <Nav.Item>
<Nav.Link eventKey="/AdminScreen/users" href="/AdminScreen/users" title="/AdminScreen/users">Usuarios</Nav.Link>
</Nav.Item> */}
				{/* <Nav.Item>
<Nav.Link eventKey="/AdminScreen/contacts" href="/AdminScreen/contacts" title="/AdminScreen/contacts">Contactos</Nav.Link>
</Nav.Item> */}
				<Nav.Item>
					<Nav.Link
						eventKey='/AdminScreen/debts'
						href='/AdminScreen/debts/search'
						title='/AdminScreen/debts'
					>
						Deudas
					</Nav.Link>
				</Nav.Item>
				{/* <Nav.Item>
<Nav.Link eventKey="/AdminScreen/payments" href="/AdminScreen/payments" title="/AdminScreen/payments">Cobros</Nav.Link>
</Nav.Item> */}
				<Nav.Item>
					<Nav.Link
						eventKey='/AdminScreen/credits'
						href='/AdminScreen/credits'
						title='/AdminScreen/credits'
					>
						Pasividades
					</Nav.Link>
				</Nav.Item>
				{/* <Nav.Item>
<Nav.Link eventKey="/AdminScreen/creditPayments" href="/AdminScreen/creditPayments" title="/AdminScreen/creditPayments">Pagos</Nav.Link>
</Nav.Item> */}
				<Nav.Item>
					<Nav.Link
						eventKey='/AdminScreen/contracts'
						href='/AdminScreen/contracts'
						title='/AdminScreen/contracts'
					>
						Alquileres
					</Nav.Link>
				</Nav.Item>
				{/* <Nav.Item>
<Nav.Link eventKey="/AdminScreen/interests" href="/AdminScreen/interests" title="/AdminScreen/interests">Intereses</Nav.Link>
</Nav.Item> */}
				<Nav.Item>
					<Nav.Link
						eventKey='/AdminScreen/assets'
						href='/AdminScreen/assets'
						title='/AdminScreen/assets'
					>
						Activos Fijos
					</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link
						eventKey='/AdminScreen/reports'
						href='/AdminScreen/reports'
						title='/AdminScreen/reports'
					>
						Reportes
					</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link
						eventKey='/AdminScreen/predictions'
						href='/AdminScreen/predictions'
						title='/AdminScreen/predictions'
					>
						Previsiones
					</Nav.Link>
				</Nav.Item>
				{/* <Nav.Item>
<Nav.Link eventKey="/AdminScreen/bills" href="/AdminScreen/bills" title="/AdminScreen/bills">Gastos de Activo</Nav.Link>
</Nav.Item> */}
			</Nav>
		</div>
	);
}
export default TabNav;
