import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function MessageBox(props) {
	return (
		<Row className='justify-content-center'>
			<Col md={3}>
				<Alert variant={props.variant || 'info'}>
					<span className='text-center'>{props.children}</span>
				</Alert>
			</Col>
		</Row>
	);
}
export default MessageBox;
