import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import CreditUpdatesService from '../services/creditUpdates.service';
import DebtUpdatesService from '../services/debtUpdates.service';
import HistoryLogsService from '../services/historyLog.service';
import LoadingBox from './LoadingBox';
import MessageBox from './MessageBox';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_DEBTUPDATES_REQUEST':
		return { ...state, loadingDebt: true };
	case 'FETCH_DEBTUPDATES_SUCCESS':
		return { ...state, debtUpdates: action.payload, loadingDebt: false };
	case 'FETCH_DEBTUPDATES_FAIL':
		return { ...state, loadingDebt: false, error: action.payload };
	case 'FETCH_CREDITUPDATES_REQUEST':
		return { ...state, loadingCredit: true };
	case 'FETCH_CREDITUPDATES_SUCCESS':
		return { ...state, creditUpdates: action.payload, loadingCredit: false };
	case 'FETCH_CREDITUPDATES_FAIL':
		return { ...state, loadingCredit: false, error: action.payload };
	case 'FETCH_HISTORYLOG_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_HISTORYLOG_SUCCESS':
		return { ...state, historyLogs: action.payload, loading: false };
	case 'FETCH_HISTORYLOG_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function HistoryModal({ show, handleClose, model, id, isHistory }) {
	const [{ error, loading,loadingCredit,loadingDebt,debtUpdates,creditUpdates, historyLogs }, dispatch] = useReducer(
		reducer,
		{
			debtUpdates: [],
			creditUpdates:[],
			historyLogs: [],
			loading: false,
			loadingCredit:false,
			loadingDebt:false,
			error: '',
		},
	);


	let inSpanish = {};
	if (model === 'debt') {
		inSpanish = {
			customId: 'Cuenta:Concepto',
			owner: 'Deudor',
			capital: 'Capital',
			concept: 'Concepto',
			dues: 'Cuotas',
			currency: 'Moneda',
			amount: 'Monto cuota',
			date: 'Fecha',
			endDate: 'Fecha vto',
			periodicity: 'Periodicidad',
			hasPreviousDebts: 'Tiene deuda anterior?',
			previousDebt: 'Deuda Anterior',
			previousDebtDate: 'Fecha de Deuda Anterior',
			interest: '% Interés',
			type: 'Tipo',
			guaranteedAmount: 'Total Garantizado',
			contract: 'Alquiler',
			assetSale: 'Venta de activo',
		};
	} else if (model === 'credit') {
		inSpanish = {
			customId: 'Cuenta:Concepto',
			owner: 'Acreedor',
			capital: 'Capital',
			concept: 'Concepto',
			dues: 'Cuotas',
			currency: 'Moneda',
			amount: 'Monto cuota',
			date: 'Fecha',
			endDate: 'Fecha vto',
			periodicity: 'Periodicidad',
			hasPreviousCredits: 'Tiene credito anterior?',
			previousCredit: 'Credito Anterior',
			previousCreditDate: 'Fecha de Credito Anterior',
			interest: '% Interés',
			type: 'Tipo',
			guaranteedAmount: 'Total Garantizado',
		};}	else if (model === 'contract') {
		inSpanish = {
			closeDate: 'Fecha de finalización',
			status: 'Estado',
		};}	

	const displayValue = (value) => {
		if (typeof value === 'boolean') {
			return value ? 'Si' : 'No';
		}
		else if (value === 'closed') {
			return 'Finalizado';
		}
		return value;
	};

	useEffect(() => {
		if (id) {
			const fetchData = async () => {
				if (model === 'debt'){
					dispatch({ type: 'FETCH_DEBTUPDATES_REQUEST' });
					try {
						const result = await DebtUpdatesService.listFromDebtId(id);
						dispatch({ type: 'FETCH_DEBTUPDATES_SUCCESS', payload: result.data });
					} catch (error) {
						dispatch({ type: 'FETCH_DEBTUPDATES_FAIL', payload: error.message });
					}
				}
				if (model === 'credit'){
					dispatch({ type: 'FETCH_CREDITUPDATES_REQUEST' });
					try {
						const result = await CreditUpdatesService.listFromCreditId(id);
						dispatch({ type: 'FETCH_CREDITUPDATES_SUCCESS', payload: result.data });
					} catch (error) {
						dispatch({ type: 'FETCH_CREDITUPDATES_FAIL', payload: error.message });
					}
				}
			};
			const fetchHistoryData = async () => {
				dispatch({ type: 'FETCH_HISTORYLOG_REQUEST' });
				try {
					const result = await HistoryLogsService.listFromId(id);
					dispatch({ type: 'FETCH_HISTORYLOG_SUCCESS', payload: result.data });
				} catch (error) {
					dispatch({ type: 'FETCH_HISTORYLOG_FAIL', payload: error.message });
				}
			};
			if (isHistory){
				fetchHistoryData();}
			else{
				fetchData();}
		}
	}, [id]);

	const updates = model === 'debt' ? debtUpdates : model === 'credit' ? creditUpdates : [];


	return (
		<>
			{(loading || loadingCredit || loadingDebt)? (
				<LoadingBox></LoadingBox>
			) : 
				error ? (
					<MessageBox variant='danger'>{error}</MessageBox>
				) : !isHistory ? 
					(<Modal show={show} onHide={handleClose} size='md'>
						<Modal.Header closeButton>
							<Modal.Title>Historial de cambios</Modal.Title>
						</Modal.Header>
						<Modal.Body style={{ maxHeight: '60vh', overflowY: 'auto' }}>
							<>
								{updates.length != 0? (updates.map((change, index) => (
									<Row key={index} className="mb-3">
										<Col>
											<Card style={{ position: 'relative' }}>
												<div className="d-flex justify-content-between align-items-center p-2 bg-light">
													<span>Modificación de usuario {change.updatedDateBy.name}</span>
													<span>{moment.utc(change.createdAt).format('DD-MM-YYYY HH:mm')}</span>
												</div>
												<Card.Body className='text-center'>
											La fecha de primer vencimiento fue modificada:<br />
													<strong> {moment.utc(change.firstDueExpirationBefore).format('DD-MM-YYYY')}</strong>
													{' --------->'} <strong>{moment.utc(change.firstDueExpirationUpdated).format('DD-MM-YYYY')}</strong><br />
											Opción seleccionada: <strong>{change.updateDateOption}</strong>
												</Card.Body>
											</Card>
										</Col>
									</Row>
								))):(<MessageBox variant='danger'>No hay cambios de fecha registrados</MessageBox>)}
							</>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>Cerrar historial</Button>
						</Modal.Footer>
					</Modal>):
					(<Modal show={show} onHide={handleClose} size='md'>
						<Modal.Header closeButton>
							<Modal.Title>Historial de cambios</Modal.Title>
						</Modal.Header>
						<Modal.Body style={{ maxHeight: '60vh', overflowY: 'auto' }}>
							<>
								{historyLogs.length !== 0 ? (
									historyLogs.map((change, index) => (
										<Row key={index} className="mb-3">
											<Col>
												<Card style={{ position: 'relative' }}>
													<div className="d-flex justify-content-between align-items-center p-2 bg-light">
														<span>{`Modificación realizada por ${change.responsable.name}`}</span>
														<span>{moment.utc(change.createdAt).format('DD-MM-YYYY HH:mm')}</span>
													</div>
													<Card.Body className='text-center'>
														{change.cambios.map((cambio, cambioIndex) => (
															<div key={cambioIndex}>
																<span>{`Campo modificado: ${inSpanish[cambio.campo]}`}</span><br />
																<span>{`Valor anterior: ${displayValue(cambio.valorAnterior)}`}</span><br />
																<span>{`Nuevo valor: ${displayValue(cambio.valorNuevo)}`}</span><br />
																<br />
															</div>
														))}
													</Card.Body>
												</Card>
											</Col>
										</Row>
									))
								) : (
									<MessageBox variant='danger'>No hay cambios registrados</MessageBox>
								)}
							</>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>Cerrar historial</Button>
						</Modal.Footer>
					</Modal>)
			}
		</>
	);
}

export default HistoryModal;
