import React, { useEffect, useReducer } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { MdAddCircleOutline, MdOutlineAccountTree, MdOutlineWindow } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import AssetService from '../../services/asset.service';
import { getError } from '../../utils';
import AssetBills from '../Assets/AssetBills';
import AssetContracts from '../Assets/AssetContracts';
import AssetInfo from '../Assets/AssetInfo';
import AssetSales from '../Assets/AssetSales';
import './AssetDetails.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, asset: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

export default function AssetDetails() {
	const params = useParams();
	const navigate = useNavigate();
	const { id } = params;

	const [{ asset, loading, error }, dispatch] = useReducer(reducer, {
		asset: {},
		loading: true,
		error: '',
	});

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await AssetService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				toast.error('No se pudo obtener lista');
			}
		};
		fetchData();
	}, [id]);

	async function editDebtHandler() {
		navigate(`/AdminScreen/assets/editAsset/${asset._id}`);
	}
	async function NewBillHandler(e) {
		navigate(`/AdminScreen/assets/asset/${asset._id}/newBill`);
		e.stopPropagation();
	}
	async function NewContractHandler(e) {
		navigate(`/AdminScreen/assets/asset/${asset._id}/newContract`);
		e.stopPropagation();
	}
	async function addAssetSale() {
		navigate(`/AdminScreen/assets/asset/${asset._id}/assetSale`);
	}

	return loading ? (
		<LoadingBox loading={loading}></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div className='container admin-con align-items-center'>
			<Row style={{ margin: '0 0 2.5% 0' }}>
				<Col style={{ alignSelf: 'center' }}>
					<h2>
						<MdOutlineWindow></MdOutlineWindow>Activo:{asset.customId}
					</h2>
				</Col>
				<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						style={{ backgroundColor: 'transparent!important' }}
						id='back-btn'
						className='btn m-2'
						value='back'
						onClick={() => navigate(-1)}
					>
						Atras
					</Button>
					<Button
						className='btn btn-dark m-2'
						value='Crear Cuenta'
						onClick={() => editDebtHandler()}
					>
						Editar Activo
					</Button>
					<Button className='btn btn m-2' value='Crear Venta' onClick={() => addAssetSale()}>
						Venta de Activo
					</Button>
				</Col>
			</Row>
			<div className='container'>
				<div className='jumbotron'>
					<AssetInfo asset={asset}></AssetInfo>
				</div>
				<div className='jumbotron related-data'>
					<h2 style={{ marginBottom: '2.5%' }}>
						<MdOutlineAccountTree></MdOutlineAccountTree>Datos Relacionados
					</h2>
					<Accordion>
						<Accordion>
							<Accordion.Item eventKey='0'>
								<Accordion.Header>
									Contratos
									<Button id='add-btn' onClick={(e) => NewContractHandler(e)}>
										<MdAddCircleOutline></MdAddCircleOutline>Agregar
									</Button>
								</Accordion.Header>
								<Accordion.Body>
									<AssetContracts></AssetContracts>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey='1'>
								<Accordion.Header>
									Gastos
									<Button id='add-btn' onClick={(e) => NewBillHandler(e)}>
										<MdAddCircleOutline></MdAddCircleOutline>Agregar
									</Button>
								</Accordion.Header>
								<Accordion.Body>
									<AssetBills></AssetBills>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey='2'>
								<Accordion.Header>Ventas</Accordion.Header>
								{/* <Accordion.Header>
                  Gastos
                  <Button
                    id="add-btn"
                    onClick={(e) => NewBillHandler(e)}
                  >
                    <MdAddCircleOutline></MdAddCircleOutline>Agregar</Button>
                </Accordion.Header> */}
								<Accordion.Body>
									<AssetSales></AssetSales>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</Accordion>
				</div>
			</div>
		</div>
	);
}
