import Container from 'react-bootstrap/Container';
import React, { useContext, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Store } from '../Store';
import { Helmet } from 'react-helmet-async';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { BsFillEyeFill } from 'react-icons/bs';
import { BsFillEyeSlashFill } from 'react-icons/bs';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';
import LoadingBox from '../components/LoadingBox';

const reducer = (state, action) => {
	switch (action.type) {
	case 'UPDATE_REQUEST':
		return { ...state, loadingUpdate: true };
	case 'UPDATE_SUCCESS':
		return { ...state, loadingUpdate: false };
	case 'UPDATE_FAIL':
		return { ...state, loadingUpdate: false };

	default:
		return state;
	}
};

function ChangePassword() {
	const navigate = useNavigate();
	const params = useParams();
	const { token } = params;
	const { dispatch: ctxDispatch } = useContext(Store);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [passwordType, setPasswordType] = useState('password');
	const [repeatPasswordType, setRepeatPasswordType] = useState('password');

	const togglePassword = () => {
		if (passwordType === 'password') {
			setPasswordType('text');
			return;
		}
		setPasswordType('password');
	};
	const toggleRepeatPassword = () => {
		if (repeatPasswordType === 'password') {
			setRepeatPasswordType('text');
			return;
		}
		setRepeatPasswordType('password');
	};
	const [{ loadingUpdate }, dispatch] = useReducer(reducer, {
		loadingUpdate: false,
	});

	const submitHandler = async (e) => {
		e.preventDefault();
		if (password === confirmPassword) {
			try {
				const { data } = await axios.put(`/api/auth/resetPassword/${token}`, {
					password,
				});
				dispatch({
					type: 'UPDATE_SUCCESS',
				});
				ctxDispatch({ type: 'USER_SIGNIN', payload: data });
				localStorage.setItem('userInfo', JSON.stringify(data));
				navigate('/');
				toast.success('Usuario actualizado correctamente');
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL' });
				toast.error(getError(err));
			}
		} else {
			toast.error('Revise su contraseña');
		}
	};
	return (
		<Container className='small-container'>
			{loadingUpdate && <LoadingBox />}
			<Helmet>
				<title>Cambiar Contraseña</title>
			</Helmet>
			<h1 className='my-3'>Cambia tu contraseña</h1>
			<Form onSubmit={submitHandler}>
				<Form.Label>Contraseña</Form.Label>
				<InputGroup className='mb-3' style={{ padding: 0 }} controlId='password'>
					<Form.Control
						type={passwordType}
						required
						onChange={(e) => setPassword(e.target.value)}
					/>
					<Button
						variant='btn btn--secondary'
						className='eyeButton'
						id='button-addon2'
						onClick={togglePassword}
					>
						{passwordType === 'password' ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
					</Button>
				</InputGroup>
				<Form.Label>Repetir Contraseña</Form.Label>
				<InputGroup className='mb-3' style={{ padding: 0 }} controlId='password'>
					<Form.Control
						type={repeatPasswordType}
						required
						onChange={(e) => setConfirmPassword(e.target.value)}
					/>
					<Button
						variant='btn btn--secondary'
						className='eyeButton'
						id='button-addon2'
						onClick={toggleRepeatPassword}
					>
						{repeatPasswordType === 'password' ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
					</Button>
				</InputGroup>
				<div className='mb-3'>
					<Button type='submit'>Actualizar</Button>
				</div>
			</Form>
		</Container>
	);
}
export default ChangePassword;
