import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from '../../hooks/usePagination';
import './Paginator.css';
import { addAlphaToHex } from '../../utils';

const Paginator = (props) => {
	const secondaryColorDimmed = addAlphaToHex(
		getComputedStyle(document.documentElement)
			.getPropertyValue('--secondaryColor')
			.replace('#', ''),
		0.4,
	);

	const {
		onPageChange,
		totalCount,
		siblingCount = props.siblingCount || 1,
		currentPage,
		pageSize,
		className,
	} = props;

	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	});

	if (currentPage === 0 || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	let lastPage = paginationRange[paginationRange.length - 1];
	return (
		<ul className={classnames('pagination-container', { [className]: className })}>
			<li
				className={classnames('pagination-item', {
					disabled: currentPage === 1,
				})}
				onClick={onPrevious}
			>
				<div className='arrow left' />
			</li>
			{paginationRange.map((pageNumber, i) => {
				if (pageNumber === DOTS) {
					return (
						<li key={i} className='pagination-item dots'>
							&#8230;
						</li>
					);
				}

				return (
					<li
						key={i}
						className={classnames('pagination-item', {
							selected: pageNumber === currentPage,
							tens: pageNumber > 9 && pageNumber <= 17,
							twenties: pageNumber > 17 && pageNumber <= 29,
							'thirties-to-sixties': pageNumber > 29 && pageNumber <= 59,
							sixties: pageNumber > 59 && pageNumber <= 69,
							seventies: pageNumber > 69 && pageNumber <= 79,
							eighties: pageNumber > 79 && pageNumber <= 89,
							nineties: pageNumber > 89 && pageNumber <= 99,
							'three-figure': pageNumber > 99,
						})}
						onClick={() => onPageChange(pageNumber)}
					>
						{pageNumber}
					</li>
				);
			})}
			<li
				className={classnames('pagination-item', {
					disabled: currentPage === lastPage,
				})}
				onClick={onNext}
			>
				<div className='arrow right' />
			</li>
			<style>
				{`
        .pagination-container > * {
          background-color: ${secondaryColorDimmed}
        }
        `}
			</style>
		</ul>
	);
};

export default Paginator;
