import React, { useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import DynamicListTable from '../../components/DynamicListTable';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import debtService from '../../services/debt.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, debtDues: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function DebtDues() {
	const [{ loading, error, debtDues, successDelete }, dispatch] = useReducer(reducer, {
		debtDues: [],
		loading: true,
		error: '',
	});

	const params = useParams();

	const { id } = params;

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await debtService.getDuesForDebt(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Cuotas</title>
			</Helmet>

			<Helmet>
				<title>Lista de Cuotas</title>
			</Helmet>

			<div className='container admin-con'>
				<div className='row adminProdBtnRow align-items-center'></div>
				<DynamicListTable
					data={debtDues}
					loading={loading}
					error={error}
					dataName='debtDues'
					frontPaginator={true}
					exportOption={true}
				/>
			</div>
		</div>
	);
}

export default DebtDues;
