import React from 'react';
import './AdminFooter.css';

function AdminFooter() {
	return (
		<footer className='bg-light admin-footer text-lg-start gotaFooter'>
			<a href='https://gotasoft.io/' target='_blank' title='Powered by Gotasoft' rel='noreferrer'>
				<p className='gotaFooter'>
					Powered by <img src='/LogoBlanco.svg' alt='Gotasoft' />
				</p>
				<p className='copyrightFooter'>©All rights reserved 2022</p>
			</a>
		</footer>
	);
}
export default AdminFooter;
