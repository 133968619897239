import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { MdAddCircleOutline, MdOutlineAccountTree, MdOutlineMoney } from 'react-icons/md';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import debtService from '../../services/debt.service';
import { getError } from '../../utils';
import DebtDues from '../Debts/DebtDues';
import DebtInfo from '../Debts/DebtInfo';
import DebtPayments from '../Debts/DebtPayments';
import HistoryModal from '../../components/HistoryModal';
import './DebtDetails.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, debt: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'UPDATE_REQUEST':
		return { ...state, loadingUpdate: true, successUpdate: false };
	case 'UPDATE_SUCCESS':
		return { ...state, loadingUpdate: false, successUpdate: true };
	case 'UPDATE_FAIL':
		return { ...state, loadingUpdate: false, successUpdate: false };
	case 'UPDATE_RESET':
		return { ...state, loadingUpdate: false, successUpdate: false };
	default:
		return state;
	}
};

export default function DebtDetails() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ debt, loading, successUpdate, loadingUpdate ,error}, dispatch] = useReducer(reducer, {
		debt: {},
		loading: true,
		loadingUpdate:false,
		error: '',
	});

	const [expiredDebt, setExpiredDebt] = useState();
	const [allDuesGenerated, setAllDuesGenerated] = useState();
	const [showHistoryLog, setShowHistoryLog] = useState(false);

	const handleShowHistoryLog = () => {
		setShowHistoryLog(true);
	};

	const handleCloseHistoryLog = () => {
		setShowHistoryLog(false);
	};

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await debtService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				setExpiredDebt(
					result.data.closeDate
						? moment.utc(result.data.closeDate).isBefore(moment.utc())
						: result.data.endDate
							? moment.utc(result.data.endDate).isBefore(moment.utc())
							: false,
				);
				setAllDuesGenerated(
					result.data.generatedDebts && result.data.dues
						? result.data.generatedDebts.length >= result.data.dues
						: false,
				);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		if (successUpdate) {
			fetchData();
			dispatch({ type: 'UPDATE_RESET' });
		} else {
			fetchData();
		}
	}, [id, successUpdate]);

	async function editDebtHandler() {
		navigate(`/AdminScreen/debts/editDebt/${debt._id}`);
	}
	async function refinanciar() {
		try {
			dispatch({ type: 'UPDATE_REQUEST' });
			await debtService.refinanciar(id);
			dispatch({ type: 'UPDATE_SUCCESS' });
			toast.success('Deuda actualizada Correctamente');
		} catch (err) {
			toast.error(getError(err));
			dispatch({ type: 'UPDATE_FAIL' });
		}
	}

	// async function NewInterestHandler(e) {
	// 	navigate(`/AdminScreen/debts/debt/${debt._id}/newInterest`);
	// 	e.stopPropagation();
	// }

	async function NewPaymentHandler(e) {
		navigate(`/AdminScreen/debts/debt/${debt._id}/newPayment`);
		e.stopPropagation();
	}
	async function NewDueHandler(e) {
		navigate(`/AdminScreen/debts/debt/${debt._id}/newDue`);
		e.stopPropagation();
	}

	let nextExpirationDate = null;
	let lastExpirationDate;
	let generationDate = null;

	let periodicityNumMonths = 0;
	if (debt.periodicity === 'mensual') {
		periodicityNumMonths = 1;
	} else if (debt.periodicity === 'semestral') {
		periodicityNumMonths = 6;
	} else if (debt.periodicity === 'anual') {
		periodicityNumMonths = 12;
	} else if (debt.periodicity === 'otra') {
		periodicityNumMonths = debt.periodicityNumMonths;
	}

	let dueNumber = debt.generatedDebts ? Number(debt.generatedDebts.length) + 1 : 1;

	if (debt.generatedDebts && debt.generatedDebts.length > 0) {
		if (
			debt.generatedDebts.length > 0 &&
			debt.generatedDebts[debt.generatedDebts.length - 1].expiration
		) {
			debt.generatedDebts.sort((a, b) => moment.utc(a.expiration).diff(moment.utc(b.expiration)));

			lastExpirationDate = moment.utc(
				debt.generatedDebts[debt.generatedDebts.length - 1].expiration,
			);
		}

		nextExpirationDate = moment
			.utc(lastExpirationDate)
			.add(periodicityNumMonths, 'month')
			.format('DD-MM-YYYY');
		const nextExpirationMoment = moment.utc(nextExpirationDate, 'DD-MM-YYYY');
		generationDate = nextExpirationMoment.startOf('month').format('DD-MM-YYYY');
	} else if (debt.generatedDebts && debt.generatedDebts.length === 0 && debt.firstDueExpiration) {
		nextExpirationDate = moment.utc(debt.firstDueExpiration).format('DD-MM-YYYY');
		const nextExpirationMoment = moment.utc(nextExpirationDate, 'DD-MM-YYYY');
		generationDate = nextExpirationMoment.startOf('month').format('DD-MM-YYYY');
	}

	return (
		<>
			{(loading || loadingUpdate) ? (
				<LoadingBox></LoadingBox>
			) : (
				error ? (
					<MessageBox variant='danger'>{error}</MessageBox>
				) : null
			)}			<div className='container admin-con align-items-center'>
				<Row style={{ margin: '0 0 2.5% 0' }}>
					<Col style={{ alignSelf: 'center' }}>
						<h2>
							<MdOutlineMoney></MdOutlineMoney>Deuda:{debt.customId}
						</h2>
						<Col>
							<span onClick={handleShowHistoryLog} style={{ cursor: 'pointer', textDecoration: 'underline' }} className="btn btn-link p-0">historial de cambios deuda</span>
						</Col>
						<HistoryModal model="debt" show={showHistoryLog} handleClose={handleCloseHistoryLog} id={debt._id} isHistory={true}/>
						{debt.concept === 'alquileres' && debt.contract ? (
							<Link to={`/AdminScreen/contracts/contract/${debt.contract._id}`}>
								<span>ir a Alquiler</span>
							</Link>
						) : debt.concept === 'ventas-a-plazo' && debt.assetSale ? (
							<Link to={`/AdminScreen/assetSales/assetSale/${debt.assetSale._id}`}>
								<span>ir a Venta de Activo</span>
							</Link>
						) : null}
					</Col>
					<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							style={{ backgroundColor: 'transparent!important' }}
							id='back-btn'
							className='btn m-2'
							value='back'
							onClick={() => navigate(-1)}
						>
							Atras
						</Button>
						<Button
							className='btn btn-dark m-2'
							value='Crear Cuenta'
							onClick={() => editDebtHandler()}
						>
							Editar Deuda
						</Button>
						{debt.concept === 'prestamos-interés' && (
							<Button className='btn m-2' value='Crear Cuenta' onClick={() => refinanciar()}>
								Refinanciar Deuda
							</Button>
						)}
					</Col>
				</Row>
				<div className='container'>
					<div className='jumbotron'>
						<DebtInfo debt={debt}></DebtInfo>
					</div>
					<div className='jumbotron related-data'>
						<h2 style={{ marginBottom: '2.5%' }}>
							<MdOutlineAccountTree></MdOutlineAccountTree>Datos Relacionados
						</h2>
						<Accordion>
							<Accordion.Item eventKey='0'>
								<Accordion.Header>
									Cobros
									<Button id='add-btn' onClick={(e) => NewPaymentHandler(e)}>
										<MdAddCircleOutline></MdAddCircleOutline>Agregar
									</Button>
								</Accordion.Header>
								<Accordion.Body>
									<DebtPayments></DebtPayments>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey='1'>
								<Accordion.Header>
									Cuotas
									<Row>
										<span className='m-3'>
											<small className='text-muted'>
												{expiredDebt
													? 'Deuda vencida'
													: allDuesGenerated
														? 'Todas las cuotas fueron creadas'
														: `Próximo vencimiento ${nextExpirationDate}, cuota #${dueNumber} a generarse el ${generationDate}`}
											</small>
										</span>
									</Row>
									<Button onClick={(e) => NewDueHandler(e)}>
										<MdAddCircleOutline></MdAddCircleOutline>Agregar cuota manual
									</Button>
								</Accordion.Header>
								<Accordion.Body>
									<DebtDues></DebtDues>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
				</div>
			</div>
		</>
	);
}
