import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Helmet } from 'react-helmet-async';
import { MdAttachMoney } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination';
import BillService from '../../services/bill.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, bills: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function BillsList() {
	const [{ loading, error, bills, successDelete }, dispatch] = useReducer(reducer, {
		bills: [],
		loading: true,
		error: '',
	});

	const navigate = useNavigate();

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(15); //maxPostsperPage
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = bills.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });

				const { data } = await BillService.list();
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	async function verComprobante(bill) {
		if (bill.comprobante) {
			window.open(bill.comprobante.fileLink, '_blank', 'noopener,noreferrer');
		}
	}

	async function deletePaymentHandler(bill) {
		if (window.confirm('Seguro desea eliminar el gasto?')) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await BillService.delete(bill._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Gasto Eliminado');
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}

	async function editPaymentHandler(bill) {
		navigate(`/AdminScreen/bills/editBill/${bill._id}`);
	}
	async function seeDetails(bill) {
		navigate(`/AdminScreen/bills/bill/${bill._id}`);
	}
	async function newPaymentHandler() {
		navigate('/AdminScreen/newBill');
	}
	async function confirmPayment(bill) {
		navigate(`/AdminScreen/bills/confirmPayment/${bill._id}`);
	}
	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Gastos</title>
			</Helmet>

			<div className='container admin-con'>
				<div className='row adminProdBtnRow align-items-center'>
					{/* <button className='btn btn-info btn-prod-admin'><FaFilter></FaFilter>Filtrar</button> */}
					{/* <button className='btn btn-danger btn-prod-admin'><Link to='/AdminScreen/AgregarProducto'><FaPlusCircle></FaPlusCircle>Agregar Productos</Link></button> */}
				</div>
				<div>
					<Row style={{ margin: '5% 0 2.5%' }}>
						<Col>
							<h1 className='text-right'>
								<MdAttachMoney></MdAttachMoney>Lista de Gastos
							</h1>
						</Col>
						<Col
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<Button
								className='btn btn-dark m-2'
								value='Crear Cuenta'
								onClick={() => newPaymentHandler()}
							>
								Agregar Gasto
							</Button>
						</Col>
					</Row>
					<Table bordered hover responsive size='sm'>
						<thead>
							<tr>
								<th>Id</th>
								<th>Nombre</th>
								<th>Monto</th>
								<th>Vencimiento</th>
								<th>Activo</th>
								<th>Cuenta</th>
								<th>Tipo</th>
								<th>Periodicidad</th>
								<th>Periodo</th>
								<th>Carpeta</th>
								<th>Pago</th>
								<th className='col-1'>Opciones</th>
							</tr>
						</thead>
						{bills
							? currentPosts.map((bill) => (
								<tbody key={bill._id}>
									<tr key={bill._id}>
										<td>
											<Link to={`bill/${bill._id}`}>{bill.customId}</Link>
										</td>
										<td>
											<Link to={`bill/${bill._id}`}>{bill.name}</Link>
										</td>
										<td>{bill.amount}</td>
										<td>{bill.expirationDate.substring(0, 10)}</td>
										<td>
											<Link to={`/AdminScreen/assets/asset/${bill.asset._id}`}>
												{bill.asset.name}
											</Link>
										</td>
										{bill.asset.owner ? (
											<td>
												<Link to={`/AdminScreen/accounts/account/${bill.asset.owner._id}`}>
													{bill.asset.owner.name}
												</Link>
											</td>
										) : (
											<td></td>
										)}
										<td>{bill.type}</td>
										<td>{bill.periodicity}</td>
										{bill.period ? (
											<td>
												{bill.periodicity === 'Anual'
													? moment().year(bill.period).format('YYYY')
													: moment(bill.period).format('MM-YYYY')}
											</td>
										) : (
											<td></td>
										)}
										<td>{bill.folder}</td>
										{bill.confirmed ? (
											<td>
													confirmado{' '}
												{bill.comprobante && bill.comprobante.fileLink && (
													<Link
														style={{ fontSize: '10px' }}
														to=''
														onClick={() => verComprobante(bill)}
													>
															ver comprobante
													</Link>
												)}
											</td>
										) : (
											<td>no</td>
										)}
										<td>
											<DropdownButton drop='start' title=''>
												<Dropdown.Item eventKey='0' onClick={() => seeDetails(bill)}>
														Ver Detalles
												</Dropdown.Item>
												{!bill.confirmed ? (
													<Dropdown.Item eventKey='1' onClick={() => confirmPayment(bill)}>
															Confirmar Pago
													</Dropdown.Item>
												) : (
													''
												)}
												<Dropdown.Item eventKey='2' onClick={() => editPaymentHandler(bill)}>
														Editar
												</Dropdown.Item>
												<Dropdown.Item eventKey='3' onClick={() => deletePaymentHandler(bill)}>
														Eliminar
												</Dropdown.Item>
											</DropdownButton>
										</td>
									</tr>
								</tbody>
							))
							: ''}
					</Table>
					<Pagination
						postsPerPage={postsPerPage}
						totalPosts={bills.length}
						paginate={paginate}
					></Pagination>
				</div>
			</div>
		</div>
	);
}

export default BillsList;
