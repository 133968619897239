import React from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';

const SelectDropdown = ({
	label,
	items,
	selected,
	onChange,
	name,
	isInFilterForm,
	accountsList,
	disabled = false
}) => {
	const customStyles = {
		container: (provided) => ({
			...provided,
			minHeight: '30px!important',
			boxShadow: 'none',
		}),
		control: (provided) => ({
			...provided,
			height: '30px!important',
			border: 0,
			boxShadow: 'none',
			backgroundColor: '#E6E6E6',
		}),
		option: (provided, state) => ({
			...provided,
			color: state.isSelected ? 'white' : 'black',
			backgroundColor: state.isSelected ? 'var(--primaryColor)' : 'white',
			'&:hover': {
				backgroundColor: state.isSelected ? 'var(--primaryColor)' : 'var(--secondaryColor)',
			},
			cursor: 'pointer',
		}),
	};
	let options;
	if (!accountsList) {
		options = [...items.map((item) => ({ value: item, label: item }))];
	} else {
		options = items;
	}

	const selectedOption = options.find((option) => option.value === selected);
	const displayValue = selectedOption ? selected.value : '';

	return (
		<div>
			{isInFilterForm ? (
				<Select
					size='sm'
					options={options}
					isDisabled = {disabled}
					name={name}
					value={displayValue}
					placeholder={'Todos'}
					onChange={(selectedOption) => {
						onChange({ target: { name, value: selectedOption.value } });
					}}
					styles={customStyles}
					isInFilterForm={isInFilterForm}
				/>
			) : (
				<>
					<Form.Label>{label}</Form.Label>
					<Select
						isDisabled = {disabled}
						options={options}
						name={name}
						value={options.find((option) => option.value === selected)}
						placeholder={name === 'duesOptions'?('Elige que hacer con los Datos existentes:'): (`Elige ${label}`)}
						onChange={onChange}
						styles={customStyles}
					/>
				</>
			)}
		</div>
	);
};
export default SelectDropdown;
