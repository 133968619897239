import React, { useEffect, useReducer, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Helmet } from 'react-helmet-async';
import { MdContacts } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination';
import ContactService from '../../services/contact.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, contacts: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function ContactList() {
	const [{ loading, error, contacts, successDelete }, dispatch] = useReducer(reducer, {
		contacts: [],
		loading: true,
		error: '',
	});

	const navigate = useNavigate();
	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(15); //maxPostsperPage
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = contacts.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await ContactService.list();
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	async function deleteContactHandler(contact) {
		if (window.confirm('Seguro desea eliminar el contacto?')) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await ContactService.delete(contact._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Contacto Eliminado');
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}

	async function editContactHandler(contact) {
		navigate(`/AdminScreen/contacts/editContact/${contact._id}`);
	}
	async function seeDetails(contact) {
		navigate(`/AdminScreen/contacts/contact/${contact._id}`);
	}

	async function newContactHandler() {
		navigate('/AdminScreen/newContact');
	}

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Contactos</title>
			</Helmet>

			<div className='container admin-con'>
				<div className='row adminProdBtnRow align-items-center'>
					{/* <button className='btn btn-info btn-prod-admin'><FaFilter></FaFilter>Filtrar</button> */}
					{/* <button className='btn btn-danger btn-prod-admin'><Link to='/AdminScreen/AgregarProducto'><FaPlusCircle></FaPlusCircle>Agregar Productos</Link></button> */}
				</div>
				<Row style={{ margin: '5% 0 2.5%' }}>
					<Col>
						<h1 className='text-right'>
							<MdContacts></MdContacts>Lista de Contactos
						</h1>
					</Col>
					<Col
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<Button
							className='btn btn-dark m-2'
							value='Crear Cuenta'
							onClick={() => newContactHandler()}
						>
							Agregar Contacto
						</Button>
					</Col>
				</Row>
				<Table bordered hover responsive size='sm'>
					<thead>
						<tr>
							<th>Nombre</th>
							<th>Email</th>
							<th>Direccion</th>
							<th>Telefono</th>
							<th>Cuenta</th>
							<th>Carpeta</th>
							<th className='col-1'>Opciones</th>
						</tr>
					</thead>
					{currentPosts
						? currentPosts.map((contact) => (
							<tbody key={contact._id}>
								<tr key={contact._id}>
									<td>
										<Link to={`contact/${contact._id}`}>{contact.name}</Link>
									</td>
									<td>{contact.email}</td>
									<td>{contact.address}</td>
									<td>{contact.phone}</td>
									{contact.account ? (
										<td>
											<Link to={`/AdminScreen/accounts/account/${contact.account._id}`}>
												{contact.account.name}
											</Link>
										</td>
									) : (
										<td></td>
									)}
									<td>{contact.folder}</td>
									<td>
										<DropdownButton drop='start' title=''>
											<Dropdown.Item eventKey='0' onClick={() => seeDetails(contact)}>
													Ver Detalles
											</Dropdown.Item>
											<Dropdown.Item eventKey='1' onClick={() => editContactHandler(contact)}>
													Editar
											</Dropdown.Item>
											<Dropdown.Item eventKey='2' onClick={() => deleteContactHandler(contact)}>
													Eliminar
											</Dropdown.Item>
										</DropdownButton>
									</td>
								</tr>
							</tbody>
						))
						: ''}
				</Table>
				<Pagination
					postsPerPage={postsPerPage}
					totalPosts={contacts.length}
					paginate={paginate}
				></Pagination>
			</div>
		</div>
	);
}

export default ContactList;
