import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { MdOutlineEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { getError } from '../../utils';

import { useNavigate, useParams } from 'react-router-dom';
import debtService from '../../services/debt.service';
import interestService from '../../services/interest.service';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_DEBTS_REQUEST':
		return { ...state, loadingContacts: true };
	case 'FETCH_DEBTS_SUCCESS':
		return { ...state, debts: action.payload, loadingContacts: false };
	case 'FETCH_DEBTS_FAIL':
		return { ...state, loadingContacts: false, error: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, interest: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function EditInterest() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, error, interest, debts }, dispatch] = useReducer(reducer, {
		debts: [],
		interest: {},
		loading: true,
		error: '',
	});
	const { dispatch: ctxDispatch } = useContext(Store);

	const [percentage, setPercentage] = useState();
	const [type, setType] = useState();
	const [debt, setDebt] = useState();
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await interestService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				setPercentage(result.data.percentage);
				setType(result.data.type);
				setDebt(result.data.debt);
				setFolder(result.data.folder);
				setObservations(result.data.observations);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
			// setdebts(result.data);
		};
		const fetchDebts = async () => {
			dispatch({ type: 'FETCH_DEBTS_REQUEST' });
			try {
				const result = await debtService.list();
				dispatch({ type: 'FETCH_DEBTS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchDebts();
		fetchData();
	}, [id]);

	const editPaymentHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const { data } = await interestService.edit(interest._id, {
				percentage,
				type,
				debt,
				folder,
				observations,
			});
			// deletePictureHandler(image);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'CONTACT_UPDATE_SUCCESS', payload: data });
			toast.success('Interes actualizado Correctamente');
			navigate(-1);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL' });
			toast.error(getError(err));
		}
	};

	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{(loading) ? (
					<LoadingBox></LoadingBox>
				) : (
					error ? (
						<MessageBox variant='danger'>{error}</MessageBox>
					) : null
				)}				{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
				{/* <Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Guardar Cambios</Modal.Title>
        </Modal.Header>
        <Modal.Body>Seguro desea guardar los cambios?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={editPaymentHandler}>Confirmar</Button>
        </Modal.Footer>
      </Modal> */}
				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Editar Interes</title>
					</Helmet>
					<h1>
						<MdOutlineEdit></MdOutlineEdit>Editar Interes
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Row>
								<Form.Group className='mb-3 col-6' controlId='amount'>
									<Form.Label>Porcentaje</Form.Label>
									<Form.Control
										type='number'
										defaultValue={percentage}
										onChange={(e) => setPercentage(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3 col-6' controlId='date'>
									<Form.Label>Tipo</Form.Label>
									<Form.Control
										type='text'
										defaultValue={type}
										onChange={(e) => setType(e.target.value)}
									></Form.Control>
								</Form.Group>
							</Row>
							<Row>
								{debt ? (
									<Form.Group className='mb-3 col-6'>
										<Form.Label>Deuda vinculada</Form.Label>
										<Form.Select value={debt._id} onChange={(e) => setDebt(e.target.value)}>
											{debts.map((debt) => {
												return (
													<option key={debt._id} value={debt._id}>
														{debt.owner
															? debt.owner.name + ': ' + debt.internalId
															: debt.internalId}
													</option>
												);
											})}
											<option key='blankChoice' hidden value>
												{' '}
												Elige la Deuda vinculada
											</option>
										</Form.Select>
									</Form.Group>
								) : (
									<Form.Group className='mb-3 col-6'>
										<Form.Label>Deuda vinculada</Form.Label>
										<Form.Select value={debt} onChange={(e) => setDebt(e.target.value)}>
											{debts.map((debt) => {
												return (
													<option key={debt._id} value={debt._id}>
														{debt.owner
															? debt.owner.name + ': ' + debt.internalId
															: debt.internalId}
													</option>
												);
											})}
											<option key='blankChoice' hidden value>
												{' '}
												Elige la Deuda vinculada
											</option>
										</Form.Select>
									</Form.Group>
								)}
								<Form.Group as={Col} className='mb-3 col-6' controlId='folder'>
									<Form.Label>Carpeta</Form.Label>
									<Form.Control
										value={folder}
										onChange={(e) => setFolder(e.target.value)}
									></Form.Control>
								</Form.Group>
							</Row>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Observaciones</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									value={observations}
									rows={10}
									onChange={(e) => setObservations(e.target.value)}
								></Form.Control>
							</Form.Group>
						</div>
						<Row className='modal-btns'>
							{/* <button id="goBack" onClick={() => navigate(-1)}>
              <BsArrowLeftCircle /> Atras
            </button> */}
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={editPaymentHandler}>
									Guardar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default EditInterest;
