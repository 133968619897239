import React, { useEffect, useReducer, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet-async';
import { MdAttachMoney, MdOutlineEdit } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import BillService from '../../services/bill.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_ASSETS_REQUEST':
		return { ...state, loadingContacts: true };
	case 'FETCH_ASSETS_SUCCESS':
		return { ...state, assets: action.payload, loadingContacts: false };
	case 'FETCH_ASSETS_FAIL':
		return { ...state, loadingContacts: false, error: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, bill: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function ConfirmPayment() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, error, bill }, dispatch] = useReducer(reducer, {
		bill: {},
		loading: true,
		error: '',
	});

	const [customId, setCustomId] = useState();
	const [comprobante, setComprobante] = useState('');
	const [uploading, setUploading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await BillService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				setCustomId(result.data.customId);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
			// setdebts(result.data);
		};

		fetchData();
	}, [id]);

	// const editPaymentHandler = async () => {
	// 	try {
	// 		dispatch({ type: 'CREATE_REQUEST' });
	// 		const { data } = await BillService.edit(bill._id, {
	// 			name,
	// 			amount,
	// 			expirationDate,
	// 			asset,
	// 			type,
	// 			periodicity,
	// 			period,
	// 			folder,
	// 			observations,
	// 		});
	// 		// deletePictureHandler(image);
	// 		dispatch({
	// 			type: 'UPDATE_SUCCESS',
	// 		});
	// 		ctxDispatch({ type: 'CONTACT_UPDATE_SUCCESS', payload: data });
	// 		toast.success('Pago actualizado Correctamente');
	// 		navigate(-1);
	// 	} catch (err) {
	// 		dispatch({ type: 'FETCH_FAIL' });
	// 		toast.error(getError(err));
	// 	}
	// };
	// const selectedDate = (date) => {
	// 	setPeriod(date);
	// };

	const uploadComprobante = async () => {
		setUploading(true);
		const file = comprobante[0];
		const formData = new FormData();
		let r = Math.random().toString(36).substring(7);
		formData.append('file', file, file.name.replaceAll(' ') + r);
		if (comprobante) {
			formData.append('documentId', comprobante._id);
		}
		try {
			const response = await BillService.uploadComprobante(formData);
			setComprobante(response.data);
			setUploading(false);
			toast.success('Archivo subido con exito');
		} catch (ex) {
			setUploading(false);
			toast.error(getError(ex));
		}
	};

	const confirmPayment = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await BillService.confirmBilllPayment(bill._id, { comprobante });
			toast.success('Pago Confirmado');
			navigate(-1);
		} catch (ex) {
			toast.error(getError(ex));
		}
	};

	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{(loading || uploading) ? (
					<LoadingBox></LoadingBox>
				) : (
					error ? (
						<MessageBox variant='danger'>{error}</MessageBox>
					) : null
				)}				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Editar Gasto</title>
					</Helmet>
					<h1>
						<MdOutlineEdit></MdOutlineEdit>Confirmar Pago
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='container admin-con align-items-center'>
							<Row style={{ margin: '0 0 2.5% 0' }}>
								<Col style={{ alignSelf: 'center' }}>
									<h2>
										<MdAttachMoney></MdAttachMoney>Gasto:{customId}
									</h2>
								</Col>
							</Row>
							<Row>
								<Form.Group className='col-10' controlid='comprobante'>
									<Form.Label>Comprobante</Form.Label>
									<Form.Control
										type='file'
										required
										onChange={(e) => setComprobante(e.target.files)}
									></Form.Control>
									<Form.Control.Feedback type='invalid'>Seleccione Archivo</Form.Control.Feedback>
								</Form.Group>
								<Button
									className='col-2'
									style={{ height: '30px', marginTop: '32px', padding: '0' }}
									onClick={() => uploadComprobante(comprobante)}
								>
									subir
								</Button>
							</Row>
						</div>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={confirmPayment}>
									Confirmar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default ConfirmPayment;
