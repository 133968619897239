import React, { useEffect, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DynamicListTable from '../../components/DynamicListTable';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import creditPaymentService from '../../services/creditPayment.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, creditPayments: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function CreditPaymentsCredit() {
	const [{ loading, error, creditPayments, successDelete }, dispatch] = useReducer(reducer, {
		creditPayments: [],
		loading: true,
		error: '',
	});

	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await creditPaymentService.getPaymentsForcredit(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	async function deletePaymentHandler(creditPayment) {
		if (window.confirm('Seguro desea eliminar el pago?')) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await creditPaymentService.delete(creditPayment._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Pago Eliminado');
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}
	async function verComprobante(creditPayment) {
		if (creditPayment.comprobante) {
			window.open(creditPayment.comprobante.fileLink, '_blank', 'noopener,noreferrer');
		}
	}
	async function editPaymentHandler(creditPayment) {
		navigate(`/AdminScreen/creditPayments/editCreditPayment/${creditPayment._id}`);
	}
	// async function newPaymentHandler() {
	// 	navigate('/AdminScreen/newCreditPayment');
	// }

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<div className='container admin-con'>
				<div className='row adminProdBtnRow align-items-center'>
					<DynamicListTable
						data={creditPayments}
						loading={loading}
						error={error}
						actionButtons={{
							editHandler: editPaymentHandler,
							deleteHandler: deletePaymentHandler,
							seeAttachment: verComprobante,
						}}
						dataName='creditPayments'
						showCheckboxColumn={false}
						links={true}
						frontPaginator={true}
						exportOption={true}
					/>
				</div>
			</div>
		</div>
	);
}

export default CreditPaymentsCredit;
