import axiosInstance from '../RestClient';

const BASE_URI = '/users';
class UserService {
	async list() {
		const uri = `${BASE_URI}/`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async getDetails(recordId) {
		const uri = `${BASE_URI}/${recordId}`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async create(payload) {
		const response = await axiosInstance.post(`${BASE_URI}/`, payload);
		return response;
	}

	async edit(recordId, payload) {
		const response = await axiosInstance.put(`${BASE_URI}/${recordId}`, payload);
		return response;
	}

	async delete(recordId) {
		const uri = `${BASE_URI}/${recordId}`;
		const response = await axiosInstance.delete(uri);
		return response;
	}
}
export default new UserService();
