import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Modal,Col,Form,Row,OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { MdOutlineEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import { getError } from '../../utils';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useNavigate, useParams } from 'react-router-dom';
import CustomTypeahead from '../../components/CustomTypeahead';
import FormatedNumberInput from '../../components/FormatedNumberInput';
import MessageBox from '../../components/MessageBox';
import GenericDropdown from '../../components/SelectDropdown';
import debtService from '../../services/debt.service';
import paymentService from '../../services/payment.service';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_DEBTS_REQUEST':
		return { ...state, loadingDebts: true };
	case 'FETCH_DEBTS_SUCCESS':
		return { ...state, debts: action.payload, loadingDebts: false };
	case 'FETCH_DEBTS_FAIL':
		return { ...state, loadingDebts: false, error: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, payment: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function EditPayment() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading,loadingDebts, error, payment, debts }, dispatch] = useReducer(reducer, {
		debts: [],
		payment: {},
		loading: true,
		loadingDebts:true,
		error: '',
	});
	const { dispatch: ctxDispatch } = useContext(Store);

	const currencies = ['USD', 'UYU'];

	const [amount, setAmount] = useState();
	const [date, setDate] = useState();
	const [debt, setDebt] = useState('');
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();
	const [formatedDate, setFormatedDate] = useState();
	const [comprobante, setComprobante] = useState();
	const [uploading, setUploading] = useState(false);
	const [debtDue, setDebtDue] = useState();
	const [currency, setCurrency] = useState();
	const [generatedDues, setGeneratedDues] = useState();

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await paymentService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				setAmount(result.data.amount);
				setDate(result.data.date);
				setDebt([result.data.debt]);
				setFolder(result.data.folder);
				setObservations(result.data.observations);
				setFormatedDate(result.data.date.substring(0, 10));
				setComprobante(result.data.comprobante);
				setDebtDue([result.data.debtDue]);
				setCurrency(result.data.currency);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
			// setdebts(result.data);
		};
		const fetchDebts = async () => {
			dispatch({ type: 'FETCH_DEBTS_REQUEST' });
			try {
				const result = await debtService.list();
				dispatch({ type: 'FETCH_DEBTS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchDebts();
		fetchData();
	}, [id]);

	const editPaymentHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const { data } = await paymentService.edit(payment._id, {
				amount,
				date,
				debt: debt[0],
				folder,
				observations,
				comprobante,
				debtDue: debtDue[0],
				currency,
			});
			// deletePictureHandler(image);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'CONTACT_UPDATE_SUCCESS', payload: data });
			toast.success('Cobro actualizado Correctamente');
			navigate(-1);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL' });
			toast.error(getError(err));
		}
	};

	const uploadComprobante = async () => {
		setUploading(true);
		if (!comprobante) {
			toast.warning('Debe seleccionar un archivo');
			setUploading(false);
			return;
		}

		const file = comprobante[0];
		const formData = new FormData();
		let r = Math.random().toString(36).substring(7);
		formData.append('file', file, (file.name || '').replaceAll(' ', '') + r);

		formData.append('documentId', comprobante._id);
		try {
			const response = await paymentService.uploadComprobante(formData);
			setComprobante(response.data);
			setUploading(false);
			toast.success('Archivo Subido');
		} catch (ex) {
			setUploading(false);
			toast.error(getError(ex));
		}
	};

	const [isInvalid, setIsInvalid] = useState(false);
	const [isDebtDueInvalid, setIsDebtDueInvalid] = useState(false);

	const handleDebtChange = (selected) => {
		if (!selected || selected.length === 0) {
			const selectedDebt = selected[0];
			const isValid = debts.includes(selectedDebt);
			setIsInvalid(!isValid);
			setDebt(null);
			setGeneratedDues(null);
			setCurrency('');
			setFolder('');
			setDebtDue(null);
		} else {
			const selectedDebt = selected[0];
			const isValid = debts.includes(selectedDebt);
			setIsInvalid(!isValid);
			setDebt(selectedDebt);
			setGeneratedDues(selectedDebt.generatedDebts);
			setCurrency(selectedDebt.currency);
			setFolder(selectedDebt.owner.folder);
		}
	};

	const handleDebtDueChange = (selected) => {
		if (generatedDues) {
			if (!selected || selected.length === 0) {
				const selectedDebtDue = selected[0];
				const isValid = generatedDues.includes(selectedDebtDue);
				setIsDebtDueInvalid(!isValid);
				setDebtDue(null);
			} else {
				const selectedDebtDue = selected[0];
				const isValid = generatedDues.includes(selectedDebtDue);
				setIsDebtDueInvalid(!isValid);
				setDebtDue(selectedDebtDue);
				setAmount(selectedDebtDue.amount);
			}
		}
	};

	const isButtonEnabled = () => {
		const emptyFields = [];
		if (!debt) {
			emptyFields.push('Deudor');
		}
		if (!debtDue) {
			emptyFields.push('cuota');
		}
		if(!date) {
			emptyFields.push('Fecha');
		} 
		if (!amount || (amount <= 0)) {
			emptyFields.push('Monto');
		}
		return emptyFields.length === 0;
	};

	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{(loading || uploading || loadingDebts) ? (
					<LoadingBox></LoadingBox>
				) : (
					error ? (
						<MessageBox variant='danger'>{error}</MessageBox>
					) : null
				)}
				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Editar Cobro</title>
					</Helmet>
					<h1>
						<MdOutlineEdit></MdOutlineEdit>Editar Cobro
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Form.Group className='mb-3 col-6 form-group required'>
								<Form.Label>Deuda</Form.Label>
								{payment.debt && payment.debt != null ? (
									<>
										<CustomTypeahead
											id='debts'
											labelKey={(option) => `${option.customId}`}
											onChange={handleDebtChange}
											selected={debt}
											options={debts}
											disabled
											placeholder='Buscar deuda...'
											linkToCreate='/AdminScreen/newDebt'
											newOption='nueva deuda'
										/>
										{isInvalid && (
											<div style={{ color: 'red' }}>Deuda no seleccionada o invalida</div>
										)}
									</>
								) : (
									<>
										<CustomTypeahead
											id='debts'
											labelKey={(option) => `${option.customId}`}
											onChange={handleDebtChange}
											options={debts}
											disabled
											placeholder='Buscar deuda...'
											linkToCreate='/AdminScreen/newDebt'
											newOption='nueva deuda'
										/>
										{isInvalid && (
											<div style={{ color: 'red' }}>Deuda no seleccionada o invalida</div>
										)}
									</>
								)}
							</Form.Group>
							<Form.Group className='mb-3 col-6 form-group required'>
								<Form.Label>Cuota Deuda</Form.Label>
								<CustomTypeahead
									id='debts'
									labelKey={(option) => `${option.customId}`}
									onChange={handleDebtDueChange}
									selected={debtDue}
									options={generatedDues ? generatedDues : []}
									placeholder='Buscar cuota...'
									linkToCreate=''
									disabled
									newOption={null}
									newSelectionPrefix={null}
								/>
								{isDebtDueInvalid && <div style={{ color: 'red' }}>invalido</div>}
							</Form.Group>
							<Form.Group className='mb-3 col-6 form-group required' controlId='amount'>
								<Form.Label>Monto</Form.Label>
								<FormatedNumberInput
									type='number'
									value={amount}
									onChange={(e) => setAmount(e.target.value)}
									min={0}
								></FormatedNumberInput>
							</Form.Group>
							<Form.Group className='mb-3 col-6 form-group required' controlId='date'>
								<Form.Label>Fecha</Form.Label>
								<Form.Control
									type='date'
									defaultValue={formatedDate}
									onChange={(e) => setDate(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-3' controlId='currency'>
								<GenericDropdown
									label='Moneda'
									items={currencies}
									selected={currency}
									onChange={(e) => setCurrency(e.value)}
								/>
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-6' controlId='folder'>
								<Form.Label>Carpeta</Form.Label>
								<Form.Control
									value={folder}
									onChange={(e) => setFolder(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group className='col-10' controlid='escritura' value={comprobante}>
								<Form.Label>Comprobante</Form.Label>
								<Form.Control
									type='file'
									required
									onChange={(e) => setComprobante(e.target.files)}
								></Form.Control>
								<Form.Control.Feedback type='invalid'>Seleccione Archivo</Form.Control.Feedback>
							</Form.Group>
							<Button
								className='col-2'
								style={{ height: '30px', marginTop: '32px', padding: '0' }}
								onClick={() => uploadComprobante(comprobante)}
							>
								subir
							</Button>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Observaciones</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									value={observations}
									rows={10}
									onChange={(e) => setObservations(e.target.value)}
								></Form.Control>
							</Form.Group>
						</div>

						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							{!isButtonEnabled() ? (
								<OverlayTrigger
									placement='right'
									overlay={
										<Tooltip id='tooltip-payment'>
											Para editar cobro debe completar los campos obligatorios: Monto y Fecha
										</Tooltip>
									}
								>
									<Col>
										<Button className='guardar' onClick={editPaymentHandler} disabled={!isButtonEnabled()}>
									Guardar
										</Button>
									</Col>
								</OverlayTrigger>
							) : (
								<Col>
									<Button className='guardar' onClick={editPaymentHandler} disabled={false}>
										Guardar
									</Button>
								</Col>
							)}						
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default EditPayment;
