import Container from 'react-bootstrap/Container';
import React, { useContext, useReducer, useState } from 'react';
import { Store } from '../Store';
import { Helmet } from 'react-helmet-async';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { getError, getUserInfo } from '../utils';
import axiosInstance from '../RestClient';
import LoadingBox from '../components/LoadingBox';

const reducer = (state, action) => {
	switch (action.type) {
	case 'UPDATE_REQUEST':
		return { ...state, loadingUpdate: true };
	case 'UPDATE_SUCCESS':
		return { ...state, loadingUpdate: false };
	case 'UPDATE_FAIL':
		return { ...state, loadingUpdate: false };

	default:
		return state;
	}
};

function ProfileScreen() {
	const { dispatch: ctxDispatch } = useContext(Store);
	const userInfo = getUserInfo();
	const [name, setName] = useState(userInfo.name);
	const [email, setEmail] = useState(userInfo.email);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [{ loadingUpdate }, dispatch] = useReducer(reducer, {
		loadingUpdate: false,
	});

	const submitHandler = async (e) => {
		e.preventDefault();
		if (password === confirmPassword) {
			try {
				//User serice post
				const { data } = await axiosInstance.put(
					'/api/users/profile',
					{
						name,
						email,
						password,
					},
					{
						headers: { Authorization: `Bearer ${userInfo.token}` },
					},
				);
				dispatch({
					type: 'UPDATE_SUCCESS',
				});
				ctxDispatch({ type: 'USER_SIGNIN', payload: data });
				localStorage.setItem('userInfo', JSON.stringify(data));
				toast.success('Usuario actualizado correctamente');
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL' });
				toast.error(getError(err));
			}
		} else {
			toast.error('Revise su contraseña');
		}
	};
	return (
		<Container className='small-container'>
			{loadingUpdate && <LoadingBox></LoadingBox>}
			<Helmet>
				<title>Perfil</title>
			</Helmet>
			<h1 className='my-3'>Perfil</h1>
			<Form onSubmit={submitHandler}>
				<Form.Group className='mb-3' controlId='name'>
					<Form.Label>Nombre</Form.Label>
					<Form.Control
						value={name}
						onChange={(e) => setName(e.target.value)}
						required
					></Form.Control>
				</Form.Group>
				<Form.Group className='mb-3' controlId='email'>
					<Form.Label>Email</Form.Label>
					<Form.Control
						type='email'
						value={email}
						onChange={(e) => setEmail(e.target.value.toLowerCase())}
						required
					></Form.Control>
				</Form.Group>
				<Form.Group className='mb-3' controlId='password'>
					<Form.Label>Contraseña</Form.Label>
					<Form.Control
						type='password'
						onChange={(e) => setPassword(e.target.value)}
						required
					></Form.Control>
				</Form.Group>
				<Form.Group className='mb-3' controlId='confirmPassword'>
					<Form.Label>Repetir Contraseña</Form.Label>
					<Form.Control
						type='password'
						onChange={(e) => setConfirmPassword(e.target.value)}
						required
					></Form.Control>
				</Form.Group>
				<div className='mb-3'>
					<Button type='submit'>Update</Button>
				</div>
			</Form>
		</Container>
	);
}
export default ProfileScreen;
