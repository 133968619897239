import React, { useEffect, useReducer, useState } from 'react';
import { Button } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { MdOutlinePayments } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import AccountService from '../../services/account.service';
import ContactService from '../../services/contact.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_ACCOUNTS_REQUEST':
		return { ...state, loadingAccounts: true };
	case 'FETCH_ACCOUNTS_SUCCESS':
		return { ...state, accounts: action.payload, loadingAccounts: false };
	case 'FETCH_ACCOUNTS_FAIL':
		return { ...state, loadingAccounts: false, error: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, contact: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function ContactInfo() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, error, contact, loadingAccounts, accounts }, dispatch] = useReducer(reducer, {
		accounts: [],
		loadingAccounts: true,
		contact: {},
		loading: true,
		error: '',
	});
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [address, setAddress] = useState();
	const [phone, setPhone] = useState();
	const [account, setAccount] = useState();
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await ContactService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				setName(result.data.name);
				setEmail(result.data.email);
				setAddress(result.data.address);
				setPhone(result.data.phone);
				setAccount(result.data.account);
				setObservations(result.data.observations);
				setFolder(result.data.folder);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
			// setAccounts(result.data);
		};
		const fetchAccounts = async () => {
			dispatch({ type: 'FETCH_ACCOUNTS_REQUEST' });
			try {
				const result = await AccountService.list();
				dispatch({ type: 'FETCH_ACCOUNTS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchAccounts();
		fetchData();
	}, [id]);
	async function editPaymentHandler() {
		navigate(`/AdminScreen/contacts/editContact/${id}`);
	}

	return (
		<div>
			{(loading || loadingAccounts) ? (
				<LoadingBox></LoadingBox>
			) : (
				error ? (
					<MessageBox variant='danger'>{error}</MessageBox>
				) : null
			)}			<div className='container admin-con align-items-center'>
				<Row style={{ margin: '0 0 2.5% 0' }}>
					<Col style={{ alignSelf: 'center' }}>
						<h2>
							<MdOutlinePayments></MdOutlinePayments>Contacto:{contact.name}
						</h2>
					</Col>
					<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							style={{ backgroundColor: 'transparent!important' }}
							id='back-btn'
							className='btn m-2'
							value='back'
							onClick={() => navigate(-1)}
						>
							Atras
						</Button>
						<Button
							className='btn btn-dark m-2'
							value='Crear Cuenta'
							onClick={() => editPaymentHandler()}
						>
							Editar Contacto
						</Button>
					</Col>
				</Row>
				<div className='jumbotron'>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<Row className='align-items-center'>
							<Form.Group className='mb-3 col-6' controlId='name'>
								<Form.Label>Nombre</Form.Label>
								<Form.Control
									type='text'
									defaultValue={name}
									disabled
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3 col-6' controlId='email'>
								<Form.Label>Email</Form.Label>
								<Form.Control
									type='text'
									defaultValue={email}
									disabled
								></Form.Control>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group className='mb-3 col-6' controlId='address'>
								<Form.Label>Direccion</Form.Label>
								<Form.Control
									type='text'
									defaultValue={address}
									disabled
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3 col-6' controlId='phone'>
								<Form.Label>Telefono</Form.Label>
								<Form.Control
									type='text'
									defaultValue={phone}
									disabled
								></Form.Control>
							</Form.Group>
							{account ? (
								<Form.Group className='mb-3 col-6'>
									<Form.Label>Cuenta de Contacto</Form.Label>
									<Form.Select value={account._id} disabled>
										{accounts.map((acc) => {
											return (
												<option key={acc._id} value={acc._id}>
													{acc.name}
												</option>
											);
										})}
										<option key='blankChoice' hidden value>
											{' '}
											Elige la cuenta Vinculada{' '}
										</option>
									</Form.Select>
								</Form.Group>
							) : (
								<Form.Group className='mb-3 col-6'>
									<Form.Label>Cuenta de Contacto</Form.Label>
									<Form.Select value={account} disabled>
										{accounts.map((acc) => {
											return (
												<option key={acc._id} value={acc._id}>
													{acc.name}
												</option>
											);
										})}
										<option key='blankChoice' hidden value>
											No tiene Cuenta vinculada{' '}
										</option>
									</Form.Select>
								</Form.Group>
							)}
							<Form.Group as={Col} className='mb-3 col-6' controlId='folder'>
								<Form.Label>Carpeta</Form.Label>
								<Form.Control
									value={folder}
									disabled
								></Form.Control>
							</Form.Group>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Observaciones</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									value={observations}
									rows={10}
									disabled
								></Form.Control>
							</Form.Group>
						</Row>
					</Form>
					{/* <div className="row align-items-centers">
            <div
              style={{
                padding: 0,
                width: "300px",
                height: "300px",
                margin: "auto",
                border: "1px solid black",
                backgroundImage:
                  image && image.fileLink
                    ? "url(" + image.fileLink + ")"
                    : "url(/images/emptyPhoto.png)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% auto",
                backgroundPosition: "center top",
              }}
            >
              <AvatarImageCropper apply={apply} text="Adjuntar la imagen" />
            </div>
          </div> */}
				</div>
			</div>
		</div>
	);
}
export default ContactInfo;
