import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, ListGroup, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsPlus } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination';
import userService from '../../services/user.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, users: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function UsersList() {
	const [{ loading, error, users, successDelete }, dispatch] = useReducer(reducer, {
		users: [],
		loading: true,
		error: '',
	});

	const navigate = useNavigate();

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(15); //maxPostsperPage
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = users.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await userService.list();
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	async function deleteUserHandler(user) {
		if (window.confirm('Seguro desea eliminar el Usuario?')) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await userService.delete(user._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Usuario Eliminado');
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}



	async function newUserHandler() {
		navigate('/AdminScreen/newUser');
	}

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Usuarios</title>
			</Helmet>

			<div className='container admin-con'>
				<div className='row adminProdBtnRow align-items-center'>
					{/* <button className='btn btn-info btn-prod-admin'><FaFilter></FaFilter>Filtrar</button> */}
					{/* <button className='btn btn-danger btn-prod-admin'><Link to='/AdminScreen/AgregarProducto'><FaPlusCircle></FaPlusCircle>Agregar Productos</Link></button> */}
				</div>
				<div>
					<h1 className='text-center'>Lista de Usuarios</h1>
					<div
						style={{
							display: 'flex',
							justifyContent: 'right',
							alignItems: 'center',
						}}
					>
						<Button
							className='btn btn-dark m-2 fixed-right'
							value='Crear Usuario'
							onClick={() => newUserHandler()}
						>
							<span>
								<BsPlus /> Crear Usuario
							</span>
						</Button>
					</div>
					{loading ? (
						<LoadingBox></LoadingBox>
					) : error ? (
						<MessageBox variant='danger'>{error}</MessageBox>
					) : (
						<ListGroup>
							<ListGroup.Item>
								<Row className='align-items-center table-order'>
									{/* <Col md={2}>
                    <span>ID</span>
                  </Col> */}
									<Col md={2}>
										<span>Nombre</span>
									</Col>
									<Col md={2}>Email</Col>
									<Col md={1}>Verificado?</Col>
									<Col md={1}></Col>
									{/* <Col md={1}></Col> */}
								</Row>
							</ListGroup.Item>
						</ListGroup>
					)}
				</div>
				<div className='con-data'>
					{currentPosts.map((user) => (
						<div className='Container ' id='data' key={user._id}>
							<ListGroup>
								<ListGroup.Item key={user._id}>
									<Row className='align-items-center table-order'>
										{/* <Col md={2}>{user._id}</Col> */}
										<Col md={2}>{user.name}</Col>
										<Col md={2}>{user.email}</Col>
										<Col md={1}>{user.isVerified ? 'Si' : 'No'}</Col>
										{/* <Col md={1}>
                      <Link to={`/user/${user._id}`}>
                        <Button
                          variant="light"
                          className="btn-outline-info btn light"
                        >
                          Ver Detalles
                        </Button>
                      </Link>
                    </Col> */}
										{/* <Col md={1}>
                      <Button
                        className="btn-outline-success btn light"
                        onClick={() => editUserHandler(user)}
                      >
                        Editar Usuario
                      </Button>
                    </Col> */}
										<Col md={1}>
											{/* <Col md={2}>{order.user._id */}
											<Button
												className='btn-outline-warning btn light'
												onClick={() => deleteUserHandler(user)}
											>
												Eliminar Usuario
											</Button>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>
						</div>
					))}
				</div>
				<Pagination
					postsPerPage={postsPerPage}
					totalPosts={users.length}
					paginate={paginate}
				></Pagination>
			</div>
		</div>
	);
}

export default UsersList;
