import React, { useEffect, useReducer, useState } from 'react';

import { Button, Card, Col, Container, Form, Modal, Row, Stack } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import contractService from '../../services/contract.service';
import userService from '../../services/user.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return { ...state, contacts: action.payload, loadingFetch: false };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function AddUser() {
	const navigate = useNavigate();

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	//const [passwordType, setPasswordType] = useState('password');
	const [showModal, setShowModal] = useState(false);
	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);


	const [{ loadingFetch, loadingCreate }, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		loadingCreate: false,
		error: '',
	});

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await contractService.list();
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, []);

	const addUserHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await userService.create({
				name,
				email,
				password: '123456',
				isAdmin: true,
			});
			toast.success('Usuario creado');
			navigate('/AdminScreen/users');
		} catch (ex) {
			toast.error(getError(ex));
		}
	};
	return (
		<div>
			<div>
				{loadingCreate && loadingFetch && <LoadingBox></LoadingBox>}
				<Modal size='lg' show={showModal} onHide={handleClose} animation={true}>
					<Modal.Header closeButton>
						<Modal.Title>Crear Usuario</Modal.Title>
					</Modal.Header>
					<Modal.Body>Seguro desea crear usuario?</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={handleClose}>
							Cancelar
						</Button>
						<Button onClick={addUserHandler}>Confirmar</Button>
					</Modal.Footer>
				</Modal>
			</div>
			<Container className='large-container'>
				<Helmet>
					<title>Crear Usuario</title>
				</Helmet>
				<Container fluid>
					<Row className='vh-100 d-flex justify-content-center align-items-center'>
						<Col md={8} lg={6} xs={12}>
							<div className='border border-3 border-primary'></div>
							<Card className='shadow'>
								<Card.Body>
									<div className='mb-3 mt-md-4'>
										<h2 className='fw-bold mb-4 text-uppercase text-align-center '>
											Crear Usuario
										</h2>
										<div className='mb-3'>
											<Form onSubmit={(e) => e.preventDefault()}>
												<Form.Group className='mb-3' controlid='name'>
													<Form.Label>Nombre:</Form.Label>
													<Form.Control
														type='text'
														required
														onChange={(e) => setName(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Group className='mb-3' controlid='email'>
													<Form.Label>E-mail</Form.Label>
													<Form.Control
														type='email'
														required
														onChange={(e) => setEmail(e.target.value.toLowerCase())}
													></Form.Control>
												</Form.Group>
												<Form.Label>Contraseña</Form.Label>
												<Stack direction='horizontal' gap={10}>
													<Button id='goBack' onClick={() => navigate(-1)}>
														<BsArrowLeftCircle /> Atras
													</Button>

													<Button type='submit' className='mx-auto' onClick={handleShow}>
														Crear Usuario
													</Button>
												</Stack>
												{/* <div className="mb-3">
                          
                       
                        </div> */}
											</Form>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</Container>
		</div>
	);
}
export default AddUser;
