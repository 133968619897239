import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { MdLibraryAdd, MdWarning } from 'react-icons/md';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import CustomTypeahead from '../../components/CustomTypeahead';
import MessageBox from '../../components/MessageBox';
import FormatedNumberInput from '../../components/FormatedNumberInput';
//import LoadingBox from '../../components/LoadingBox';
import GenericDropdown from '../../components/SelectDropdown';
import accountService from '../../services/account.service';
import assetSaleService from '../../services/assetSale.service';
import contractService from '../../services/contract.service';
import debtService from '../../services/debt.service';
import { concepts, currencies, dueDays, getError, monthOptions, periodicities } from '../../utils';
import SpinnerOverlay from '../../components/SpinnerOverlay/SpinnerOverlay';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'FETCH_ACCOUNT_SUCCESS':
		return { ...state, accounts: action.payload};
	case 'FETCH_CONTRACTS_SUCCESS':
		return { ...state, contracts: action.payload};
	case 'FETCH_ASSETSALES_SUCCESS':
		return { ...state, assetSales: action.payload};
	case 'CREATE_REQUEST':
		return { ...state, loading: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loading: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loading: false };
	case 'CALC':
		return { ...state, loading: true };
	case 'CALC_SUCCESS':
		return {
			...state,
			loading: false,
		};
	case 'CALC_FAIL':
		return { ...state, loading: false };
	default:
		return state;
	}
};
function AddDebt() {
	const navigate = useNavigate();
	const { state } = useContext(Store);

	const [capital, setCapital] = useState();
	const [amount, setAmount] = useState(0);
	const [interest, setInterest] = useState(0);
	const [selectedConcept, setSelectedConcept] = useState();
	const [concept, setConcept] = useState();

	const [dues, setDues] = useState();
	const [date, setDate] = useState();
	const [contract, setContract] = useState();
	const [periodicity, setPeriodicity] = useState('mensual');
	const [owner, setOwner] = useState();
	const [disabledInput, setDisabledInput] = useState(true);
	const [previousDebt, setPreviousDebt] = useState();
	const [previousDebtDate, setPreviousDebtDate] = useState();
	const [currency, setCurrency] = useState();
	const [endDate, setEndDate] = useState();
	const [disabledAmount, setDisabledAmount] = useState(false);
	const [disabledOwner, setDisabledOwner] = useState(false);
	const [disabledEndDate, setDisabledEndDate] = useState(false);
	const [filteredContracts, setFilteredContracts] = useState();
	//error message for typeahead account and property
	const [isInvalid, setIsInvalid] = useState(false);
	const [hasPreviousDebts, setHasPreviousDebts] = useState(false);
	const [isPropertyInvalid, setIsPropertyInvalid] = useState(false);
	const [isAssetInvalid, setIsAssetInvalid] = useState(false);
	const [assetSale, setAssetSale] = useState();
	const [periodicityNumMonths, setPeriodicityNumMonths] = useState();
	const currentDate = moment.utc();
	const [createWithPreviousDues, setCreateWithPreviousDues] = useState(false);
	const [createWithPreviousPayments, setCreateWithPreviousPayments] = useState(false);
	const [dueDay, setDueDay] = useState('10'); //first due generation year always next to selected date
	const [firstDueExpiration, setFirstDueExpiration] = useState(); //first due generation date always next to selected date
	const [numMonths, setNumMonths] = useState(0);
	const [selectedMonthOption, setSelectedMonthOption] = useState('Mes actual');

	const [{  loading, accounts, error, contracts, assetSales }, dispatch] =
		useReducer(reducer, {
			loading: true,
			accounts: [],
			contracts: [],
			assetSales: [],
			error: '',
		});
	const params = useParams();
	const { id } = params;
	const location = useLocation();
	const route = location.pathname;

	useEffect(() => {
		fetchData();
	}, []);
	useEffect(() => {
		generateDate();
		dateHandler();
	}, [date, selectedMonthOption, dueDay, numMonths, firstDueExpiration]);
	//#region REQUESTS
	const fetchAccountData = async () => {
		const result = await accountService.list();
		if (route.includes('account')) {
			const selectedAccount = result.data.filter((accountData) => accountData._id === id);
			setOwner(selectedAccount[0]);
			setDisabledOwner(true);
			setCurrency(selectedAccount[0].currency);
		}
		dispatch({ type: 'FETCH_ACCOUNT_SUCCESS', payload: result.data });
	};

	const fetchContractsData = async () => {
		const result = await contractService.list();
		dispatch({ type: 'FETCH_CONTRACTS_SUCCESS', payload: result.data });
		if (route.includes('contract')) {
			setSelectedConcept('alquileres');
			setConcept('alquileres');
			const selectedContract = result.data.filter((contractData) => contractData._id === id);
			setContract(selectedContract);
			setOwner(selectedContract[0].owner);
			setAmount(selectedContract[0].amount);
			setCurrency(selectedContract[0].currency);
			setEndDate(selectedContract[0].periodEnd);
			setDisabledEndDate(true);
			setDisabledOwner(true);
		}
		if (route.includes('account')) {
			setFilteredContracts(result.data.filter((contract) => contract.owner?._id === id));
		}
	};
	const fetchAssetSalesData = async () => {
		const result = await assetSaleService.list();
		dispatch({ type: 'FETCH_ASSETSALES_SUCCESS', payload: result.data });
		if (route.includes('assetSale')) {
			const selectedAssetSale = result.data.filter((assetSaleData) => assetSaleData._id === id);
			setOwner(selectedAssetSale[0].purchaser);
			setCurrency(selectedAssetSale[0].currency);
			setConcept('ventas-a-plazo');
			setSelectedConcept('ventas-a-plazo');
			setAssetSale(selectedAssetSale);
			setDisabledInput(false);
		}
	};
	async function fetchData() {
		try {
			dispatch({type: 'FETCH_REQUEST'});
			await Promise.all([fetchAccountData(),
				fetchContractsData(),
				fetchAssetSalesData()]);
			dispatch({type: 'FETCH_SUCCESS'});
		} catch(ex) {
			console.error(ex);
			dispatch({type: 'FETCH_FAIL', payload: getError(ex)});
			toast.error(getError(ex));
		}
	}
	//#endregion
	const isAddDebtButtonEnabled = () => {
		const emptyFields = [];
		if (selectedConcept === 'otro' && !concept) {
			emptyFields.push('Otro Concepto');
		}
		if (concept == 'alquileres' && !contract) {
			emptyFields.push('Propiedad');
		}
		if(selectedConcept != 'alquileres') {
			if(isNaN(parseInt(interest))) {
				emptyFields.push('Interés');
			}
			if(!capital) {
				emptyFields.push('Capital');
			}
			if (!periodicity) {
				emptyFields.push('Periodicidad');
			}
		}
		if(!dues && (selectedConcept === 'prestamos-amortizable' || selectedConcept === 'ventas-a-plazo')) {
			emptyFields.push('Cuotas');
		} 
		if (!owner) {
			emptyFields.push('Cuenta');
		}

		if (!firstDueExpiration) {
			emptyFields.push('Datos generación cuota');
		}
		return emptyFields.length === 0;
	};

	const dateHandler = () => {
		if (moment.utc(firstDueExpiration).isBefore(moment.utc(currentDate))) {
			setCreateWithPreviousDues(true);
		} else {
			setCreateWithPreviousDues(false);
			setCreateWithPreviousPayments(false);
		}
	};

	const addDebtHandler = async () => {
		const emptyFields = [];
		if (!date) {
			emptyFields.push('Fecha');
		}
		if (!selectedConcept && !concept) {
			emptyFields.push('Concepto');
		}
		if (selectedConcept === 'otro' && !concept) {
			emptyFields.push('Otro Concepto');
		}
		if (!amount) {
			emptyFields.push('Monto');
		}
		if (concept == 'alquileres' && !contract) {
			emptyFields.push('Propiedad');
		}
		if (concept == 'ventas-a-plazo' && !assetSale) {
			emptyFields.push('Venta de activo fijo');
		}
		if (
			(selectedConcept === 'prestamos-amortizable' ||
				selectedConcept === 'prestamos-interés' ||
				selectedConcept === 'ventas-a-plazo') &&
			(!capital || (isNaN(interest) || Number(interest) < 0) || !dues)
		) {
			if (!capital) emptyFields.push('Capital');
			if (isNaN(interest) || Number(interest) < 0) emptyFields.push('Interés');
			if (!periodicity) emptyFields.push('Periodicidad');
		}
		if (selectedConcept === 'prestamos-amortizable' || selectedConcept === 'ventas-a-plazo') {
			if (!dues) emptyFields.push('Cuotas');
		}
		if (!owner) {
			emptyFields.push('Cuenta');
		}
		if (emptyFields.length > 0) {
			const errorMessages = `Debe completar el/los campo/s: ${emptyFields.join(', ')}`;
			toast.error(errorMessages);
			return;
		}
		if (concept === 'ventas-a-plazo' && assetSale && assetSale.length > 0) {
			const sumDebtsFloat = parseFloat(assetSale[0].sumDebts);
			const capitalFloat = parseFloat(capital);
			const amountFloat = parseFloat(assetSale[0].amount);
			const initialPaymentFloat = parseFloat(assetSale[0].initialPayment);
			if (
				sumDebtsFloat + capitalFloat + initialPaymentFloat > amountFloat &&
				selectedConcept === 'ventas-a-plazo'
			) {
				const msg = 'Error. La sumatoria del capital inicial y las deudas registradas no puede superar el valor de la venta del inmueble.';
				toast.error(msg);
				return;
			}
		}
		if (moment.utc(firstDueExpiration).isBefore(moment.utc(date))) {
			toast.error('La fecha de vencimiento no puede ser menor a la fecha seleccionada');
			return;
		}

		try {
			dispatch({ type: 'CREATE_REQUEST' });
			if (!owner) {
				toast.error('Debe seleccionar una cuenta.');
				return;
			}
			const requestData = {
				amount,
				concept,
				date: moment.utc(date),
				owner: owner[0] || owner,
				currency,
				dueDay,
				selectedMonthOption,
				firstDueExpiration: moment.utc(firstDueExpiration),
				createWithPreviousDues,
				createWithPreviousPayments,
				createdBy: state.userInfo._id,
				periodicity: periodicity,
			};
			//check if is 'alquileres' to set contract
			if (concept == 'alquileres' && contract.length > 0) {
				requestData.contract = contract[0];
				requestData.endDate = moment.utc(endDate);
				requestData.interest = '';
				requestData.dues = '';
				requestData.capital = '';
				requestData.dues = '';
			}
			if (selectedMonthOption === 'Otro') {
				requestData.numMonths = numMonths;
			}
			if (periodicity === 'otra') {
				requestData.periodicityNumMonths = periodicityNumMonths;
			}
			//check if is 'prestamos' to set other fields
			if (selectedConcept.includes('prestamos') || selectedConcept === 'ventas-a-plazo') {
				requestData.interest = interest;
				requestData.capital = capital;
				requestData.guaranteedAmount = 0;
			}
			if (selectedConcept === 'prestamos-interés') {
				requestData.endDate = moment.utc(endDate);
			}
			if (selectedConcept === 'prestamos-amortizable') {
				requestData.dues = dues;
			}
			if (selectedConcept === 'ventas-a-plazo') {
				requestData.assetSale = assetSale[0];
				requestData.dues = dues;
			}
			//check if is 'hasPreviousDebts' to set other fields
			if (hasPreviousDebts) {
				requestData.hasPreviousDebts = hasPreviousDebts;
				requestData.previousDebt = previousDebt;
				requestData.previousDebtDate = previousDebtDate;
			}
			//check if is dont 'hasPreviousDebts' to unset other fields
			if (!hasPreviousDebts) {
				requestData.hasPreviousDebts = false;
				requestData.previousDebt = '';
				requestData.previousDebtDate = '';
			}
			await debtService.create({
				...requestData,
				createWithPreviousDues,
				createWithPreviousPayments,
			});
			dispatch({ type: 'CREATE_SUCCESS' });
			toast.success('Deuda creada');
			navigate(-1);
		} catch (ex) {
			toast.error(getError(ex));
		}
	};

	const handleSelectedConcept = (e) => {
		setSelectedConcept(e.value);
		if (
			e.value === 'prestamos-amortizable' ||
			e.value === 'prestamos-interés' ||
			e.value === 'ventas-a-plazo'
		) {
			setDisabledInput(false);
			setDisabledOwner(false);
		}
		if (e.value !== 'otro') {
			setConcept(e.value);
		}
	};

	//for calc button
	function AmountCalc() {
		dispatch({ type: 'CALC' });
		if (concept === 'prestamos-amortizable' || concept === 'ventas-a-plazo') {
			getValorDeCuotaFijaMensualAmortizable();
			setDisabledAmount(false);
			dispatch({ type: 'CALC_SUCCESS' });
		}
		if (concept === 'prestamos-interés') {
			getValorDeCuotaFijaMensualInteres();
			dispatch({ type: 'CALC_SUCCESS' });
		} else {
			dispatch({ type: 'CALC_FAIL' });
		}
	}

	function getValorDeCuotaFijaMensualAmortizable() {
		if (!capital || isNaN(parseInt(interest)) || !dues) {
			toast.error('Debe ingresar Capital, interés y cuotas');
			return;
		} else {
			if (interest == 0) {
				const valorCuota = capital / dues;
				setAmount(valorCuota);
			} else {
				const tasa = interest / 100;
				const valorCuota =
					capital * ((tasa * Math.pow(1 + tasa, dues)) / (Math.pow(1 + tasa, dues) - 1));
				setAmount(Math.ceil(valorCuota));
			}
		}
	}

	function getValorDeCuotaFijaMensualInteres() {
		if (!capital || !interest) {
			toast.error('Debe ingresar Capital e interés');
			return;
		}
		const tasa = interest / 100;
		const valorCuota = capital * tasa;
		setAmount(Math.ceil(valorCuota));
	}

	//account set only if 'alquileres'
	const handleAccountChange = (selected) => {
		if (!selected || selected.length === 0) {
			const selectedAccount = selected[0];
			const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
			setIsInvalid(!isValid);
			setOwner('');
			setCurrency('');
		} else {
			const selectedAccount = selected[0];
			const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
			setIsInvalid(!isValid);
			setOwner(selectedAccount);
			setCurrency(selectedAccount.currency);
		}
	};

	//contract handle if 'alquileres'
	const handlePropertyChange = (selected) => {
		if (!selected || selected.length === 0) {
			// Reset the state variables if nothing is selected
			const selectedProperty = selected[0];
			const isPropertyInvalid = contracts.includes(selectedProperty);
			setIsPropertyInvalid(!isPropertyInvalid);
			setContract(null);
			setOwner('');
			setAmount('');
			setCurrency('');
			setDisabledAmount(false);
			setDisabledOwner(false);
			setDisabledEndDate(false);
		} else {
			// Update the state variables with the selected property
			const selectedProperty = selected[0];
			const isPropertyInvalid = contracts.includes(selectedProperty);
			setIsPropertyInvalid(!isPropertyInvalid);
			setContract(selected);
			setOwner(selectedProperty.owner);
			setAmount(selectedProperty.amount);
			setCurrency(selectedProperty.currency);
			setEndDate(selectedProperty.periodEnd);
			setDisabledEndDate(true);
			setDisabledAmount(true);
			setDisabledOwner(true);
		}
	};
	//asset handle if 'ventas-a-plazo'
	const handleAssetSaleChange = (selected) => {
		if (!selected || selected.length === 0) {
			// Reset the state variables if nothing is selected
			const selectedAssetSale = selected[0];
			const isAssetInvalid = assetSales.includes(selectedAssetSale);
			setIsAssetInvalid(!isAssetInvalid);
			setAssetSale(null);
			setOwner('');
			setAmount('');
			setCurrency('');
			setDisabledAmount(false);
			setDisabledOwner(false);
			setDisabledEndDate(false);
		} else {
			const selectedAssetSale = selected[0];
			const isAssetInvalid = assetSales.includes(selectedAssetSale);
			setIsAssetInvalid(!isAssetInvalid);
			setAssetSale(selected);
			setOwner(selected[0].purchaser);
			setCurrency(selected[0].currency);
		}
	};

	const generateDate = () => {
		const currentDate = moment.utc(date, 'YYYY-MM-DD');
		let futureDate;

		if (selectedMonthOption === 'Mes actual') {
			futureDate = currentDate.clone();
		} else if (selectedMonthOption === 'Mes siguiente') {
			futureDate = currentDate.clone().add(1, 'month');
		} else if (selectedMonthOption === 'En tres meses' || selectedMonthOption === 'En seis meses') {
			const monthsToAdd = selectedMonthOption === 'En tres meses' ? 3 : 6;
			futureDate = currentDate.clone().add(monthsToAdd, 'month');
		} else if (selectedMonthOption === 'Otro') {
			futureDate = currentDate.clone().add(numMonths, 'month');
		}

		futureDate.date(Math.min(dueDay, futureDate.daysInMonth()));

		setFirstDueExpiration(moment.utc(futureDate).format('YYYY-MM-DD'));
		return firstDueExpiration;
	};

	return (
		<div>
			<Modal size='xl' show={true} animation={false}>
				{loading && <SpinnerOverlay/>}
				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Agregar Deuda</title>
					</Helmet>
					<h1>
						<MdLibraryAdd></MdLibraryAdd>Agregar Deuda
					</h1>
					{ !error ? 
						<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
							<div className='row align-items-center'>
								<Card className='d-flex mb-3 justify-content-center card-form-debts'>
									<Row>
										<h2 className='text-center m-3'> Datos para la generación de cuotas</h2>
									</Row>
									<Row className='d-flex mb-3 justify-content-center'>
										<OverlayTrigger
											placement='left'
											overlay={
												<Tooltip id='tooltip-previous-debt'>
													<MdWarning />
												Es la fecha de inicio de la deuda, si usted selecciona una fecha anterior al
												día de hoy se crearán las cuotas pasadas, si no es lo que desea seleccione
												una fecha igual o mayor a la de hoy.
												</Tooltip>
											}
										>
											<Form.Group className='mb-3 col-3 form-group required' controlId='Date'>
												<Form.Label>Fecha de inicio de deuda</Form.Label>
												<Form.Control
													type='date'
													onChange={(e) => setDate(e.target.value)}
												></Form.Control>
											</Form.Group>
										</OverlayTrigger>
										<Form.Group className='mb-3 col-3 form-group required' controlId='DaysDropdown'>
											<GenericDropdown
												label='Día vencimiento de 1er cuota:'
												items={dueDays}
												selected={dueDay}
												onChange={(e) => setDueDay(e.value)}
											/>
										</Form.Group>
										<Form.Group
											className='mb-3 col-2 form-group required'
											controlId='selectedMonthOption'
										>
											<GenericDropdown
												label='Generar a partir de:'
												items={monthOptions}
												selected={selectedMonthOption}
												onChange={(e) => setSelectedMonthOption(e.value)}
											/>
										</Form.Group>
										{selectedMonthOption === 'Otro' && (
											<Form.Group className='mb-3 col-3 form-group required' controlId='numMonths'>
												<Form.Label>Ingrese en cuantos meses:</Form.Label>
												<Form.Control
													type='number'
													min='1'
													max ='240'
													value={numMonths}
													onChange={(e) => setNumMonths(parseInt(e.target.value, 10))}
													onInput={(e) => {
														const inputValue = parseInt(e.target.value, 10);
														const maxValue = 240;
														if (maxValue !== null && inputValue > maxValue) {
															e.target.value = maxValue;
														}
													}}
												/>
											</Form.Group>
										)}
									</Row>

									<Row>
										<div className='d-flex d-inline justify-content-center align-items-center'>
											<p>
												{date
													? `Primer Vencimiento: ${moment.utc(firstDueExpiration).format('DD-MM-YYYY')}  `
													: ''}
											</p>
										</div>
									</Row>
								</Card>
								{createWithPreviousDues ? (
									<Card className='d-flex align-items-center justify-content-center mb-3 card-form-debts'>
										<Row>
											<h2 className='text-center m-3'>
												<MdWarning />
											Deuda con cuotas previas
											</h2>
										</Row>
										<Row>
											<p>
											Usted está por crear una deuda con fecha anterior a la fecha de hoy, si no
											desea crear cuotas previas debe ingresar una fecha igual o posterior al día de
											hoy
											</p>
										</Row>
										<Form.Group
											as={Row}
											className='mb-3 align-items-center col-8'
											controlId='createWithPreviousPayments'
										>
											<Form.Check
												type='checkbox'
												className='mb-3 align-items-center col-5 justify-content-center text-center'
												label='Marcar cuotas previas como pagas'
												checked={createWithPreviousPayments}
												onChange={() => setCreateWithPreviousPayments(!createWithPreviousPayments)}
											/>
										</Form.Group>
									</Card>
								) : (
									''
								)}
								<Card className='d-flex justify-content-center mb-3 card-form-debts'>
									<Row>
										<h2 className='text-center m-3'>Información contable</h2>
									</Row>
									<Row>
										<Form.Group className='mb-3 col-6 form-group required'>
											<GenericDropdown
												label='Concepto'
												items={concepts}
												selected={selectedConcept}
												onChange={handleSelectedConcept}
											/>
										</Form.Group>
										{selectedConcept === 'alquileres' && (
											<Form.Group className='mb-3 col-6 form-group required'>
												<Form.Label>Propiedad:</Form.Label>
												<CustomTypeahead
													id='contracts'
													labelKey={(option) => `${option.customId}`}
													onChange={handlePropertyChange}
													options={filteredContracts ? filteredContracts : contracts}
													selected={contract ? contract : null}
													placeholder='Buscar un alquiler...'
													linkToCreate='/AdminScreen/newContract'
													newOption='nuevo alquiler'
												/>
												{isPropertyInvalid && <div style={{ color: 'red' }}>Alquiler invalido</div>}
											</Form.Group>
										)}
										{selectedConcept === 'ventas-a-plazo' && (
											<Form.Group className='mb-3 col-6 form-group required'>
												<Form.Label>Venta:</Form.Label>
												<CustomTypeahead
													id='assetSaleService'
													labelKey={(option) => `${option.customId}`}
													onChange={handleAssetSaleChange}
													options={assetSales}
													selected={assetSale ? assetSale : null}
													placeholder='Buscar una venta de activo fijo...'
													linkToCreate='/AdminScreen/newAssetSale'
													newOption='nueva venta de activo fijo'
												/>
												{isAssetInvalid && <div style={{ color: 'red' }}>Alquiler invalido</div>}
											</Form.Group>
										)}
									</Row>
									<Row>
										<Form.Group className='mb-3 col-6 form-group required'>
											<Form.Label>Deudor</Form.Label>
											<CustomTypeahead
												id='accounts'
												labelKey={(option) => `${option.name}`}
												onChange={handleAccountChange}
												options={accounts}
												disabled={disabledOwner}
												selected={owner ? [owner] : null}
												placeholder='Buscar Cuenta... '
												isInvalid={isInvalid}
												linkToCreate='/AdminScreen/newAccount'
												newOption='nueva cuenta'
											/>
											{isInvalid && <div style={{ color: 'red' }}>Cuenta invalida</div>}
										</Form.Group>
										<Form.Group as={Col} className='mb-3 col-6' controlId='currency'>
											<GenericDropdown
												label='Moneda'
												items={currencies}
												selected={currency}
												onChange={(e) => setCurrency(e.value)}
											/>
										</Form.Group>
										<Form.Group className='mb-3 col-md-4 form-group required'>
											<GenericDropdown
												label='Periodicidad'
												items={periodicities}
												selected={periodicity}
												onChange={(e) => setPeriodicity(e.value)}
											/>
										</Form.Group>
										{periodicity === 'otra' && (
											<Form.Group className='mb-3 col-md-3' controlId='periodicityNumMonths'>
												<Form.Label>Periodicidad en meses</Form.Label>
												<FormatedNumberInput
													type='number'
													onChange={(e) => setPeriodicityNumMonths(e.target.value)}
													min={1}
													noDecimal={true}
												></FormatedNumberInput>
											</Form.Group>
										)}
										{selectedConcept &&
										(selectedConcept.includes('prestamos') ||
											selectedConcept.includes('ventas')) && (
											<>
												<Form.Group
													className='mb-3 col-md-3 form-group required'
													controlId='capital'
												>
													<Form.Label>Capital</Form.Label>
													<FormatedNumberInput
														type='number'
														onChange={(e) => setCapital(e.target.value)}
														min={0}
													></FormatedNumberInput>
												</Form.Group>
												<Form.Group
													className='mb-3 col-md-2 form-group required'
													controlId='interest'
												>
													<Form.Label>Interes %</Form.Label>
													<FormatedNumberInput
														type='number'
														value={interest}
														onChange={(e) => setInterest(e.target.value)}
														min={0}
													></FormatedNumberInput>
												</Form.Group>
												{selectedConcept !== 'prestamos-amortizable' &&
													!selectedConcept.includes('ventas') && (
													<Form.Group className='mb-3 col-md-3 form-group' controlId='endDate'>
														<Form.Label>Fecha de fin</Form.Label>
														<Form.Control
															type='date'
															disabled={disabledEndDate}
															value={endDate ? moment(endDate).format('YYYY-MM-DD') : null}
															onChange={(e) => setEndDate(e.target.value)}
														></Form.Control>
													</Form.Group>
												)}

												{(selectedConcept === 'prestamos-amortizable' ||
													selectedConcept.includes('ventas')) && (
													<Form.Group className='mb-3 col-md-3' controlId='dues'>
														<Form.Label>Cuotas</Form.Label>
														<FormatedNumberInput
															type='number'
															onChange={(e) => setDues(e.target.value)}
															min={1}
															noDecimal={true}
														></FormatedNumberInput>
													</Form.Group>
												)}
												<Card className='d-flex flex-row mb-3 align-items-center justify-content-evenly card-form-debts'>
													<Button
														className='mt-3 col-md-2'
														onClick={AmountCalc}
														disabled={disabledInput}
													>
														Calcular
													</Button>
													<Form.Group
														className='mb-3 col-md-2 form-group required'
														controlId='amount'
													>
														<Form.Label>
															Monto{' '}
															{selectedConcept &&
																(selectedConcept.includes('prestamos') ||
																	selectedConcept.includes('ventas-a-plazo'))
																? 'cuota'
																: ''}
														</Form.Label>
														<FormatedNumberInput
															type='number'
															value={amount}
															disabled={disabledAmount}
															onChange={(e) => setAmount(e.target.value)}
															min={0}
														></FormatedNumberInput>
													</Form.Group>
												</Card>
											</>
										)}
									</Row>
								</Card>
								<Card className='d-flex  justify-content-between mb-3 card-form-debts'>
									<Row>
										<h2 className='text-center m-3'>Registro fuera del sistema</h2>
									</Row>
									<Row className='d-flex flex-row align-items-center'>
										<OverlayTrigger
											placement='left'
											overlay={
												<Tooltip id='tooltip-previous-debt'>
												Los datos que se completan aqui son únicamente informativos, con el fin de
												registrar la contabilidad fuera del sistema
												</Tooltip>
											}
										>
											<Form.Group
												className=' col-3 form-group d-flex align-items-center'
												controlId='hasPreviousDebts'
												style={{ height: '60px' }}
											>
												<Form.Check
													className='d-flex align-items-center'
													type='checkbox'
													label='Tiene deuda anterior?'
													checked={hasPreviousDebts}
													onChange={(e) => setHasPreviousDebts(e.target.checked)}
												/>
											</Form.Group>
										</OverlayTrigger>
										<Form.Group className='mb-3 col-4 form-group ' controlId='previousDebt'>
											<Form.Label>Deuda Anterior</Form.Label>
											<FormatedNumberInput
												type='number'
												onChange={(e) => setPreviousDebt(e.target.value)}
												disabled={!hasPreviousDebts}
												min={0}
											></FormatedNumberInput>
										</Form.Group>
										<Form.Group className='mb-3 col-4 form-group ' controlId='previousDebtDate'>
											<Form.Label>Fecha deuda anterior</Form.Label>
											<Form.Control
												type='date'
												disabled={!hasPreviousDebts}
												onChange={(e) => setPreviousDebtDate(e.target.value)}
											></Form.Control>
										</Form.Group>
									</Row>
								</Card>
							</div>
							<Row className='modal-btns'>
								<Col>
									<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
									</Button>
								</Col>
								<Col>
									<Button
										className='guardar'
										disabled={!isAddDebtButtonEnabled()}
										onClick={() => addDebtHandler()}
									>
									Agregar
									</Button>
								</Col>
							</Row>
						</Form> : 
						<MessageBox variant = "danger">{error}</MessageBox>}
				</div>
			</Modal>
		</div>
	);
}
export default AddDebt;
