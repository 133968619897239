import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import { MdLibraryAdd } from 'react-icons/md';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import { getError } from '../../utils';

import { useNavigate, useParams } from 'react-router-dom';
import debtService from '../../services/debt.service';
import interestService from '../../services/interest.service';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return { ...state, debts: action.payload, loadingFetch: false };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function NewInterestNew() {
	const navigate = useNavigate();

	const [percentage, setPercentage] = useState();
	const [type, setType] = useState();
	const params = useParams();
	const { id } = params;
	const [debt, setDebt] = useState(id);
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();

	const [{ loadingFetch, loadingCreate, debts }, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		debts: [],
		loadingCreate: false,
		error: '',
	});

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await debtService.list();
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, []);

	const addPaymentHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await interestService.create({
				percentage,
				type,
				debt,
				folder,
				observations,
			});
			toast.success('Interes creado');
			navigate(-1);
		} catch (ex) {
			toast.error(getError(ex));
		}
	};
	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{loadingCreate && loadingFetch && <LoadingBox></LoadingBox>}
				{/* <Modal size="lg" show={showModal} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Guardar Cambios</Modal.Title>
        </Modal.Header>
        <Modal.Body>Seguro desea guardar los cambios?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={addPaymentHandler}>Confirmar</Button>
        </Modal.Footer>
      </Modal> */}
				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Agregar Interes</title>
					</Helmet>
					<h1>
						<MdLibraryAdd></MdLibraryAdd>Agregar Interes
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Row>
								<Form.Group className='mb-3 col-6' controlId='amount'>
									<Form.Label>Porcentaje</Form.Label>
									<Form.Control
										type='number'
										onChange={(e) => setPercentage(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3 col-6' controlId='type'>
									<Form.Label>tipo</Form.Label>
									<Form.Control
										type='text'
										onChange={(e) => setType(e.target.value)}
									></Form.Control>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group className='mb-3 col-6'>
									<Form.Label>Deuda Relacionada</Form.Label>
									<Form.Select disabled value={id} onChange={(e) => setDebt(e.target.value)}>
										<option key={0}>Seleccione una deuda</option>
										{debts.map((debt) => {
											return (
												<option key={debt._id} value={debt._id}>
													{debt.owner ? debt.owner.name + ': ' + debt.internalId : debt.internalId}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>
								<Form.Group as={Col} className='mb-3 col-6' controlId='folder'>
									<Form.Label>Carpeta</Form.Label>
									<Form.Control
										value={folder}
										onChange={(e) => setFolder(e.target.value)}
									></Form.Control>
								</Form.Group>
							</Row>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Observaciones</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									value={observations}
									rows={10}
									onChange={(e) => setObservations(e.target.value)}
								></Form.Control>
							</Form.Group>
						</div>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={addPaymentHandler}>
									Agregar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default NewInterestNew;
