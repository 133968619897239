import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdAttachMoney } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import AssetService from '../../services/asset.service';
import BillService from '../../services/bill.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_ASSETS_REQUEST':
		return { ...state, loadingContacts: true };
	case 'FETCH_ASSETS_SUCCESS':
		return { ...state, assets: action.payload, loadingContacts: false };
	case 'FETCH_ASSETS_FAIL':
		return { ...state, loadingContacts: false, error: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, payment: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function BillDetail() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, error, assets }, dispatch] = useReducer(reducer, {
		assets: [],
		loading: true,
		error: '',
	});
	const [name, setName] = useState();
	const [amount, setAmount] = useState();
	const [asset, setAsset] = useState('');
	const [type, setType] = useState('');
	const types = ['Periodico', 'Eventual'];
	const [periodicity, setPeriodicity] = useState('');
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();
	const [customId, setCustomId] = useState();
	const [period, setPeriod] = useState();
	const periods = ['Mensual', 'Anual'];
	const [formatedDate, setFormatedDate] = useState();

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await BillService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				setName(result.data.name);
				setAmount(result.data.amount);
				setAsset(result.data.asset);
				setType(result.data.type);
				setPeriodicity(result.data.periodicity);
				setPeriod(Date.parse(result.data.period));
				setFolder(result.data.folder);
				setObservations(result.data.observations);
				setFormatedDate(result.data.expirationDate.substring(0, 10));
				setCustomId(result.data.customId);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				console.error(error);
				toast.error('No se pudo obtener lista');
			}
			// setdebts(result.data);
		};
		const fetchAssets = async () => {
			dispatch({ type: 'FETCH_ASSETS_REQUEST' });
			try {
				const result = await AssetService.list();
				dispatch({ type: 'FETCH_ASSETS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
				console.error(error);
				toast.error('No se pudo obtener lista');
			}
		};
		fetchAssets();
		fetchData();
	}, [id]);

	async function editPaymentHandler() {
		navigate(`/AdminScreen/bills/editBill/${id}`);
	}
	return (
		<div>
			{(loading) ? (
				<LoadingBox></LoadingBox>
			) : (
				error ? (
					<MessageBox variant='danger'>{error}</MessageBox>
				) : null
			)}			<div className='container admin-con align-items-center'>
				<Row style={{ margin: '0 0 2.5% 0' }}>
					<Col style={{ alignSelf: 'center' }}>
						<h2>
							<MdAttachMoney></MdAttachMoney>Gasto:{customId}
						</h2>
					</Col>
					<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							style={{ backgroundColor: 'transparent!important' }}
							id='back-btn'
							className='btn m-2'
							value='back'
							onClick={() => navigate(-1)}
						>
							Atras
						</Button>
						<Button
							className='btn btn-dark m-2'
							value='Crear Cuenta'
							onClick={() => editPaymentHandler()}
						>
							Editar Gasto
						</Button>
					</Col>
				</Row>
				<div className='jumbotron'></div>
				<div className='container admin-con align-items-center'>
					<Form onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Row className='align-items-center'>
								<Form.Group className='mb-3 col-6' controlId='name'>
									<Form.Label>Nombre</Form.Label>
									<Form.Control
										type='text'
										defaultValue={name}
										disabled
										onChange={(e) => setName(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3 col-3' controlId='amount'>
									<Form.Label>Monto</Form.Label>
									<Form.Control
										type='number'
										defaultValue={amount}
										disabled
										onChange={(e) => setAmount(e.target.value)}
									></Form.Control>
								</Form.Group>
							</Row>
							<Row className='align-items-center'>
								<Form.Group className='mb-3 col-3' controlId='date'>
									<Form.Label>Vencimiento</Form.Label>
									<Form.Control
										type='date'
										defaultValue={formatedDate}
										disabled
									></Form.Control>
								</Form.Group>
								{asset ? (
									<Form.Group className='mb-3 col-6'>
										<Form.Label>Activo vinculado</Form.Label>
										<Form.Select value={asset._id} disabled>
											{assets.map((asset) => {
												return (
													<option key={asset._id} value={asset._id}>
														{asset.owner ? asset.customId : asset.customId}
													</option>
												);
											})}
											<option key='blankChoice' hidden value>
												{' '}
												Elige el Activo Relacionado{' '}
											</option>
										</Form.Select>
									</Form.Group>
								) : (
									<Form.Group className='mb-3 col-6'>
										<Form.Label>Activo vinculado</Form.Label>
										<Form.Select value={asset} disabled>
											{assets.map((asset) => {
												return (
													<option key={asset._id} value={asset._id}>
														{asset.owner ? asset.customId : asset.customId}
													</option>
												);
											})}
											<option key='blankChoice' hidden value>
												{' '}
												No tiene Activo Relacionado{' '}
											</option>
										</Form.Select>
									</Form.Group>
								)}
							</Row>
							<Form.Group className='mb-3 col-3' id='type'>
								<Form.Label>Tipo</Form.Label>
								<Form.Select
									as='select'
									value={type}
									disabled
									onChange={(e) => setType(e.target.value)}
								>
									<option key={0}>Selecione el tipo de gasto</option>
									{types.map((type, index) => {
										return (
											<option key={index} value={type}>
												{type}
											</option>
										);
									})}
								</Form.Select>
							</Form.Group>
							{type === 'Periodico' && (
								<Form.Group className='mb-3 col-3' id='other'>
									<Form.Label>Periodicidad:</Form.Label>
									<Form.Select
										as='select'
										value={periodicity}
										onChange={(e) => setPeriodicity(e.target.value)}
										disabled
									>
										<option key={0}>Selecione la periodicidad</option>
										{periods.map((period, index) => {
											return (
												<option key={index} value={period}>
													{period}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>
							)}
							{type === 'Periodico' && periodicity == 'Anual' && (
								<Form.Group className='mb-3 col-3'>
									<Form.Label>Año:</Form.Label>
									<DatePicker selected={period} dateFormat='yyyy' showYearPicker disabled />
								</Form.Group>
							)}

							{type === 'Periodico' && periodicity == 'Mensual' && (
								<Form.Group className='mb-3 col-3'>
									<Form.Label>Mes/año:</Form.Label>
									<DatePicker selected={period} showMonthYearPicker dateFormat='MM/yyyy' disabled />
								</Form.Group>
							)}
							<Form.Group as={Col} className='mb-3 col-9' controlId='folder'>
								<Form.Label>Carpeta</Form.Label>
								<Form.Control
									value={folder}
									disabled
									onChange={(e) => setFolder(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Observaciones</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									disabled
									value={observations}
									rows={10}
									onChange={(e) => setObservations(e.target.value)}
								></Form.Control>
							</Form.Group>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
}
export default BillDetail;
