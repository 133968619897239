import React from 'react';
import ClockLoader from 'react-spinners/ClockLoader';
import style from './SpinnerOverlay.module.css'; // import the CSS file

function SpinnerOverlay(loading) {
	return <div className={style.spinner}>
		<ClockLoader color='#764176' loading={loading} />
	</div>;
	
}

export default SpinnerOverlay;
