import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import './SearchBox.css';
import { MdSearch } from 'react-icons/md';

function SearchBox({ getFilterUrl, navigate }) {
	const [query, setQuery] = useState();

	const [isActiveSearch, setActive] = useState(true);

	const submitHandler = (e) => {
		e.preventDefault();
		const filterUrl = getFilterUrl({ query });
		setActive(!isActiveSearch);
		navigate(filterUrl);
	};

	return (
		<Form className='input-group justify-content-lg-end justify-content-md-start'>
			<div className='form-outline'>
				<input
					placeholder='buscar'
					className=' form-control search-input input-sm'
					type='text'
					name='q'
					id='q'
					defaultValue={query}
					autoComplete='off'
					onChange={(e) => setQuery(e.target.value.replace(/[-\\^$*+?.()|[\]{}]/g, ''))}
				></input>
			</div>
			<Button type='submit' className='btn-primary-search btn-sm' onClick={submitHandler}>
				<MdSearch />
			</Button>
		</Form>
	);
}

export default SearchBox;
