import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { MdOutlineEdit } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import CustomTypeahead from '../../components/CustomTypeahead';
import FormatedNumberInput from '../../components/FormatedNumberInput';
import LoadingBox from '../../components/LoadingBox';
import GenericDropdown from '../../components/SelectDropdown';
import AccountService from '../../services/account.service';
import AssetService from '../../services/asset.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_ACCOUNTS_SUCCESS':
		return { ...state, accounts: action.payload };
	case 'FETCH_ASSET_SUCCESS':
		return { ...state, asset: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'UPDATE_REQUEST':
		return { ...state, loadingUpdate: true };
	case 'UPDATE_SUCCESS':
		return { ...state, loadingUpdate: false };
	case 'UPDATE_FAIL':
		return { ...state, loadingUpdate: false, error: action.payload };
	default:
		return state;
	}
};

function EditAsset() {
	const params = useParams();
	const navigate = useNavigate();
	const { id } = params;
	const { dispatch: ctxDispatch } = useContext(Store);


	const [{ loading, error, accounts, loadingUpdate }, dispatch] = useReducer(reducer, {
		accounts: [],
		loadingAccounts: true,
		loading: true,
		loadingUpdate: false,
		error: '',
	});

	const [name, setName] = useState();
	const [price, setPrice] = useState();
	const [type, setType] = useState();
	const [ubication, setUbication] = useState();
	const [description, setDescription] = useState();
	const [owner, setOwner] = useState();
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();
	const [escritura, setEscritura] = useState();
	const [uploading, setUploading] = useState(false);
	const currencies = ['USD', 'UYU'];
	const [currency, setCurrency] = useState();
	const [multipleLots, setMultipleLots] = useState();
	const [lot, setLot] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				await Promise.all([fetchAsset(), fetchAccounts()]);
				dispatch({ type: 'FETCH_SUCCESS' });
			} catch (ex) {
				console.error(ex);
				dispatch({ type: 'FETCH_FAIL', payload: getError(ex) });
				toast.error(getError(ex));
			}
		};
		const fetchAsset = async () => {
			const result = await AssetService.getDetails(id);
			dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			setName(result.data.name);
			setPrice(result.data.price);
			setType(result.data.type);
			setUbication(result.data.ubication);
			setDescription(result.data.description);
			setOwner([result.data.owner]);
			setFolder(result.data.folder);
			setObservations(result.data.observations);
			setEscritura(result.data.escritura);
			setCurrency(result.data.currency);
			setMultipleLots(result.data.multipleLots);
			setLot(result.data.lot);
		};

		const fetchAccounts = async () => {
			const result = await AccountService.list();
			dispatch({ type: 'FETCH_ACCOUNTS_SUCCESS', payload: result.data });
		};
		fetchData();
	}, [id]);

	const editDebtHandler = async () => {
		if (!owner) {
			toast.error('Debe seleccionar un propietario.');
			return;
		}
		if (!multipleLots && !lot) {
			toast.error('Debe completar el campo padrón.');
			return;
		}
		try {
			dispatch({ type: 'UPDATE_REQUEST' });
			const { data } = await AssetService.edit(id, {
				name,
				price,
				type,
				ubication,
				description,
				owner: owner[0],
				folder,
				observations,
				escritura,
				currency,
				multipleLots,
				...(multipleLots ? { multipleLots } : { multipleLots, lot }),
			});
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'CONTACT_UPDATE_SUCCESS', payload: data });
			toast.success('Activo actualizado Correctamente');
			navigate(-1);
		} catch (err) {
			dispatch({ type: 'UPDATE_FAIL' });
			console.error(getError(error));
			toast.error('Error al editar Activo');
		}
	};
	const uploadEscritura = async () => {
		setUploading(true);
		const file = escritura[0];
		const formData = new FormData();
		let r = Math.random().toString(36).substring(7);
		formData.append('file', file, file.name.replaceAll(' ') + r);
		if (escritura) {
			formData.append('documentId', escritura._id);
		}
		try {
			const response = await AssetService.uploadEscritura(formData);
			setEscritura(response.data);
			setUploading(false);
			toast.success('Archivo Subido');
		} catch (ex) {
			setUploading(false);
			console.error(getError(error));
			toast.error('Error al eliminar subir archivo');
		}
	};

	const [isInvalid, setIsInvalid] = useState(false);

	const handleAccountChange = (selectedOwner) => {
		if (!selectedOwner || selectedOwner.length === 0) {
			const selectedAccount = selectedOwner[0];
			const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
			setIsInvalid(!isValid);
			setOwner('');
			setCurrency('');
		} else {
			const selectedAccount = selectedOwner[0];
			const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
			setIsInvalid(!isValid);
			setOwner(selectedOwner);
			setCurrency(selectedAccount.currency);
		}
	};

	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{(loading || uploading || loadingUpdate) && <LoadingBox></LoadingBox>}

				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Editar Activo</title>
					</Helmet>
					<h1>
						<MdOutlineEdit></MdOutlineEdit>Editar Activo
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Row>
								<Form.Group className='mb-3 col-6 form-group required' controlId='Name'>
									<Form.Label>Nombre</Form.Label>
									<Form.Control
										type='text'
										defaultValue={name}
										onChange={(e) => setName(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3 col-6' controlId='price'>
									<Form.Label>Precio/Valor</Form.Label>
									<FormatedNumberInput
										type='number'
										onChange={(e) => setPrice(e.target.value)}
										defaultValue={price}
										min={0}
									></FormatedNumberInput>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group className='mb-3 col-6' controlId='type'>
									<Form.Label>Tipo</Form.Label>
									<Form.Control
										type='text'
										defaultValue={type}
										onChange={(e) => setType(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3 col-6' controlId='expirationDate'>
									<Form.Label>Ubicacion</Form.Label>
									<Form.Control
										type='text'
										defaultValue={ubication}
										onChange={(e) => setUbication(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Card>
									<Row>
										<Form.Group
											className=' col-6 form-group d-flex align-items-center'
											controlId='multipleLots'
											style={{ height: '60px' }}
										>
											<Form.Check
												type='checkbox'
												label='El activo tiene multiples padrones?'
												defaultChecked={multipleLots}
												onChange={(e) => setMultipleLots(e.target.checked)}
											/>
										</Form.Group>
										<Form.Group className='mb-3 col-6' controlId='periodicity'>
											<Form.Label>Padrón</Form.Label>
											<Form.Control
												type='text'
												onChange={(e) => setLot(e.target.value)}
												defaultValue={lot}
												disabled={multipleLots}
											></Form.Control>
										</Form.Group>
									</Row>
								</Card>
							</Row>
							<Row>
								<Form.Group className='mb-3 col-6' controlId='periodicity'>
									<Form.Label>Descripcion</Form.Label>
									<Form.Control
										type='text'
										defaultValue={description}
										onChange={(e) => setDescription(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3 col-6 form-group required'>
									<Form.Label>Propietario</Form.Label>
									{owner && owner != null ? (
										<>
											<CustomTypeahead
												id='accounts'
												labelKey={(option) => `${option.name}`}
												onChange={handleAccountChange}
												options={accounts}
												selected={owner}
												placeholder='Buscar Cuenta de propietario... '
												isInvalid={isInvalid}
												linkToCreate='/AdminScreen/newAccount'
												newOption='nueva cuenta'
											/>
											{isInvalid && (
												<div style={{ color: 'red' }}>Cuenta no seleccionada o invalida</div>
											)}
										</>
									) : (
										<>
											<CustomTypeahead
												id='accounts'
												labelKey={(option) => `${option.name}`}
												onChange={handleAccountChange}
												options={accounts}
												placeholder='Buscar Cuenta de propietario... '
												isInvalid={isInvalid}
												linkToCreate='/AdminScreen/newAccount'
												newOption='nueva cuenta'
											/>
											{isInvalid && (
												<div style={{ color: 'red' }}>Cuenta no seleccionada o invalida </div>
											)}
										</>
									)}
								</Form.Group>
							</Row>
						</div>
						<Form.Group as={Col} className='mb-3 col-3' controlId='currency'>
							<GenericDropdown
								label='Moneda'
								items={currencies}
								selected={currency}
								onChange={(e) => setCurrency(e.value)}
							/>
						</Form.Group>
						<Row>
							<Form.Group as={Col} className='mb-3 col-6' controlId='folder'>
								<Form.Label>Carpeta</Form.Label>
								<Form.Control
									value={folder}
									onChange={(e) => setFolder(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group className='col-4' controlid='escritura' value={escritura}>
								<Form.Label>Escritura</Form.Label>
								<Form.Control
									type='file'
									required
									onChange={(e) => setEscritura(e.target.files)}
								></Form.Control>
								<Form.Control.Feedback type='invalid'>Seleccione Archivo</Form.Control.Feedback>
							</Form.Group>
							<Button
								className='col-2'
								style={{ height: '30px', marginTop: '32px', padding: '0' }}
								onClick={() => uploadEscritura(escritura)}
							>
								subir
							</Button>
						</Row>
						<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
							<Form.Label>Observaciones</Form.Label>
							<Form.Control
								type='text'
								as='textarea'
								value={observations}
								rows={10}
								onChange={(e) => setObservations(e.target.value)}
							></Form.Control>
						</Form.Group>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={editDebtHandler}>
									Guardar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default EditAsset;
