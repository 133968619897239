import React from 'react';
import Axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getError, getUserInfo } from '../utils';
import { BsFillEyeFill } from 'react-icons/bs';
import { BsFillEyeSlashFill } from 'react-icons/bs';
import InputGroup from 'react-bootstrap/InputGroup';
import { Col, Button, Row, Container, Card, Form } from 'react-bootstrap';

export default function SignupScreen() {
	const navigate = useNavigate();
	const { search } = useLocation();
	const redirectInUrl = new URLSearchParams(search).get('redirect');
	const redirect = redirectInUrl ? redirectInUrl : '/';

	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [passwordType, setPasswordType] = useState('password');
	const [repeatPasswordType, setRepeatPasswordType] = useState('password');

	const togglePassword = () => {
		if (passwordType === 'password') {
			setPasswordType('text');
			return;
		}
		setPasswordType('password');
	};
	const toggleRepeatPassword = () => {
		if (repeatPasswordType === 'password') {
			setRepeatPasswordType('text');
			return;
		}
		setRepeatPasswordType('password');
	};

	const userInfo = getUserInfo();
	const submitHandler = async () => {
		if (password !== confirmPassword) {
			toast.error('Las contraseñas no coinciden');
			return;
		}
		try {
			const { data } = await Axios.post('/api/auth/signup', {
				name,
				email,
				password,
			});
			toast.success(data.message);
			navigate(redirect || '/');
		} catch (err) {
			toast.error(getError(err));
		}
	};

	useEffect(() => {
		if (userInfo) {
			navigate(redirect);
		}
	}, [navigate, redirect, userInfo]);

	return (
		<Container className='small-container'>
			<Helmet>
				<title>Registro</title>
			</Helmet>
			<Container fluid>
				<Row className='vh-100 d-flex justify-content-center align-items-center'>
					<Col md={8} lg={6} xs={12}>
						<div className='border border-3 border-primary'></div>
						<Card className='shadow'>
							<Card.Body>
								<div className='mb-3 mt-md-4'>
									<h2 className='fw-bold mb-4 text-uppercase text-align-center '>Registro</h2>
									<div className='mb-3'>
										<Form onSubmit={(e) => e.preventDefault()}>
											<Form.Group className='mb-3' controlId='email'>
												<Form.Label>Nombre:</Form.Label>
												<Form.Control
													type='text'
													required
													onChange={(e) => setName(e.target.value)}
												></Form.Control>
											</Form.Group>
											<Form.Group className='mb-3' controlId='email'>
												<Form.Label>E-mail</Form.Label>
												<Form.Control
													type='email'
													required
													onChange={(e) => setEmail(e.target.value.toLowerCase())}
												></Form.Control>
											</Form.Group>
											<Form.Label>Contraseña</Form.Label>
											<InputGroup className='mb-3' style={{ padding: 0 }} controlId='password'>
												<Form.Control
													type={passwordType}
													required
													onChange={(e) => setPassword(e.target.value)}
												/>
												<Button
													variant='btn btn--secondary'
													className='eyeButton'
													id='button-addon2'
													onClick={togglePassword}
												>
													{passwordType === 'password' ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
												</Button>
											</InputGroup>
											<Form.Label>Repetir Contraseña</Form.Label>
											<InputGroup className='mb-3' style={{ padding: 0 }} controlId='password'>
												<Form.Control
													type={repeatPasswordType}
													required
													onChange={(e) => setConfirmPassword(e.target.value)}
												/>
												<Button
													variant='btn btn--secondary'
													className='eyeButton'
													id='button-addon2'
													onClick={toggleRepeatPassword}
												>
													{repeatPasswordType === 'password' ? (
														<BsFillEyeSlashFill />
													) : (
														<BsFillEyeFill />
													)}
												</Button>
											</InputGroup>
											<div className='mb-3'>
												<Button type='submit' onClick={submitHandler}>
													Registrarse
												</Button>
											</div>
											<div className='mb-3'>
												Ya tienes una cuenta?{' '}
												<Link to={`/signin?redirect=${redirect}`} className='text-primary fw-bold'>
													Inicia sesion
												</Link>
											</div>
										</Form>
									</div>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</Container>
	);
}
