import React, { useRef } from 'react';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { MdSearch, MdClose } from 'react-icons/md';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CustomTypeahead = ({
	id,
	labelKey,
	onChange,
	options,
	placeholder,
	emptyLabel,
	linkToCreate,
	newOption,
	selected,
	disabled,
}) => {
	const inputRef = useRef(null);

	const handleIconClick = () => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};

	const renderEmptyLabel = () => (
		<Link to={linkToCreate} className='dropdown-item' tabIndex={-1}>
			...Crear {newOption}
		</Link>
	);

	//customized render menu

	const renderMenu = (results, menuProps) => {
		if (results.length === 0) {
			//no results
			return (
				<Menu {...menuProps}>
					<MenuItem disabled>{emptyLabel}</MenuItem>
					{renderEmptyLabel()}
				</Menu>
			);
		}

		return (
			<Menu {...menuProps}>
				{results.map(
					(
						option,
						index, //all the results+new option
					) => (
						<MenuItem key={index} option={option} position={index}>
							{labelKey(option)}
						</MenuItem>
					),
				)}
				{linkToCreate && linkToCreate != '' && renderEmptyLabel()}
			</Menu>
		);
	};
	return (
		<div style={{ position: 'relative' }}>
			<Typeahead
				id={id}
				labelKey={labelKey}
				onChange={onChange}
				options={options}
				placeholder={placeholder}
				selected={selected}
				disabled={disabled}
				emptyLabel={renderEmptyLabel()}
				renderInput={({ inputRef, ...props }, state) => (
					<div className='d-flex'>
						<Form.Control
							{...props}
							style={{ flex: 1, borderRadius: '0.25rem 0 0 0.25rem' }}
							isInvalid={props.isInvalid}
							inputRef={inputRef}
						/>
						{state.selected.length === 0 && (
							<span
								className='input-group-text d-flex justify-content-center align-items-center typeahedSearchIcon'
								onClick={handleIconClick}
							>
								<MdSearch />
							</span>
						)}
						{state.selected.length > 0 && !disabled && (
							<span
								className='input-group-text d-flex justify-content-center align-items-center typeahedSearchIcon'
								onClick={() => {
									state.onClear();
									onChange([]); // clear the selected state
								}}
							>
								<MdClose />
							</span>
						)}
					</div>
				)}
				renderMenu={renderMenu}
			/>
		</div>
	);
};

export default CustomTypeahead;
