import React, { useEffect, useReducer, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import { MdLibraryAdd } from 'react-icons/md';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomTypeahead from '../../components/CustomTypeahead';
import FormatedNumberInput from '../../components/FormatedNumberInput';
import LoadingBox from '../../components/LoadingBox';
import GenericDropdown from '../../components/SelectDropdown';
import AccountService from '../../services/account.service';
import AssetService from '../../services/asset.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return { ...state, accounts: action.payload, loadingFetch: false };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function AddAsset() {
	const navigate = useNavigate();

	const [name, setName] = useState();
	const [price, setPrice] = useState();
	const [type, setType] = useState();
	const [ubication, setUbication] = useState();
	const [description, setDescription] = useState();
	const [owner, setOwner] = useState();
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();
	const [escritura, setEscritura] = useState('');
	const [uploading, setUploading] = useState(false);
	const currencies = ['USD', 'UYU'];
	const [currency, setCurrency] = useState();
	const [multipleLots, setMultipleLots] = useState(false);
	const [lot, setLot] = useState();
	const [disabledOwner, setDisabledOwner] = useState(false);

	// const [image, setImage] = useState("");
	// const [imageUrl, setImageUrl] = useState("");
	// const [description, setDescription] = useState("");
	// const [showModal, setShowModal] = useState(false);
	// const handleClose = () => setShowModal(false);
	// const handleShow = () => setShowModal(true);

	const [{ loadingFetch, loadingCreate, accounts }, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		accounts: [],
		loadingCreate: false,
		error: '',
	});

	const params = useParams();
	const { id } = params;
	const location = useLocation();
	const route = location.pathname;

	const uploadEscritura = async () => {
		setUploading(true);
		if (!escritura) {
			toast.warning('Debe seleccionar un archivo');
			setUploading(false);
			return;
		}
		const file = escritura[0];
		const formData = new FormData();
		let r = Math.random().toString(36).substring(7);
		formData.append('file', file, file.name.replaceAll(' ') + r);
		if (escritura) {
			formData.append('documentId', escritura._id);
		}
		try {
			const response = await AssetService.uploadEscritura(formData);
			setEscritura(response.data);
			setUploading(false);
			toast.success('Archivo Subido');
		} catch (ex) {
			setUploading(false);
			toast.error(getError(ex));
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await AccountService.list();
				if (route.includes('account')) {
					const selectedAccount = result.data.filter((accountData) => accountData._id === id);
					setOwner(selectedAccount[0]);
					setDisabledOwner(true);
					setFolder(selectedAccount[0].folder);
				}
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
				console.error(error);
				toast.error('No se pudo obtener lista');
			}
		};
		fetchData();
	}, []);

	const addDebtHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			if (!owner) {
				toast.error('Debe seleccionar un propietario.');
				return;
			}
			if (!multipleLots && !lot) {
				toast.error('Debe completar el campo padrón.');
				return;
			}
			await AssetService.create({
				name,
				price,
				type,
				ubication,
				description,
				owner: owner[0] || owner,
				folder,
				observations,
				escritura,
				guaranteedAmount: 0,
				currency,
				multipleLots,
				...(multipleLots ? { multipleLots } : { multipleLots, lot }),
			});
			toast.success('Activo creado');
			navigate(-1);
		} catch (ex) {
			// handleClose();
			console.error(ex);
			toast.error(getError(ex));
		}
	};

	const [isInvalid, setIsInvalid] = useState(false);

	const handleAccountChange = (selected) => {
		if (!selected || selected.length === 0) {
			const selectedAccount = selected[0];
			const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
			setIsInvalid(!isValid);
			setOwner('');
			setFolder('');
		} else {
			const selectedAccount = selected[0];
			const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
			setIsInvalid(!isValid);
			setOwner(selectedAccount);
			setCurrency(selectedAccount.currency);
			setFolder(selectedAccount.folder);
		}
	};

	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{(loadingCreate || loadingFetch || uploading) && <LoadingBox></LoadingBox>}
				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Agregar Activo</title>
					</Helmet>
					<h1>
						<MdLibraryAdd></MdLibraryAdd>Agregar Activo
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Row>
								<Form.Group className='mb-3 col-6 form-group required' controlId='name'>
									<Form.Label>Nombre</Form.Label>
									<Form.Control
										type='text'
										onChange={(e) => setName(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3 col-6' controlId='price'>
									<Form.Label>Precio/Valor</Form.Label>
									<FormatedNumberInput
										type='number'
										onChange={(e) => setPrice(e.target.value)}
										min={0}
									></FormatedNumberInput>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group className='mb-3 col-6' controlId='type'>
									<Form.Label>Tipo</Form.Label>
									<Form.Control
										type='text'
										onChange={(e) => setType(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3 col-6' controlId='expirationDate'>
									<Form.Label>Ubicacion</Form.Label>
									<Form.Control
										type='text'
										onChange={(e) => setUbication(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Card>
									<Row>
										<Form.Group
											className=' col-6 form-group d-flex align-items-center'
											controlId='multipleLots'
											style={{ height: '60px' }}
										>
											<Form.Check
												type='checkbox'
												label='El activo tiene multiples padrones?'
												checked={multipleLots}
												onChange={(e) => setMultipleLots(e.target.checked)}
											/>
										</Form.Group>
										<Form.Group className='mb-3 col-6' controlId='periodicity'>
											<Form.Label>Padrón</Form.Label>
											<Form.Control
												type='text'
												onChange={(e) => setLot(e.target.value)}
												disabled={multipleLots}
											></Form.Control>
										</Form.Group>
									</Row>
								</Card>
							</Row>
							<Row>
								<Form.Group className='mb-3 col-6' controlId='periodicity'>
									<Form.Label>Descripcion</Form.Label>
									<Form.Control
										type='text'
										onChange={(e) => setDescription(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3 col-6 form-group required'>
									<Form.Label>Propietario</Form.Label>
									<CustomTypeahead
										id='accounts'
										labelKey={(option) => `${option.name}`}
										onChange={handleAccountChange}
										options={accounts}
										placeholder='Buscar Cuenta de propietario... '
										disabled={disabledOwner}
										selected={owner ? [owner] : null}
										isInvalid={isInvalid}
										linkToCreate='/AdminScreen/newAccount'
										newOption='nueva cuenta'
									/>
									{isInvalid && <div style={{ color: 'red' }}>Cuenta invalida</div>}
								</Form.Group>
							</Row>
							<Row>
								<Form.Group as={Col} className='mb-3 col-3' controlId='currency'>
									<GenericDropdown
										label='Moneda'
										items={currencies}
										selected={currency}
										onChange={(e) => setCurrency(e.value)}
									/>
								</Form.Group>
							</Row>
							{/* <div className="row align-items-centers">            
            <div style={{padding:0, width: '300px', height: '300px', margin: 'auto', border: '1px solid black', backgroundImage: imageUrl ? 'url('+ imageUrl +')' : 'url(/images/emptyPhoto.png)',backgroundRepeat: 'no-repeat', backgroundSize: '100% auto', backgroundPosition: 'center top' }}>
              <AvatarImageCropper apply={apply} text="Adjuntar la imagen"/>
            </div>
          </div> */}
							<Row className='d-flex-inline'>
								<Form.Group as={Col} className='mb-3 col-6' controlId='folder'>
									<Form.Label>Carpeta</Form.Label>
									<Form.Control
										onChange={(e) => setFolder(e.target.value)}
										value={folder ? folder : null}
									></Form.Control>
								</Form.Group>
								<Form.Group className='col-4' controlid='escritura'>
									<Form.Label>Escritura</Form.Label>
									<Form.Control
										type='file'
										required
										onChange={(e) => setEscritura(e.target.files)}
									></Form.Control>
									<Form.Control.Feedback type='invalid'>Seleccione Archivo</Form.Control.Feedback>
								</Form.Group>
								<Button
									className='col-2'
									style={{ height: '30px', marginTop: '32px', padding: '0' }}
									onClick={() => uploadEscritura(escritura)}
								>
									subir
								</Button>
							</Row>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Observaciones</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									rows={10}
									onChange={(e) => setObservations(e.target.value)}
								></Form.Control>
							</Form.Group>
						</div>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={addDebtHandler}>
									Agregar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default AddAsset;
