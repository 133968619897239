import React, { useEffect, useReducer, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { MdLibraryAdd } from 'react-icons/md';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import { getError } from '../../utils';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useNavigate, useParams } from 'react-router-dom';
import CustomTypeahead from '../../components/CustomTypeahead';
import GenericDropdown from '../../components/SelectDropdown';
import assetService from '../../services/asset.service';
import creditService from '../../services/credit.service';
import debtService from '../../services/debt.service';
import warrantyService from '../../services/warranty.service';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			credit: action.payload.credit,
			debts: action.payload.debts,
			assets: action.payload.assets,
			loadingFetch: false,
		};
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function NewWarranty() {
	const navigate = useNavigate();

	const params = useParams();
	const { id } = params;
	const options = ['deuda', 'activo'];

	const [option, setOption] = useState('');
	const [debt, setDebt] = useState('');
	const [asset, setAsset] = useState('');
	const [amount, setAmount] = useState(0);
	const [maximumAmount, setMaximumAmount] = useState();

	const [filteredAssetsList, setFilteredAssetsList] = useState();
	const [filteredDebtsList, setFilteredDebtsList] = useState();

	const [{ loadingFetch, loadingCreate }, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		loadingCreate: false,
		error: '',
	});

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const [creditData, assetsData, debtsData] = await Promise.all([
					creditService.getDetails(id),
					assetService.getForWarranty(),
					debtService.getForWarranty(),
				]);
				dispatch({
					type: 'FETCH_SUCCESS',
					payload: {
						credit: creditData.data,
						assets: assetsData.data,
						debts: debtsData.data,
					},
				});
				const filteredAssets = assetsData.data.filter(
					(asset) => asset.currency === creditData.data.currency,
				);
				const filteredDebts = debtsData.data.filter(
					(debt) => debt.currency === creditData.data.currency,
				);
				setFilteredAssetsList(filteredAssets);
				setFilteredDebtsList(filteredDebts);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, []);

	const addWarrantyHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await warrantyService.create({
				option,
				debt,
				asset,
				amount,
				credit: id,
			});
			toast.success('Garantia creada');
			navigate(-1);
		} catch (ex) {
			toast.error(getError(ex));
		}
	};
	const [isInvalid, setIsInvalid] = useState(false);
	const [isDebtInvalid, setIsDebtInvalid] = useState(false);

	const handleDebtChange = (selected) => {
		if (!selected || selected.length === 0) {
			const selectedDebt = selected[0];
			const isValid = selectedDebt ? filteredDebtsList.includes(selectedDebt) : false;
			setIsDebtInvalid(!isValid);
			setDebt('');
			setAmount('');
		} else {
			const selectedDebt = selected[0];
			const isValid = selectedDebt ? filteredDebtsList.includes(selectedDebt) : false;
			setIsDebtInvalid(!isValid);
			setDebt(selected[0]);
			setAmount(selected[0].capital);
		}
	};
	const handleAssetChange = (selected) => {
		if (!selected || selected.length === 0) {
			const selectedDebt = selected[0];
			const isValid = selectedDebt ? filteredAssetsList.includes(selectedDebt) : false;
			setIsDebtInvalid(!isValid);
			setAsset('');
			setAmount('');
		} else {
			const selectedAsset = selected[0];
			const isValid = selectedAsset ? filteredAssetsList.includes(selectedAsset) : false;
			setIsInvalid(!isValid);
			setAsset(selected[0]);
			setMaximumAmount(selected[0].price - selected[0].guaranteedAmount);
			setAmount(selected[0].price - selected[0].guaranteedAmount);
		}
	};
	const handleAmountChange = (e) => {
		const value = e.target.value;
		if (value <= maximumAmount) {
			setAmount(value);
		}
	};

	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{loadingCreate && loadingFetch && <LoadingBox></LoadingBox>}
				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Agregar Garantia</title>
					</Helmet>
					<h1>
						<MdLibraryAdd></MdLibraryAdd>Agregar Garantia
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Row>
								<Form.Group className='mb-3 col-12' controlId='option'>
									<GenericDropdown
										label='Opción de Garantía'
										items={options}
										selected={option}
										onChange={(e) => setOption(e.value)}
									/>
								</Form.Group>
								{option === 'deuda' && (
									<Form.Group className='mb-3 col-12' controlId='debt'>
										<Form.Label>Deuda</Form.Label>
										<CustomTypeahead
											id='debt'
											labelKey={(option) => `${option.customId}`}
											onChange={handleDebtChange}
											options={filteredDebtsList}
											placeholder='Buscar Deuda...'
											isInvalid={isInvalid}
											linkToCreate='/AdminScreen/newDebt'
											newOption='Nueva Deuda'
										/>
										{isDebtInvalid && (
											<div style={{ color: 'red' }}>Deuda no no seleccionada o invalida</div>
										)}
									</Form.Group>
								)}
								{option === 'activo' && (
									<Form.Group className='mb-3 col-12' controlId='asset'>
										<Form.Label>Activo</Form.Label>
										<CustomTypeahead
											id='asset'
											labelKey={(option) => `${option.customId}`}
											onChange={handleAssetChange}
											options={filteredAssetsList}
											placeholder='Buscar Activo...'
											isInvalid={isInvalid}
											linkToCreate='/AdminScreen/newAsset'
											newOption='Nuevo Activo'
										/>
									</Form.Group>
								)}
							</Row>
							<Row>
								<Form.Group className='mb-3 col-12' controlId='amount'>
									<Form.Label>Monto</Form.Label>
									<Form.Control
										type='number'
										value={amount}
										onChange={(e) => handleAmountChange(e)}
										disabled={option === 'debt'}
										max={
											option === 'asset' && asset ? asset.price - asset.guaranteedAmount : undefined
										}
										min={0}
									/>
								</Form.Group>
							</Row>
						</div>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={addWarrantyHandler}>
									Agregar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default NewWarranty;
