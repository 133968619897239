import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import { MdLibraryAdd } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormatedNumberInput from '../../components/FormatedNumberInput';
import LoadingBox from '../../components/LoadingBox';
import debtService from '../../services/debt.service';
import generatedDebtService from '../../services/generatedDebts.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return { ...state, debts: action.payload, loadingFetch: false };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function NewDebtDue() {
	const navigate = useNavigate();

	const [amount, setAmount] = useState();
	const [date, setDate] = useState();
	const params = useParams();
	const { id } = params;
	const [debt, setDebt] = useState(id);
	const [customId, setCustomId] = useState();
	const [expiration, setExpiration] = useState();
	const [paid, setPaid] = useState('false');

	const [{ loadingFetch, loadingCreate, debts }, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		debts: [],
		loadingCreate: false,
		error: '',
	});

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await debtService.list();
				const debtId = result.data.filter((debt) => debt._id === id)[0];
				setAmount(debtId.amount);
				setDebt(debtId);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, []);

	const addDueHandler = async () => {
		if (!debt) {
			toast.error('Debe seleccionar deudor.');
			return;
		}
		if (!date) {
			toast.error('Debe seleccionar fecha.');
			return;
		}
		if (!amount) {
			toast.error('Debe seleccionar monto.');
			return;
		}
		if (!paid) {
			toast.error('Debe seleccionar pago.');
			return;
		}
		if (!customId) {
			toast.error('Debe indicar detalle.');
			return;
		}
		if (!expiration) {
			toast.error('Debe seleccionar vencimiento.');
			return;
		}
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await generatedDebtService.create({
				amount,
				debt,
				date,
				customId,
				paid,
				expiration,
			});
			dispatch({ type: 'CREATE_SUCCESS' });
			toast.success('Cuota creada');
			navigate(-1);
		} catch (ex) {
			toast.error(getError(ex));
		}
	};

	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{(loadingCreate || loadingFetch) && <LoadingBox></LoadingBox>}
				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Agregar Cuota</title>
					</Helmet>
					<h1>
						<MdLibraryAdd></MdLibraryAdd>Agregar Cuota
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Form.Group className='mb-3 col-6 form-group required'>
								<Form.Label>Deudor</Form.Label>
								<Form.Select disabled value={debt._id} onChange={(e) => setDebt(e.target.value)}>
									<option key={0}>Seleccione una deuda</option>
									{debts.map((debt) => {
										return (
											<option key={debt._id} value={debt._id}>
												{debt.owner ? debt.customId : debt.customId}
											</option>
										);
									})}
								</Form.Select>
							</Form.Group>
							<Form.Group className='mb-3 col-6 form-group required' controlId='amount'>
								<Form.Label>Monto</Form.Label>
								<FormatedNumberInput
									type='number'
									value={amount ? amount : null}
									onChange={(e) => setAmount(e.target.value)}
								></FormatedNumberInput>
							</Form.Group>
							<Form.Group className='mb-3 col-4 form-group required' controlId='date'>
								<Form.Label>Fecha inicio</Form.Label>
								<Form.Control
									type='date'
									max={moment(debt.date).format('YYYY-MM-DD')}
									onChange={(e) => setDate(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3 col-4 form-group required' controlId='date'>
								<Form.Label>Vencimiento</Form.Label>
								<Form.Control
									type='date'
									onChange={(e) => setExpiration(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3 col-4 form-group required'>
								<Form.Label>Paga?</Form.Label>
								<Form.Select onChange={(e) => setPaid(e.target.value)} value={paid}>
									<option key='false' value='false'>
										No
									</option>
									<option key='true' value='true'>
										Sí
									</option>
								</Form.Select>
							</Form.Group>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Detalle</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									rows={10}
									onChange={(e) => setCustomId(e.target.value)}
								></Form.Control>
							</Form.Group>
						</div>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={addDueHandler}>
									Agregar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default NewDebtDue;
