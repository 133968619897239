import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Modal, Col, Form, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Helmet } from 'react-helmet-async';
import { MdOutlineEdit } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import CustomTypeahead from '../../components/CustomTypeahead';
import FormatedNumberInput from '../../components/FormatedNumberInput';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import GenericDropdown from '../../components/SelectDropdown';
import creditService from '../../services/credit.service';
import creditPaymentService from '../../services/creditPayment.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_DEBTS_REQUEST':
		return { ...state, loadingContacts: true };
	case 'FETCH_DEBTS_SUCCESS':
		return { ...state, credits: action.payload, loadingContacts: false };
	case 'FETCH_DEBTS_FAIL':
		return { ...state, loadingContacts: false, error: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, creditPayment: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function EditCreditPayment() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, error, creditPayment, credits }, dispatch] = useReducer(reducer, {
		credits: [],
		creditPayment: {},
		loading: true,
		error: '',
	});
	const { dispatch: ctxDispatch } = useContext(Store);
	const currencies = ['USD', 'UYU'];

	const [amount, setAmount] = useState();
	const [date, setDate] = useState();
	const [credit, setCredit] = useState();
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();
	const [formatedDate, setFormatedDate] = useState();
	const [comprobante, setComprobante] = useState();
	const [uploading, setUploading] = useState(false);
	const [creditDue, setCreditDue] = useState();
	const [currency, setCurrency] = useState();
	const [generatedCreditDues, setGeneratedCreditDues] = useState();

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await creditPaymentService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				setAmount(result.data.amount);
				setDate(result.data.date);
				setCredit([result.data.credit]);
				setFolder(result.data.folder);
				setObservations(result.data.observations);
				setFormatedDate(result.data.date.substring(0, 10));
				setComprobante(result.data.comprobante);
				setCreditDue([result.data.creditDue]);
				setCurrency(result.data.currency);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
			// setdebts(result.data);
		};
		const fetchDebts = async () => {
			dispatch({ type: 'FETCH_DEBTS_REQUEST' });
			try {
				const result = await creditService.list();
				dispatch({ type: 'FETCH_DEBTS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchDebts();
		fetchData();
	}, [id]);

	const editPaymentHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			if (!credit) {
				toast.error('Debe seleccionar una pasividad.');
				return;
			}
			const { data } = await creditPaymentService.edit(creditPayment._id, {
				amount,
				date,
				credit: credit[0],
				folder,
				observations,
				comprobante,
				creditDue: creditDue[0],
				currency,
			});
			// deletePictureHandler(image);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'CONTACT_UPDATE_SUCCESS', payload: data });
			toast.success('Pago actualizado Correctamente');
			navigate(-1);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL' });
			toast.error(getError(err));
		}
	};

	const uploadComprobante = async () => {
		setUploading(true);
		if (!comprobante) {
			toast.warning('Debe seleccionar un archivo');
			setUploading(false);
			return;
		}

		const file = comprobante[0];
		const formData = new FormData();
		let r = Math.random().toString(36).substring(7);
		formData.append('file', file, (file.name || '').replaceAll(' ', '') + r);

		formData.append('documentId', comprobante._id);
		try {
			const response = await creditPaymentService.uploadComprobante(formData);
			setComprobante(response.data);
			setUploading(false);
			toast.success('Archivo Subido');
		} catch (ex) {
			setUploading(false);
			toast.error(getError(ex));
		}
	};
	const [isInvalid, setIsInvalid] = useState(false);
	const [isCreditDueInvalid, setIsCreditDueInvalid] = useState(false);

	const handleCreditChange = (selected) => {
		if (!selected || selected.length === 0) {
			const selectedCredit = selected[0];
			const isValid = credits.includes(selectedCredit);
			setIsInvalid(!isValid);
			setCredit(null);
			setGeneratedCreditDues(null);
			setCurrency('');
			setFolder('');
			setCreditDue(null);
		} else {
			const selectedCredit = selected[0];
			const isValid = credits.includes(selectedCredit);
			setIsInvalid(!isValid);
			setCredit(selectedCredit);
			setGeneratedCreditDues(selectedCredit.generatedDebts);
			setCurrency(selectedCredit.currency);
			setFolder(selectedCredit.owner.folder);
		}
	};
	const handleCreditDueChange = (selected) => {
		if (generatedCreditDues) {
			if (!selected || selected.length === 0) {
				const selectedCreditDue = selected[0];
				const isValid = generatedCreditDues.includes(selectedCreditDue);
				setIsCreditDueInvalid(!isValid);
				setCreditDue(null);
			} else {
				const selectedCreditDue = selected[0];
				const isValid = generatedCreditDues.includes(selectedCreditDue);
				setIsCreditDueInvalid(!isValid);
				setCreditDue(selectedCreditDue);
				setAmount(selectedCreditDue.amount);
			}
		}
	};
	const isButtonEnabled = () => {
		const emptyFields = [];
		if (!credit) {
			emptyFields.push('Pasividad');
		}
		if (!creditDue) {
			emptyFields.push('cuota');
		}
		if(!date) {
			emptyFields.push('Fecha');
		} 
		if (!amount || (amount <= 0)) {
			emptyFields.push('Monto');
		}
		return emptyFields.length === 0;
	};

	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{(loading || uploading) ? (
					<LoadingBox></LoadingBox>
				) : (
					error ? (
						<MessageBox variant='danger'>{error}</MessageBox>
					) : null
				)}				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Editar Pago</title>
					</Helmet>
					<h1>
						<MdOutlineEdit></MdOutlineEdit>Editar Pago
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Form.Group className='mb-3 col-6 form-group required'>
								<Form.Label>Pasividad</Form.Label>
								{creditPayment.credit && creditPayment.credit != null ? (
									<>
										<CustomTypeahead
											id='credits'
											labelKey={(option) => `${option.customId}`}
											onChange={handleCreditChange}
											selected={credit}
											options={credits}
											disabled
											placeholder='Buscar Pasividad...'
											linkToCreate='/AdminScreen/newCredit'
											newOption='nueva pasividad'
										/>
										{isInvalid && (
											<div style={{ color: 'red' }}>Pasividad no seleccionada o invalida</div>
										)}
									</>
								) : (
									<>
										<CustomTypeahead
											id='credits'
											labelKey={(option) => `${option.customId}`}
											onChange={handleCreditChange}
											options={credit}
											disabled
											placeholder='Buscar deuda...'
											linkToCreate='/AdminScreen/newCredit'
											newOption='nueva pasividad'
										/>
										{isInvalid && (
											<div style={{ color: 'red' }}>Pasividad no seleccionada o invalida</div>
										)}
									</>
								)}
							</Form.Group>
							<Form.Group className='mb-3 col-6 form-group required'>
								<Form.Label>Cuota Deuda</Form.Label>
								<CustomTypeahead
									id='credits'
									labelKey={(option) => `${option.customId}`}
									onChange={handleCreditDueChange}
									selected={creditDue}
									options={generatedCreditDues ? generatedCreditDues : []}
									placeholder='Buscar cuota...'
									linkToCreate=''
									disabled
									newOption={null}
									newSelectionPrefix={null}
								/>
								{isCreditDueInvalid && <div style={{ color: 'red' }}>invalido</div>}
							</Form.Group>
							<Form.Group className='mb-3 col-6 form-group required' controlId='amount'>
								<Form.Label>Monto</Form.Label>
								<FormatedNumberInput
									type='number'
									value={amount}
									onChange={(e) => setAmount(e.target.value)}
									min={0}
								></FormatedNumberInput>
							</Form.Group>
							<Form.Group className='mb-3 col-6 form-group required' controlId='date'>
								<Form.Label>Fecha</Form.Label>
								<Form.Control
									type='date'
									defaultValue={formatedDate}
									onChange={(e) => setDate(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-3' controlId='currency'>
								<GenericDropdown
									label='Moneda'
									items={currencies}
									selected={currency}
									onChange={(e) => setCurrency(e.value)}
								/>
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-6' controlId='folder'>
								<Form.Label>Carpeta</Form.Label>
								<Form.Control
									value={folder}
									onChange={(e) => setFolder(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group className='col-10' controlid='escritura' value={comprobante}>
								<Form.Label>Comprobante</Form.Label>
								<Form.Control
									type='file'
									required
									onChange={(e) => setComprobante(e.target.files)}
								></Form.Control>
								<Form.Control.Feedback type='invalid'>Seleccione Archivo</Form.Control.Feedback>
							</Form.Group>
							<Button
								className='col-2'
								style={{ height: '30px', marginTop: '32px', padding: '0' }}
								onClick={() => uploadComprobante(comprobante)}
							>
								subir
							</Button>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Observaciones</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									value={observations}
									rows={10}
									onChange={(e) => setObservations(e.target.value)}
								></Form.Control>
							</Form.Group>
						</div>

						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							{!isButtonEnabled() ? (
								<OverlayTrigger
									placement='right'
									overlay={
										<Tooltip id='tooltip-payment'>
											Para editar pago debe completar los campos obligatorios: Monto y Fecha
										</Tooltip>
									}
								>
									<Col>
										<Button className='guardar' onClick={editPaymentHandler} disabled={!isButtonEnabled()}>
									Guardar
										</Button>
									</Col>
								</OverlayTrigger>
							) : (
								<Col>
									<Button className='guardar' onClick={editPaymentHandler} disabled={false}>
										Guardar
									</Button>
								</Col>
							)}
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default EditCreditPayment;
