import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { Card, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Form from 'react-bootstrap/Form';
import { MdInfoOutline } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import accountService from '../../services/account.service';
import assetService from '../../services/asset.service';
import contractService from '../../services/contract.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_ACCOUNTS_REQUEST':
		return { ...state, loadingAccounts: true };
	case 'FETCH_ACCOUNTS_SUCCESS':
		return { ...state, accounts: action.payload, loadingAccounts: false };
	case 'FETCH_ACCOUNTS_FAIL':
		return { ...state, loadingAccounts: false, error: action.payload };
	case 'FETCH_ASSETS_REQUEST':
		return { ...state, loadingAssets: true };
	case 'FETCH_ASSETS_SUCCESS':
		return { ...state, assets: action.payload, loadingAssets: false };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, contract: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function ContractInfo() {
	const params = useParams();

	const { id } = params;
	const [{ loading, error, loadingAccounts, accounts, assets, loadingAssets }, dispatch] =
		useReducer(reducer, {
			accounts: [],
			assets: [],
			loadingAccounts: true,
			loadingAssets: true,
			loading: true,
			error: '',
		});
	const currencies = ['USD', 'UYU'];
	const [periodInit, setPeriodInit] = useState();
	const [periodEnd, setPeriodEnd] = useState();
	const [amount, setAmount] = useState();
	const [owner, setOwner] = useState('');
	const [field, setField] = useState('');
	const [property, setProperty] = useState('');
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();
	const [paymentMethod, setPaymentMethod] = useState();
	const [currency, setCurrency] = useState();
	const [hasPreviousDebts, setHasPreviousDebts] = useState(false);
	const [previousDebt, setPreviousDebt] = useState();
	const [previousDebtDate, setPreviousDebtDate] = useState();

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await contractService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				setAmount(result.data.amount);
				setPeriodInit(result.data.periodInit);
				setPeriodEnd(result.data.periodEnd);
				setOwner(result.data.owner);
				setField(result.data.field);
				setProperty(result.data.property);
				setFolder(result.data.folder);
				setObservations(result.data.observations);
				setPaymentMethod(result.data.paymentMethod);
				setCurrency(result.data.currency);
				setHasPreviousDebts(result.data.hasPreviousDebts);
				setPreviousDebt(result.data.previousDebt);
				setPreviousDebtDate(result.data.previousDebtDate);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
			// setdebts(result.data);
		};
		const fetchAccounts = async () => {
			dispatch({ type: 'FETCH_ACCOUNTS_REQUEST' });
			try {
				const result = await accountService.list();
				dispatch({ type: 'FETCH_ACCOUNTS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		const fetchAssets = async () => {
			dispatch({ type: 'FETCH_ASSETS_REQUEST' });
			try {
				const result = await assetService.list();
				dispatch({ type: 'FETCH_ASSETS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchAccounts();
		fetchData();
		fetchAssets();
	}, [id]);

	return (
		<div>
			{(loading || loadingAccounts || loadingAssets) ? (
				<LoadingBox></LoadingBox>
			) : (
				error ? (
					<MessageBox variant='danger'>{error}</MessageBox>
				) : null
			)}
			<div className='container admin-con align-items-center'>
				<Form onSubmit={(e) => e.preventDefault()}>
					<div className='row align-items-center'>
						<Card className='d-flex mb-3 justify-content-center card-form-debts'>
							<Row>
								<h2 className='text-center m-3'>
									<OverlayTrigger
										trigger='click'
										right='left'
										overlay={
											<Popover id='tooltip-previous-debt' style={{ minWidth: '50%' }}>
												<Popover.Header as='h3'> A tener en cuenta:</Popover.Header>
												<Popover.Body>
													<ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
														<li>
															La información que aqui se visualiza corresponde unicamente a los
															datos contractuales
														</li>
														<li>
															{' '}
															Si desea visualizar los datos de la/s deuda/s relacionada/s, debe
															ingresar a la pantalla específica de cada deuda{' '}
														</li>
													</ul>
												</Popover.Body>
											</Popover>
										}
									>
										<span style={{ cursor: 'pointer' }}>
											<MdInfoOutline />
										</span>
									</OverlayTrigger>
									Datos Contracturales
								</h2>
							</Row>
							<Row>
								<Form.Group
									className='mb-3 col-4 form-group d-flex align-items-center'
									controlId='hasPreviousDebts'
									style={{ height: '60px' }}
								>
									<Form.Check
										className='d-flex align-items-center'
										type='checkbox'
										label='Tiene saldo anterior?'
										checked={hasPreviousDebts}
										disabled
									/>
								</Form.Group>
								{hasPreviousDebts && (
									<>
										<Form.Group className='mb-3 col-4 form-group ' controlId='previousDebt'>
											<Form.Label>Saldo Anterior</Form.Label>
											<Form.Control
												type='number'
												defaultValue={previousDebt}
												disabled
											></Form.Control>
										</Form.Group>
										<Form.Group className='mb-3 col-4 form-group ' controlId='previousDebtDate'>
											<Form.Label>Fecha saldo anterior</Form.Label>
											<Form.Control
												type='date'
												value={
													previousDebtDate
														? moment(previousDebtDate).utc().format('YYYY-MM-DD')
														: null
												}
												disabled
											></Form.Control>
										</Form.Group>
									</>
								)}
							</Row>
							<Row>
								{property ? (
									<Form.Group className='mb-3 col-6 form-group required '>
										<Form.Label>Propiedad</Form.Label>
										<Form.Select value={property._id} disabled>
											{assets.map((asset) => {
												return (
													<option key={asset._id} value={asset._id}>
														{asset.name}
													</option>
												);
											})}
											<option key='blankChoice' hidden value>
												{' '}
												Elige la propiedad{' '}
											</option>
										</Form.Select>
									</Form.Group>
								) : (
									<Form.Group className='mb-3 col-6 form-group required '>
										<Form.Label>Propiedad</Form.Label>
										<Form.Select value={property ? property._id : null} disabled>
											{assets.map((asset) => {
												return (
													<option key={asset._id} value={asset._id}>
														{asset.name}
													</option>
												);
											})}
											<option key='blankChoice' hidden value>
												{' '}
												No tiene Propiedad asignada{' '}
											</option>
										</Form.Select>
									</Form.Group>
								)}
								{owner ? (
									<Form.Group className='mb-3 col-6 form-group required '>
										<Form.Label>Inquilino</Form.Label>
										<Form.Select value={owner._id} disabled>
											{accounts.map((account) => {
												return (
													<option key={account._id} value={account._id}>
														{account.name}
													</option>
												);
											})}
											<option key='blankChoice' hidden value>
												{' '}
												Elige al inquilino{' '}
											</option>
										</Form.Select>
									</Form.Group>
								) : (
									<Form.Group className='mb-3 col-6 form-group required '>
										<Form.Label>Inquilino</Form.Label>
										{owner ? (
											<Form.Select value={owner._id} disabled>
												{accounts.map((account) => {
													return (
														<option key={account._id} value={account._id}>
															{account.name}
														</option>
													);
												})}
												<option key='blankChoice' hidden value>
													{' '}
													No tiene Inquilino asignado{' '}
												</option>
											</Form.Select>
										) : (
											<Form.Select value={owner} disabled>
												{accounts.map((account) => {
													return (
														<option key={account._id} value={account._id}>
															{account.name}
														</option>
													);
												})}
												<option key='blankChoice' hidden value>
													{' '}
													No tiene Inquilino asignado{' '}
												</option>
											</Form.Select>
										)}
									</Form.Group>
								)}
							</Row>
							<Row>
								<Form.Group className='mb-3 col-3 form-group required' controlId='date'>
									<Form.Label>Inicio</Form.Label>
									<Form.Control
										type='date'
										value={moment.utc(periodInit).format('YYYY-MM-DD')}
										disabled
									></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3 col-3 form-group' controlId='date'>
									<Form.Label>Fin</Form.Label>
									<Form.Control
										type='date'
										value={moment.utc(periodEnd).format('YYYY-MM-DD')}
										disabled
									></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3 col-3 form-group required' controlId='amount'>
									<Form.Label>Monto</Form.Label>
									<Form.Control type='number' defaultValue={amount} disabled></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3 col-3 ' controlId='currency'>
									<Form.Label>Moneda</Form.Label>
									<Form.Select as='select' value={currency} disabled>
										<option key={0}>Elige Moneda</option>
										{currencies.map((currency, index) => {
											return (
												<option key={index} value={currency}>
													{currency}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>
							</Row>
							<Form.Group style={{ marginBottom: '5%' }} className='mb-3 col-12' controlId='field'>
								<Form.Label>Detalles</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									rows={10}
									defaultValue={field}
									disabled
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3' controlId=''>
								<Form.Label>Carpeta</Form.Label>
								<Form.Control
									type='text'
									defaultValue={folder ? folder : null}
									disabled
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3' controlId=''>
								<Form.Label>Forma de cobro</Form.Label>
								<Form.Control type='text' defaultValue={paymentMethod} disabled></Form.Control>
							</Form.Group>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Observaciones</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									rows={10}
									defaultValue={observations}
									disabled
								></Form.Control>
							</Form.Group>
						</Card>
					</div>
				</Form>
			</div>
		</div>
	);
}
export default ContractInfo;
