import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Modal, Row, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Helmet } from 'react-helmet-async';
import { MdLibraryAdd } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomTypeahead from '../../components/CustomTypeahead';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import GenericDropdown from '../../components/SelectDropdown';
import creditService from '../../services/credit.service';
import creditPaymentService from '../../services/creditPayment.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return { ...state, credits: action.payload, loadingFetch: false };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function NewCreditPaymentNew() {
	const navigate = useNavigate();

	const params = useParams();
	const { id } = params;
	const currencies = ['USD', 'UYU'];

	const [amount, setAmount] = useState();
	const [date, setDate] = useState();
	const [credit, setCredit] = useState(id);
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();
	const [comprobante, setComprobante] = useState('');
	const [uploading, setUploading] = useState(false);
	const [creditDue, setCreditDue] = useState();
	const [generatedCreditDues, setGeneratedCreditDues] = useState([]);
	const [currency, setCurrency] = useState();
	const [unpaidAmount, setUnpaidAmount] = useState();

	const [{ loadingFetch, loadingCreate, credits, errorFetch }, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		credits: [],
		loadingCreate: false,
		errorFetch: '',
	});

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await creditService.list();
				const creditId = result.data.filter((credit) => credit._id === id)[0];
				setGeneratedCreditDues(creditId.generatedCredits);
				setCurrency(creditId.currency);
				setFolder(creditId.owner.folder);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, [creditDue]);

	const uploadComprobante = async () => {
		setUploading(true);
		if (!comprobante) {
			toast.warning('Debe seleccionar un archivo');
			setUploading(false);
			return;
		}

		const file = comprobante[0];
		const formData = new FormData();
		let r = Math.random().toString(36).substring(7);
		formData.append('file', file, (file.name || '').replaceAll(' ', '') + r);

		formData.append('documentId', comprobante._id);
		try {
			const response = await creditPaymentService.uploadComprobante(formData);
			setComprobante(response.data);
			setUploading(false);
			toast.success('Archivo Subido');
		} catch (ex) {
			setUploading(false);
			toast.error(getError(ex));
		}
	};

	const addPaymentHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			if (!credit) {
				toast.error('Debe seleccionar una pasividad.');
				dispatch({ type: 'CREATE_FAIL' });
				return;
			}
			await creditPaymentService.create({
				amount,
				date,
				credit,
				folder,
				observations,
				comprobante,
				creditDue,
				currency,
			});
			toast.success('Pago creado');
			navigate(-1);
		} catch (ex) {
			toast.error(getError(ex));
		}
	};
	const [isCreditDueInvalid, setIsCreditDueInvalid] = useState(false);

	const handleCreditDueChange = (selected) => {
		if (generatedCreditDues) {
			if (!selected || selected.length === 0) {
				const selectedCreditDue = selected[0];
				const isValid = generatedCreditDues.includes(selectedCreditDue);
				setIsCreditDueInvalid(!isValid);
				setCreditDue(null);
				setUnpaidAmount(0);
				setAmount(0);
			} else {
				const selectedCreditDue = selected[0];
				const isValid = generatedCreditDues.includes(selectedCreditDue);
				setIsCreditDueInvalid(!isValid);
				setCreditDue(selectedCreditDue);
				setAmount(selectedCreditDue.unpaidAmount);
				setUnpaidAmount(selectedCreditDue.unpaidAmount);
			}
		}
	};

	const handleAmountChange = (e) => {
		const value = e.target.value;
		if (value <= unpaidAmount) {
			setAmount(value);
		}
	};
	const isButtonEnabled = () => {
		const emptyFields = [];
		if (!credit) {
			emptyFields.push('Pasividad');
		}
		if (!creditDue) {
			emptyFields.push('cuota');
		}
		if(!date) {
			emptyFields.push('Fecha');
		} 
		if (!amount || (amount <= 0)) {
			emptyFields.push('Monto');
		}
		return emptyFields.length === 0;
	};
	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{(loadingCreate || loadingFetch || uploading) ? (
					<LoadingBox></LoadingBox>
				) : (
					errorFetch ? (
						<MessageBox variant='danger'>{errorFetch}</MessageBox>
					) : null
				)}				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Agregar Pago</title>
					</Helmet>
					<h1>
						<MdLibraryAdd></MdLibraryAdd>Agregar Pago
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Form.Group className='mb-3 col-6 form-group required'>
								<Form.Label>Pasividad</Form.Label>
								<Form.Select disabled value={id} onChange={(e) => setCredit(e.target.value)}>
									<option key={0}>Seleccione pasividad</option>
									{credits.map((credit) => {
										return (
											<option key={credit._id} value={credit._id}>
												{credit.owner ? credit.customId : credit.customId}
											</option>
										);
									})}
								</Form.Select>
							</Form.Group>
							<Form.Group className='mb-3 col-6 form-group required'>
								<Form.Label>Cuota Pasividad</Form.Label>
								<CustomTypeahead
									id='credits'
									labelKey={(option) => `${option.customId}`}
									onChange={handleCreditDueChange}
									options={
										generatedCreditDues ? generatedCreditDues.filter((due) => !due.paid) : []
									}
									placeholder='Buscar cuota...'
									linkToCreate=''
									newOption={null}
									newSelectionPrefix={null}
								/>
								{isCreditDueInvalid && <div style={{ color: 'red' }}>invalido</div>}
							</Form.Group>
							<Form.Group className='mb-3 col-6 form-group required' controlId='amount'>
								<Form.Label>Monto</Form.Label>
								<Form.Control
									type='number'
									onChange={(e) => handleAmountChange(e)}
									defaultValue={amount}
									min={0}
									onInput={(e) => {
										const inputValue = parseFloat(e.target.value);
										const maxValue = creditDue ? unpaidAmount : null;

										if (maxValue !== null && inputValue > maxValue) {
											e.target.value = maxValue; // Set the value to the maximum allowed value
										}
									}}
								/>
							</Form.Group>
							<Form.Group className='mb-3 col-6 form-group required' controlId='date'>
								<Form.Label>Fecha</Form.Label>
								<Form.Control type='date' onChange={(e) => setDate(e.target.value)}></Form.Control>
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-3' controlId='currency'>
								<GenericDropdown
									label='Moneda'
									items={currencies}
									selected={currency}
									onChange={(e) => setCurrency(e.value)}
								/>
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-6' controlId='folder'>
								<Form.Label>Carpeta</Form.Label>
								<Form.Control
									onChange={(e) => setFolder(e.target.value)}
									value={folder ? folder : null}
								></Form.Control>
							</Form.Group>
							<Row>
								<Form.Group className='col-10' controlid='comprobante'>
									<Form.Label>Comprobante</Form.Label>
									<Form.Control
										type='file'
										required
										onChange={(e) => setComprobante(e.target.files)}
									></Form.Control>
									<Form.Control.Feedback type='invalid'>Seleccione Archivo</Form.Control.Feedback>
								</Form.Group>
								<Button
									className='col-2'
									style={{ height: '30px', marginTop: '32px', padding: '0' }}
									onClick={() => uploadComprobante(comprobante)}
								>
									subir
								</Button>
							</Row>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Observaciones</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									rows={10}
									onChange={(e) => setObservations(e.target.value)}
								></Form.Control>
							</Form.Group>
						</div>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							{!isButtonEnabled() ? (
								<OverlayTrigger
									placement='right'
									overlay={
										<Tooltip id='tooltip-payment'>
											Para ingresar pago debe completar los campos obligatorios: Pasividad,Cuota, Monto y Fecha
										</Tooltip>
									}
								>
									<Col>
										<Button className='guardar' onClick={addPaymentHandler} disabled>
											Agregar
										</Button>
									</Col>
								</OverlayTrigger>
							) : (
								<Col>
									<Button className='guardar' onClick={addPaymentHandler} disabled={false}>
										Agregar
									</Button>
								</Col>
							)}
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default NewCreditPaymentNew;
