import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Helmet } from 'react-helmet-async';
import { MdLibraryAdd } from 'react-icons/md';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomTypeahead from '../../components/CustomTypeahead';
import LoadingBox from '../../components/LoadingBox';
import accountService from '../../services/account.service';
import contactService from '../../services/contact.service';
import userService from '../../services/user.service';
import { getError, getUserInfo } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return { ...state, accounts: action.payload, loadingFetch: false };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function AddContact() {
	const navigate = useNavigate();
	const userInfo = getUserInfo();
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [address, setAddress] = useState();
	const [phone, setPhone] = useState();
	const [account, setAccount] = useState();
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();

	const [isUser] = useState(false);
	const createdBy = userInfo._id;
	const [disabledAccount, setDisabledAccount] = useState(false);

	const [{ loadingFetch, loadingCreate, accounts }, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		accounts: [],
		loadingCreate: false,
		error: '',
	});
	const params = useParams();
	const { id } = params;
	const location = useLocation();
	const route = location.pathname;

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await accountService.list();
				if (route.includes('account')) {
					const selectedAccount = result.data.filter((accountData) => accountData._id === id);
					setAccount(selectedAccount);
					setDisabledAccount(true);
				}
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, []);

	const addContactHandler = async () => {
		if (isUser) {
			try {
				await userService.create({
					name,
					email,
					password: 'defaultPsw',
					isAdmin: false,
				});
			} catch (ex) {
				toast.error(getError(ex));
			}
		}
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await contactService.create({
				name,
				email,
				address,
				phone,
				account: account[0] || account,
				folder,
				observations,
				createdBy,
			});
			toast.success('Contacto creado');
			navigate(-1);
		} catch (ex) {
			toast.error(getError(ex));
		}
	};

	const [isInvalid, setIsInvalid] = useState(false);

	const handleAccountChange = (selected) => {
		const selectedAccount = selected[0];
		const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
		setIsInvalid(!isValid);
		setAccount(selected[0]);
	};

	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{loadingCreate && loadingFetch && <LoadingBox></LoadingBox>}
				<Helmet>
					<title>Nuevo Contacto</title>
				</Helmet>
				<div className='container admin-con align-items-center'>
					<h1>
						<MdLibraryAdd></MdLibraryAdd>Nuevo Contacto
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<Row className='mb-3'>
							<Form.Group as={Col} className='mb-3 col-6 form-group required' controlId='name'>
								<Form.Label>Nombre</Form.Label>
								<Form.Control onChange={(e) => setName(e.target.value)}></Form.Control>
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-6 form-group required' controlId='email'>
								<Form.Label>Email</Form.Label>
								<Form.Control onChange={(e) => setEmail(e.target.value)}></Form.Control>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group className='mb-3 col-6' controlId='address'>
								<Form.Label>Direccion</Form.Label>
								<Form.Control onChange={(e) => setAddress(e.target.value)}></Form.Control>
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-6' controlId='phone'>
								<Form.Label>Telefono</Form.Label>
								<Form.Control onChange={(e) => setPhone(e.target.value)}></Form.Control>
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-6' controlId='account'>
								<Form.Label>Cuenta de contacto</Form.Label>
								<CustomTypeahead
									id='accounts'
									labelKey={(option) => `${option.name}`}
									onChange={handleAccountChange}
									options={accounts}
									placeholder='Buscar Cuenta... '
									selected={account ? account : null}
									disabled={disabledAccount}
									isInvalid={isInvalid}
									linkToCreate='/AdminScreen/newAccount'
									newOption='nueva cuenta'
								/>
								{isInvalid && <div style={{ color: 'red' }}>Cuenta invalida</div>}
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-6' controlId='folder'>
								<Form.Label>Carpeta</Form.Label>
								<Form.Control onChange={(e) => setFolder(e.target.value)}></Form.Control>
							</Form.Group>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Observaciones</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									rows={10}
									onChange={(e) => setObservations(e.target.value)}
								></Form.Control>
							</Form.Group>
						</Row>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={addContactHandler}>
									Agregar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default AddContact;
