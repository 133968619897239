import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Modal, Row,OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import { MdLibraryAdd } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomTypeahead from '../../components/CustomTypeahead';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import GenericDropdown from '../../components/SelectDropdown';
import debtService from '../../services/debt.service';
import paymentService from '../../services/payment.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return { ...state, debts: action.payload, loadingFetch: false };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function NewPaymentNew() {
	const navigate = useNavigate();

	const currencies = ['USD', 'UYU'];

	const [amount, setAmount] = useState();
	const [date, setDate] = useState();
	const params = useParams();
	const { id } = params;
	const [debt, setDebt] = useState(id);
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();
	const [comprobante, setComprobante] = useState('');
	const [uploading, setUploading] = useState(false);
	const [debtDue, setDebtDue] = useState();
	const [generatedDues, setGeneratedDues] = useState([]);
	const [currency, setCurrency] = useState();
	const [unpaidAmount, setUnpaidAmount] = useState();

	const [{ loadingFetch, loadingCreate, debts,errorFetch }, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		debts: [],
		loadingCreate: false,
		errorFetch: '',
	});

	const uploadComprobante = async () => {
		setUploading(true);
		if (!comprobante) {
			toast.warning('Debe seleccionar un archivo');
			setUploading(false);
			return;
		}

		const file = comprobante[0];
		const formData = new FormData();
		let r = Math.random().toString(36).substring(7);
		formData.append('file', file, (file.name || '').replaceAll(' ', '') + r);

		formData.append('documentId', comprobante._id);
		try {
			const response = await paymentService.uploadComprobante(formData);
			setComprobante(response.data);
			setUploading(false);
			toast.success('Archivo Subido');
		} catch (ex) {
			setUploading(false);
			toast.error(getError(ex));
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await debtService.list();
				const debtId = result.data.filter((debt) => debt._id === id)[0];
				setGeneratedDues(debtId.generatedDebts);
				setCurrency(debtId.currency);
				setFolder(debtId.owner.folder);
				setDebt(debtId);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, []);
	

	const addPaymentHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			if (!debt) {
				toast.error('Debe seleccionar deudor.');
				dispatch({ type: 'CREATE_FAIL' });
				return;
			}
			if (!debtDue) {
				toast.error('Debe seleccionar cuota.');
				dispatch({ type: 'CREATE_FAIL' });
				return;
			}
			if (!date) {
				toast.error('Debe seleccionar fecha.');
				dispatch({ type: 'CREATE_FAIL' });
				return;
			}
			if (!amount || (amount <= 0)) {
				toast.error('Debe seleccionar monto mayor a 0.');
				dispatch({ type: 'CREATE_FAIL' });
				return;
			}
			await paymentService.create({
				amount,
				date,
				debt,
				folder,
				observations,
				comprobante,
				debtDue,
				currency,
			});
			dispatch({ type: 'CREATE_SUCCESS' });
			toast.success('Cobro creado');
			navigate(-1);
		} catch (ex) {
			toast.error(getError(ex));
		}
	};
	const [isDebtDueInvalid, setIsDebtDueInvalid] = useState(false);

	const handleDebtDueChange = (selected) => {
		if (generatedDues) {
			if (!selected || selected.length === 0) {
				const selectedDebtDue = selected[0];
				const isValid = generatedDues.includes(selectedDebtDue);
				setIsDebtDueInvalid(!isValid);
				setDebtDue(null);
				setUnpaidAmount(0);
				setAmount(0);
			} else {
				const selectedDebtDue = selected[0];
				const isValid = generatedDues.includes(selectedDebtDue);
				setIsDebtDueInvalid(!isValid);
				setDebtDue(selectedDebtDue);
				setUnpaidAmount(selectedDebtDue.unpaidAmount);
				setAmount(selectedDebtDue.unpaidAmount);
			}
		}
	};
	const handleAmountChange = (e) => {
		const value = e.target.value;
		if (value <= unpaidAmount) {
			setAmount(value);
		}
	};

	const isButtonEnabled = () => {
		const emptyFields = [];
		if (!debt) {
			emptyFields.push('Deudor');
		}
		if (!debtDue) {
			emptyFields.push('cuota');
		}
		if(!date) {
			emptyFields.push('Fecha');
		} 
		if (!amount || (amount <= 0)) {
			emptyFields.push('Monto');
		}
		return emptyFields.length === 0;
	};
	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{(loadingCreate || loadingFetch || uploading) ? (
					<LoadingBox></LoadingBox>
				) : (
					errorFetch ? (
						<MessageBox variant='danger'>{errorFetch}</MessageBox>
					) : null
				)}	
				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Agregar Cobro</title>
					</Helmet>
					<h1>
						<MdLibraryAdd></MdLibraryAdd>Agregar Cobro
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Form.Group className='mb-3 col-6 form-group required'>
								<Form.Label>Deudor</Form.Label>
								<Form.Select disabled value={debt._id} onChange={(e) => setDebt(e.target.value)}>
									<option key={0}>Seleccione una deuda</option>
									{debts.map((debt) => {
										return (
											<option key={debt._id} value={debt._id}>
												{debt.owner ? debt.customId : debt.customId}
											</option>
										);
									})}
								</Form.Select>
							</Form.Group>
							<Form.Group className='mb-3 col-6 form-group required'>
								<Form.Label>Cuota Deuda</Form.Label>
								<CustomTypeahead
									id='debts'
									labelKey={(option) => `${option.customId}`}
									onChange={handleDebtDueChange}
									options={generatedDues ? generatedDues.filter((due) => !due.paid) : []}
									placeholder='Buscar cuota...'
									linkToCreate=''
									newOption={null}
									newSelectionPrefix={null}
								/>
								{isDebtDueInvalid && <div style={{ color: 'red' }}>invalido</div>}
							</Form.Group>
							<Form.Group className='mb-3 col-6 form-group required' controlId='amount'>
								<Form.Label>Monto</Form.Label>
								<Form.Control
									type='number'
									defaultValue={amount}
									onChange={(e) => handleAmountChange(e)}
									min={0}
									onInput={(e) => {
										const inputValue = parseFloat(e.target.value);
										const maxValue = debtDue ? unpaidAmount : null;

										if (maxValue !== null && inputValue > maxValue) {
											e.target.value = maxValue; // Set the value to the maximum allowed value
										}
									}}
								/>
							</Form.Group>
							<Form.Group className='mb-3 col-6 form-group required' controlId='date'>
								<Form.Label>Fecha</Form.Label>
								<Form.Control type='date' onChange={(e) => setDate(e.target.value)}></Form.Control>
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-3' controlId='currency'>
								<GenericDropdown
									label='Moneda'
									items={currencies}
									selected={currency}
									onChange={(e) => setCurrency(e.value)}
								/>
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-6' controlId='folder'>
								<Form.Label>Carpeta</Form.Label>
								<Form.Control
									onChange={(e) => setFolder(e.target.value)}
									value={folder ? folder : null}
								></Form.Control>
							</Form.Group>
							<Row>
								<Form.Group className='col-10' controlid='comprobante'>
									<Form.Label>Comprobante</Form.Label>
									<Form.Control
										type='file'
										required
										onChange={(e) => setComprobante(e.target.files)}
									></Form.Control>
									<Form.Control.Feedback type='invalid'>Seleccione Archivo</Form.Control.Feedback>
								</Form.Group>
								<Button
									className='col-2'
									style={{ height: '30px', marginTop: '32px', padding: '0' }}
									onClick={() => uploadComprobante(comprobante)}
								>
									subir
								</Button>
							</Row>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Observaciones</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									rows={10}
									onChange={(e) => setObservations(e.target.value)}
								></Form.Control>
							</Form.Group>
						</div>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							{!isButtonEnabled() ? (
								<OverlayTrigger
									placement='right'
									overlay={
										<Tooltip id='tooltip-payment'>
											Para ingresar cobro debe completar los campos obligatorios: Deudor,Cuota, Monto y Fecha
										</Tooltip>
									}
								>
									<Col>
										<Button className='guardar' onClick={addPaymentHandler} disabled>
											Agregar
										</Button>
									</Col>
								</OverlayTrigger>
							) : (
								<Col>
									<Button className='guardar' onClick={addPaymentHandler} disabled={false}>
										Agregar
									</Button>
								</Col>
							)}


						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default NewPaymentNew;
