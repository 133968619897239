import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';

function AccountInfo({ account }) {


	const types = ['Persona', 'Empresa'];
	const currencies = ['USD', 'UYU'];
	const [selectedType, setSelectedType] = useState();
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [address, setAddress] = useState();
	const [phone, setPhone] = useState();
	const [folder, setFolder] = useState();
	const [rut, setRut] = useState();
	const [selectedCurrency, setSelectedCurrency] = useState();

	const [observations, setObservations] = useState();

	useEffect(() => {
		setSelectedType(account.type);
		setName(account.name);
		setEmail(account.email);
		setAddress(account.address);
		setPhone(account.phone);
		setFolder(account.folder);
		setRut(account.rut);
		setSelectedCurrency(account.currency);
		setObservations(account.observations);
	}, [account]);

	return (
		<div>
			<div className='container admin-con align-items-center'>
				<Helmet>
					<title></title>
				</Helmet>
				<h1 className='my-3'></h1>
				<Form onSubmit={(e) => e.preventDefault()}>
					<div className='row align-items-center'>
						<Row className='mb-3'>
							<Form.Group className='mb-3 col-4 form-group required'>
								<Form.Label>Tipo</Form.Label>
								<Form.Select as='select' value={selectedType} disabled>
									<option key={0}>Elige Tipo</option>
									{types.map((type, index) => {
										return (
											<option key={index} value={type}>
												{type}
											</option>
										);
									})}
								</Form.Select>
							</Form.Group>
						</Row>
						{selectedType === 'Persona' && (
							<>
								<Row className='mb-3'>
									<Form.Group as={Col} className='mb-3 col-6 form-group required' controlId='name'>
										<Form.Label>Nombre</Form.Label>
										<Form.Control
											onChange={(e) => setName(e.target.value)}
											defaultValue={name}
											disabled
										></Form.Control>
									</Form.Group>
									<Form.Group as={Col} className='mb-3 col-6 form-group' controlId='email'>
										<Form.Label>Email</Form.Label>
										<Form.Control
											onChange={(e) => setEmail(e.target.value)}
											defaultValue={email}
											disabled
										></Form.Control>
									</Form.Group>
								</Row>
								<Row>
									<Form.Group className='mb-3 col-6' controlId='address'>
										<Form.Label>Direccion</Form.Label>
										<Form.Control
											onChange={(e) => setAddress(e.target.value)}
											defaultValue={address}
											disabled
										></Form.Control>
									</Form.Group>
									<Form.Group as={Col} className='mb-3 col-6' controlId='phone'>
										<Form.Label>Telefono</Form.Label>
										<Form.Control
											onChange={(e) => setPhone(e.target.value)}
											defaultValue={phone}
											disabled
										></Form.Control>
									</Form.Group>
									<Form.Group as={Col} className='mb-3 col-12' controlId='folder'>
										<Form.Label>Carpeta</Form.Label>
										<Form.Control
											onChange={(e) => setFolder(e.target.value)}
											defaultValue={folder}
											disabled
										></Form.Control>
									</Form.Group>
									<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
										<Form.Label>Observaciones</Form.Label>
										<Form.Control
											type='text'
											as='textarea'
											rows={10}
											onChange={(e) => setObservations(e.target.value)}
											defaultValue={observations}
											disabled
										></Form.Control>
									</Form.Group>
								</Row>
							</>
						)}
						{selectedType === 'Empresa' && (
							<>
								<Row className='mb-3'>
									<Form.Group as={Col} className='mb-3 col-6 form-group required' controlId='name'>
										<Form.Label>Nombre</Form.Label>
										<Form.Control
											onChange={(e) => setName(e.target.value)}
											defaultValue={name}
											disabled
										></Form.Control>
									</Form.Group>
									<Form.Group as={Col} className='mb-3 col-6 form-group required' controlId='rut'>
										<Form.Label>Rut</Form.Label>
										<Form.Control
											onChange={(e) => setRut(e.target.value)}
											defaultValue={rut}
											disabled
										></Form.Control>
									</Form.Group>
									<Form.Group as={Col} className='mb-3 col-6 form-group' controlId='email'>
										<Form.Label>Email</Form.Label>
										<Form.Control
											onChange={(e) => setEmail(e.target.value)}
											defaultValue={email}
											disabled
										></Form.Control>
									</Form.Group>
									<Form.Group className='mb-3 col-6' controlId='address'>
										<Form.Label>Direccion</Form.Label>
										<Form.Control
											onChange={(e) => setAddress(e.target.value)}
											defaultValue={address}
											disabled
										></Form.Control>
									</Form.Group>
									<Form.Group as={Col} className='mb-3 col-6' controlId='phone'>
										<Form.Label>Telefono</Form.Label>
										<Form.Control
											onChange={(e) => setPhone(e.target.value)}
											defaultValue={phone}
											disabled
										></Form.Control>
									</Form.Group>
									<Form.Group as={Col} className='mb-3 col-6' controlId='currency'>
										<Form.Label>Moneda</Form.Label>
										<Form.Select as='select' value={selectedCurrency} disabled>
											<option key={0}>Elige Moneda</option>
											{currencies.map((currency, index) => {
												return (
													<option key={index} value={currency}>
														{currency}
													</option>
												);
											})}
										</Form.Select>
									</Form.Group>
									<Form.Group as={Col} className='mb-3 col-12' controlId='folder'>
										<Form.Label>Carpeta</Form.Label>
										<Form.Control
											onChange={(e) => setFolder(e.target.value)}
											defaultValue={folder}
											disabled
										></Form.Control>
									</Form.Group>
									<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
										<Form.Label>Observaciones</Form.Label>
										<Form.Control
											type='text'
											as='textarea'
											rows={10}
											defaultValue={observations}
											disabled
											onChange={(e) => setObservations(e.target.value)}
										></Form.Control>
									</Form.Group>
								</Row>
							</>
						)}
						{/* {mainContact ? (
              <Form.Group className="mb-3 col-6">
                <Form.Label>Contacto Principal</Form.Label>
                <Form.Select value={mainContact._id} disabled>
                  {contacts.map((contact) => {
                    return (
                      <option key={contact._id} value={contact._id}>
                        {contact.name}
                      </option>
                    );
                  })}
                  <option key='blankChoice' hidden value> Elige al Contacto Principal </option>
                </Form.Select>
              </Form.Group>) : (
              <Form.Group className="mb-3 col-6">
                <Form.Label>Contacto Principal</Form.Label>
                <Form.Select value={mainContact} disabled>
                  {contacts.map((contact) => {
                    return (
                      <option key={contact._id} value={contact._id}>
                        {contact.name}
                      </option>
                    );
                  })}
                  <option key='blankChoice' hidden value> No tiene Contacto Principal </option>
                </Form.Select>
              </Form.Group>)} */}
					</div>
				</Form>
			</div>
		</div>
	);
}
export default AccountInfo;
