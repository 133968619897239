import React, { useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import BillService from '../../services/bill.service';
import { getError } from '../../utils';

import DynamicListTable from '../../components/DynamicListTable';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, bills: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function AssetBills() {
	const [{ loading, error, bills, successDelete }, dispatch] = useReducer(reducer, {
		bills: [],
		loading: true,
		error: '',
	});

	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await BillService.listBillsForAsset(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
				console.error(err);
				toast.error('No se pudo obtener lista');
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		}
		fetchData();
	}, [successDelete]);

	async function deletePaymentHandler(bill) {
		if (window.confirm('Seguro desea eliminar el gasto?')) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await BillService.delete(bill._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('gasto Eliminado');
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
				console.error(getError(err));
				toast.error('Error al eliminar acreedor');
			}
		}
	}

	async function verComprobante(bill) {
		if (bill.comprobante) {
			window.open(bill.comprobante.fileLink, '_blank', 'noopener,noreferrer');
		}
	}
	async function editPaymentHandler(bill) {
		navigate(`/AdminScreen/bills/editBill/${bill._id}`);
	}
	// async function newPaymentHandler() {
	// 	navigate('/AdminScreen/newBill');
	// }
	// async function confirmPayment(bill) {
	// 	navigate(`/AdminScreen/bills/confirmPayment/${bill._id}`);
	// }

	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Pagos</title>
			</Helmet>

			<div className='container admin-con'>
				<div className='row adminProdBtnRow align-items-center'>
					{/* <button className='btn btn-info btn-prod-admin'><FaFilter></FaFilter>Filtrar</button> */}
					{/* <button className='btn btn-danger btn-prod-admin'><Link to='/AdminScreen/AgregarProducto'><FaPlusCircle></FaPlusCircle>Agregar Productos</Link></button> */}
				</div>
				<div></div>
				<DynamicListTable
					data={bills}
					loading={loading}
					error={error}
					actionButtons={{
						editHandler: editPaymentHandler,
						deleteHandler: deletePaymentHandler,
						seeAttachment: verComprobante,
					}}
					dataName='bills'
					showCheckboxColumn={false}
					links={true}
					frontPaginator={true}
					exportOption={true}
				/>
			</div>
		</div>
	);
}

export default AssetBills;
