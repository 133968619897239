import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Helmet } from 'react-helmet-async';
import { MdLibraryAdd } from 'react-icons/md';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomTypeahead from '../../components/CustomTypeahead';
import FormatedNumberInput from '../../components/FormatedNumberInput';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import GenericDropdown from '../../components/SelectDropdown';
import accountService from '../../services/account.service';
import AssetService from '../../services/asset.service';
import AssetSaleService from '../../services/assetSale.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return { ...state, asset: action.payload, loadingFetch: false };
	case 'FETCH_FAIL':
	case 'FETCH_ASSETS_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_ASSETS_SUCCESS':
		return { ...state, assets: action.payload, loadingFetch: false };
	case 'FETCH_ASSETS_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'FETCH_ACCOUNTS_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_ACCOUNTS_SUCCESS':
		return { ...state, accounts: action.payload, loadingFetch: false };
	case 'FETCH_ACCOUNTS_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function AddAssetSale() {
	const navigate = useNavigate();
	const currencies = ['USD', 'UYU'];
	const [currency, setCurrency] = useState();
	const [amount, setAmount] = useState(0);
	const [document, setDocument] = useState();
	const [purchaser, setPurchaser] = useState();
	const [initialPayment, setInitialPayment] = useState(0);
	const [uploading, setUploading] = useState(false);
	const [setDisabledOwner, disabledOwner] = useState(false);
	const [isInvalid, setIsInvalid] = useState(false);
	const [disabledAsset, setDisabledAsset] = useState(false);
	const [asset, setAsset] = useState();
	const [account, setAccount] = useState();
	const [multipleLots, setMultipleLots] = useState();
	const [lot, setLot] = useState();

	const [{ loadingFetch, loadingCreate, accounts, assets ,errorFetch}, dispatch] = useReducer(reducer, {
		accounts: [],
		assets: [],
		loadingFetch: true,
		loadingCreate: false,
		errorFetch: '',
	});
	const params = useParams();
	const { id } = params;
	const location = useLocation();
	const route = location.pathname;

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_ASSETS_REQUEST' });
			try {
				const result = await AssetService.list();
				if (route.includes('asset')) {
					const selectedAsset = result.data.filter((assetData) => assetData._id === id);
					setAsset(selectedAsset[0]); //se agrega[0]
					setDisabledAsset(true);
					setAccount(result.data.account);
					setMultipleLots(selectedAsset[0].multipleLots);
					if (selectedAsset[0].multipleLots === false) {
						setLot(selectedAsset[0].lot);
					}
				}
				dispatch({ type: 'FETCH_ASSETS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_ASSETS_FAIL', payload: error.message });
			}
		};

		const fetchAccountsData = async () => {
			dispatch({ type: 'FETCH_ACCOUNTS_REQUEST' });
			try {
				const result = await accountService.list();
				if (route.includes('account')) {
					const selectedAccount = result.data.filter(
						(accountData) => accountData._id === account._id,
					);
					setPurchaser(selectedAccount[0]);
					setDisabledOwner(true);
					setCurrency(selectedAccount[0].currency);
				}
				dispatch({ type: 'FETCH_ACCOUNTS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_ACCOUNTS_FAIL', payload: error.message });
			}
		};
		fetchData();
		fetchAccountsData();
	}, []);

	const addAssetSaleHandler = async () => {
		if (!lot) {
			toast.error('Debe completar el campo padrón.');
			return;
		}
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await AssetSaleService.create({
				amount,
				asset,
				currency,
				document,
				purchaser,
				initialPayment,
				lot,
			});
			dispatch({ type: 'CREATE_SUCCESS' });
			toast.success('Venta de activo creada');
			navigate(-1);
		} catch (ex) {
			dispatch({ type: 'CREATE_FAIL' });
			toast.error(getError(ex));
		}
	};

	const uploadDocument = async () => {
		setUploading(true);
		if (document) {
			const file = document[0];
			const formData = new FormData();
			let r = Math.random().toString(36).substring(7);
			formData.append('file', file, file.name.replaceAll(' ') + r);
			formData.append('documentId', document._id);
			try {
				const response = await AssetSaleService.uploadDocument(formData);
				setDocument(response.data);
				setUploading(false);
				toast.success('Archivo Subido');
			} catch (ex) {
				setUploading(false);
				toast.error(getError(ex));
			}
		}
		{
			toast.error('Debe seleccionar un archivo');
		}
	};
	const handleSelectedCurrency = (e) => {
		setCurrency(e.value);
	};
	const handleAccountChange = (selected) => {
		if (!selected || selected.length === 0) {
			const selectedAccount = selected[0];
			const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
			setIsInvalid(!isValid);
			setPurchaser('');
			setCurrency('');
		} else {
			const selectedAccount = selected[0];
			const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
			setIsInvalid(!isValid);
			setPurchaser(selectedAccount);
			setCurrency(selectedAccount.currency);
		}
	};

	const handleAssetChange = (selected) => {
		const selectedAsset = selected[0];
		const isValid = selectedAsset ? assets.includes(selectedAsset) : false;
		setIsInvalid(!isValid);
		setAsset(selected[0]);
		if (selected[0]) {
			setMultipleLots(selected[0].multipleLots);
			if (!selected[0].multipleLots) {
				setLot(selected[0].lot);
			}
		}
	};
	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{(loadingCreate || loadingFetch || uploading) ? (
					<LoadingBox></LoadingBox>
				) : (
					errorFetch ? (
						<MessageBox variant='danger'>{errorFetch}</MessageBox>
					) : null
				)}				<Helmet>
					<title>Venta de activo</title>
				</Helmet>
				<div className='container admin-con align-items-center'>
					<h1>
						<MdLibraryAdd></MdLibraryAdd>Venta de activo
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<Row className='mb-3'>
							<Form.Group as={Col} className='mb-3 col-6 form-group required' controlId='name'>
								<Form.Label>Monto</Form.Label>
								<FormatedNumberInput
									type='number'
									onChange={(e) => setAmount(e.target.value)}
									min={0}
								></FormatedNumberInput>
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-6' controlId='asset'>
								<Form.Label>Activo</Form.Label>
								<CustomTypeahead
									id='assets'
									labelKey={(option) => `${option.name}`}
									onChange={handleAssetChange}
									options={assets}
									placeholder='Buscar Activo... '
									selected={asset ? [asset] : null} // se agrega []
									disabled={disabledAsset}
									isInvalid={isInvalid}
									linkToCreate='/AdminScreen/newAsset'
									newOption='nuevo Activo Fijo'
								/>
								{isInvalid && <div style={{ color: 'red' }}>Activo invalido</div>}
							</Form.Group>
							<Form.Group className='mb-3 col-6' controlId='periodicity'>
								<Form.Label>Padrón</Form.Label>
								<Form.Control
									type='text'
									defaultValue={lot}
									disabled={!multipleLots}
									onChange={(e) => setLot(e.target.value)}
								></Form.Control>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group className='mb-3 col-8 form-group required'>
								<Form.Label>Comprador</Form.Label>
								<CustomTypeahead
									id='accounts'
									labelKey={(option) => `${option.name}`}
									onChange={handleAccountChange}
									options={accounts}
									disabled={disabledOwner}
									selected={purchaser ? [purchaser] : null}
									placeholder='Buscar Cuenta... '
									isInvalid={isInvalid}
									linkToCreate='/AdminScreen/newAccount'
									newOption='nueva cuenta'
								/>
								{isInvalid && <div style={{ color: 'red' }}>Cuenta invalida</div>}
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-4' controlId='currency'>
								<GenericDropdown
									label='Moneda'
									items={currencies}
									selected={currency}
									onChange={handleSelectedCurrency}
								/>
							</Form.Group>
							<Form.Group
								as={Col}
								className='mb-3 col-6 form-group required'
								controlId='initialPayment'
							>
								<Form.Label>Entrega Inicial</Form.Label>
								<FormatedNumberInput
									type='number'
									onChange={(e) => setInitialPayment(e.target.value)}
									min={0}
									max={amount}
								></FormatedNumberInput>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group className='col-4 mb-3' controlid='documento'>
								<Form.Label>Documento</Form.Label>
								<Form.Control
									type='file'
									required
									onChange={(e) => setDocument(e.target.files)}
								></Form.Control>
								<Form.Control.Feedback type='invalid'>Seleccione Archivo</Form.Control.Feedback>
							</Form.Group>
							<Button
								className='col-2'
								style={{ height: '30px', marginTop: '32px', padding: '0' }}
								onClick={() => uploadDocument(document)}
							>
								subir
							</Button>
						</Row>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={addAssetSaleHandler}>
									Agregar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default AddAssetSale;
