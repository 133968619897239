import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const FormatedNumberInput = ({ defaultValue, value, onChange, min, max, noDecimal, disabled }) => {
	const [isFocused, setIsFocused] = useState(false);

	const handleKeyDown = (e) => {
		if (e.key === '-' || e.key === 'e' || e.key === '+') {
			e.preventDefault();
		}
		if (
			noDecimal &&
			(e.key === '-' || e.key === 'e' || e.key === '+' || e.key === '.' || e.key === ',')
		) {
			e.preventDefault();
		}
	};

	const handleFocus = () => {
		setIsFocused(true);
	};

	const handleBlur = () => {
		setIsFocused(false);
	};

	return (
		<Form.Control
			type='number'
			value={value}
			defaultValue={defaultValue}
			onChange={onChange}
			onKeyDown={handleKeyDown}
			min={min}
			max={max}
			disabled={disabled}
			onFocus={handleFocus}
			onBlur={handleBlur}
			onWheel={(e) => {
				if (isFocused) {
					e.currentTarget.blur();
				}
			}}
		/>
	);
};
export default FormatedNumberInput;
