import React, { useEffect, useReducer } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { MdAddCircleOutline, MdOtherHouses, MdOutlineAccountTree } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingBox from '../../components/LoadingBox';
import AssetSaleService from '../../services/assetSale.service';
import AssetSaleInfo from '../AssetSales/AssetSaleInfo';
import AssetSalesDebts from '../AssetSales/AssetSalesDebts';
import './AssetSaleDetail.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, assetSale: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

export default function AssetSaleDetail() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ assetSale, loading }, dispatch] = useReducer(reducer, {
		assetSale: {},
		loading: true,
		error: '',
	});

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await AssetSaleService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, []);

	//   useEffect(() => {
	//     const fetchData = async () => {
	//       dispatch({ type: "FETCH_REQUEST" });
	//       try {
	//         const result = await AssetSaleService.getContractsForAccount(id);
	//         dispatch({ type: "FETCH_SUCCESS", payload: result.data });
	//       } catch (error) {
	//         dispatch({ type: "FETCH_FAIL", payload: getError(error) });
	//         console.error(getError(error));
	//         toast.error("No se pudo obtener lista");
	//       }
	//     };
	//     fetchData();
	//   }, [id]);

	//   async function editAccountHandler() {
	//     navigate(`/AdminScreen/contracts/editContract/${contract._id}`)
	//   }

	async function newDebtHandler(e) {
		navigate(`/AdminScreen/assetSales/assetSale/${assetSale._id}/newDebt`);
		e.stopPropagation();
	}

	return (
		<>
			<div className='container admin-con align-items-center'>
				{loading && <LoadingBox></LoadingBox>}
				<Row style={{ margin: '0 0 2.5% 0' }}>
					<Col style={{ alignSelf: 'center' }}>
						<h2>
							<MdOtherHouses></MdOtherHouses>Venta:{assetSale.customId}
						</h2>
					</Col>
					<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							style={{ backgroundColor: 'transparent!important' }}
							id='back-btn'
							className='btn m-2'
							value='back'
							onClick={() => navigate(-1)}
						>
							Atras
						</Button>
						{/* <Button className="btn btn-dark m-2" value="Crear Cuenta" onClick={() => editAccountHandler()}>Editar Contrato</Button> */}
					</Col>
				</Row>
				<div className='container'>
					<div className='jumbotron'>
						<AssetSaleInfo assetSale={assetSale}></AssetSaleInfo>
					</div>
					<div className='jumbotron related-data'>
						<h2 style={{ marginBottom: '2.5%' }}>
							<MdOutlineAccountTree></MdOutlineAccountTree>Datos Relacionados
						</h2>
						<Accordion>
							<Accordion>
								<Accordion.Item eventKey='0'>
									<Accordion.Header>
										Deudas
										<Button id='add-btn' onClick={(e) => newDebtHandler(e)}>
											<MdAddCircleOutline></MdAddCircleOutline>Agregar
										</Button>
									</Accordion.Header>
									<Accordion.Body>
										<AssetSalesDebts></AssetSalesDebts>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Accordion>
					</div>
				</div>
			</div>
		</>
	);
}
