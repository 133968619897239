import React, { useEffect, useReducer, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import { FaFilter } from 'react-icons/fa';
import { MdClose, MdOutlineWindow } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DynamicListTable from '../../components/DynamicListTable';
import FilterForm from '../../components/FilterForm';
import ListButtons from '../../components/ListButtons';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import SearchBox from '../../components/SearchBox';
import AssetService from '../../services/asset.service';
import BillService from '../../services/bill.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			assets: action.payload.assets,
			page: action.payload.page,
			pages: action.payload.pages,
			countAssets: action.payload.countAssets,
			pageSize: action.payload.pageSize,
			loading: false,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function AssetList() {
	const [{ loading, error, assets, successDelete, pageSize, countAssets }, dispatch] =
		useReducer(reducer, {
			assets: [],
			loading: true,
			error: '',
			countAssets: 0,
			pageSize: 0,
		});

	const [loadingInitial, setLoadingInitial] = useState(true);
	const navigate = useNavigate();
	const { search } = useLocation();
	const sp = new URLSearchParams(search); // /search?category=Shirts
	const column = sp.get('column') || 'customId';
	const direction = sp.get('direction') || 'asc';
	const currency = sp.get('currency') || 'all';
	const priceTo = sp.get('priceTo') || '';
	const priceFrom = sp.get('priceFrom') || 0;
	const query = sp.get('query') || 'all';
	const page = sp.get('page') || 1;
	const [filtered, setFiltered] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const myTitle = (
		<h1
			className='d-flex justify-content-start align-items-center list-title-dropdown'
			style={{ padding: '4px 12px' }}
		>
			Todos los Activos Fijos
		</h1>
	);

	const buttonItems = [
		{
			label: 'Agregar Activo',
			onClick: () => newDebtHandler(),
		},
		{
			label: 'Agregar Gasto',
			onClick: () => newBillHandler(),
		},
		{
			label: 'Agregar Venta',
			onClick: () => newAssetSaleHandler(),
		},
	];

	const handleDropdownToggle = () => {
		setIsOpen(!isOpen);
	};

	const [filterOptions, setFilterOptions] = useState({
		formType: 'ActivosFijos',
		priceFrom: priceFrom,
		priceTo: priceTo,
		currency: currency,
		query: query,
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFilterOptions((prevOptions) => ({
			...prevOptions,
			[name]: value,
			formType: filterOptions.formType,
		}));
	};

	const handleApplyFilter = () => {
		const { priceFrom, priceTo, currency } = filterOptions;
		const filter = {
			priceFrom,
			priceTo,
			currency,
			page: 1,
			query,
		};
		const filterUrl = getFilterUrl(filter);
		navigate(filterUrl);
		setFiltered(true);
	};
	useEffect(() => {
		handleApplyFilter();
	}, [filtered]);

	const handleClearFilter = () => {
		setFilterOptions({
			formType: 'ActivosFijos',
			priceFrom: 0,
			priceTo: '',
			currency: 'all',
			query: 'all',
		});
		setFiltered(false);
	};

	const onPageChange = (page) => {
		navigate(getFilterUrl({ page: page }));
	};

	const handleSort = (column) => {
		const newSortDirection = column === column && direction === 'desc' ? 'asc' : 'desc';
		const newURL = `/AdminScreen/assets/search?page=${page}&query=${query}&column=${column}&direction=${newSortDirection}&currency=${currency}&priceFrom=${priceFrom}&priceTo=${priceTo}`;
		navigate(newURL);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await AssetService.search(
					`page=${page}&query=${query}&column=${column}&direction=${direction}&currency=${currency}&priceFrom=${priceFrom}&priceTo=${priceTo}`,
				);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
				console.error(error);
				toast.error('No se pudo obtener lista');
			} finally {
				setLoadingInitial(false);
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete, column, direction, query, priceFrom, priceTo, currency, page]);

	async function deleteRelatedBills(asset) {
		try {
			dispatch({ type: 'DELETE_REQUEST' });
			await BillService.deleteBilllsForAsset(asset._id);
			dispatch({ type: 'DELETE_SUCCESS' });
		} catch (err) {
			dispatch({ type: 'DELETE_FAIL' });
			console.error(getError(error));
			toast.error('Error borrando cuentas');
		}
	}

	async function newBillHandler() {
		navigate('/AdminScreen/newBill');
	}
	async function newAssetSaleHandler() {
		navigate('/AdminScreen/newAssetSale');
	}

	async function deleteDebtHandler(asset) {
		if (
			window.confirm(
				'Seguro desea eliminar el activo? Si elimina el activo, seran eliminados todos los gastos relacionados',
			)
		) {
			deleteRelatedBills(asset);
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await AssetService.delete(asset._id);
				handleClearFilter();
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Activo Eliminado');
			} catch (err) {
				dispatch({ type: 'DELETE_FAIL' });
				console.error(getError(error));
				toast.error('Error al eliminar cuenta');
			}
		}
	}

	// async function verEscritura(asset) {
	// 	if (asset.escritura) {
	// 		window.open(asset.escritura.fileLink, '_blank', 'noopener,noreferrer');
	// 	}
	// }

	async function editDebtHandler(asset) {
		navigate(`/AdminScreen/assets/editAsset/${asset._id}`);
	}
	async function seeDetails(asset) {
		navigate(`/AdminScreen/assets/asset/${asset._id}`);
	}
	async function newDebtHandler() {
		navigate('/AdminScreen/newAsset');
	}
	const getFilterUrl = (filter) => {
		const { query, priceFrom, priceTo, currency } = filterOptions;
		const filterQuery = filter.query || query;
		const filterPage = filter.page || 1;
		const filterPriceFrom = priceFrom || 0;
		const filterPriceTo = priceTo || '';
		const filterCurrency = currency || '';

		const params = new URLSearchParams();
		params.append('page', filterPage);
		params.append('query', filterQuery);
		params.append('priceFrom', filterPriceFrom);
		params.append('priceTo', filterPriceTo);
		params.append('currency', filterCurrency);

		return `/AdminScreen/assets/search?${params.toString()}`;
	};
	return loadingInitial ? (
		<LoadingBox loading={loadingInitial}></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Activos Fijo</title>
			</Helmet>
			<div className='admin-con'>
				<Row>
					<Col>
						<Row className='d-flex justify-content-center'>
							<Row>
								<h2 className='d-flex justify-content-start align-items-center list-title'>
									<MdOutlineWindow></MdOutlineWindow>Activos Fijos
								</h2>
							</Row>
							<Row className='title-btns-row'>
								<div
									className='btn title-dropdown text-align-start p-0'
									style={{ width: 'fit-content' }}
									title={myTitle}
								>
									{myTitle}
								</div>
								<ListButtons buttonItems={buttonItems} />
							</Row>
							<Row className='d-flex justify-content-end align-items-center list-title'>
								{query !== 'all' ? (
									<Col>
										Resultados para: {query}
										<Link
											className='btn-primary-search'
											as={Link}
											to={getFilterUrl({ query: 'all' })}
										>
											<MdClose />
										</Link>
									</Col>
								) : (
									''
								)}
								<Col className='col-lg-3 d-flex justify-content-end'>
									<SearchBox getFilterUrl={getFilterUrl} navigate={navigate} />
								</Col>
								<Col className='col-lg-1 d-flex justify-content-end w-auto'>
									<Button
										className={
											isOpen
												? 'btn btn-dark btn-dark-open  btn-sm m-2'
												: 'btn btn-dark btn-dark  btn-sm m-2'
										}
										onClick={handleDropdownToggle}
										aria-expanded={isOpen}
									>
										<FaFilter />
									</Button>
								</Col>
							</Row>
						</Row>
					</Col>
				</Row>
				<div className={isOpen ? 'table-container filter-opened' : 'table-container'}>
					{isOpen && (
						<FilterForm
							filterOptions={filterOptions}
							isOpen={isOpen}
							handleDropdownToggle={handleDropdownToggle}
							handleInputChange={handleInputChange}
							clearFilter={() => handleClearFilter('ActivosFijos')}
							handleApplyFilter={() => handleApplyFilter('ActivosFijos')}
						/>
					)}
					{query !== 'all' ? (
						<Col>
							Resultados para: {query}
							<Link className='btn-primary-search' as={Link} to={getFilterUrl({ query: 'all' })}>
								<MdClose />
							</Link>
						</Col>
					) : (
						''
					)}
					<DynamicListTable
						data={assets}
						loading={loading}
						error={error}
						count={countAssets}
						onPageChange={onPageChange}
						page={parseInt(page)}
						pageSize={pageSize}
						handleSort={handleSort}
						sortColumn={column}
						direction={direction}
						actionButtons={{
							seeDetails: seeDetails,
							editHandler: editDebtHandler,
							deleteHandler: deleteDebtHandler,
						}}
						dataName='assets'
						showCheckboxColumn={false}
						links={true}
					/>
				</div>
			</div>
		</div>
	);
}

export default AssetList;
