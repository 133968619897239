import axiosInstance from '../RestClient';

const BASE_URI = '/contracts';
class ContractService {
	async list() {
		const uri = `${BASE_URI}/`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async getDetails(recordId) {
		const uri = `${BASE_URI}/${recordId}`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async create(payload) {
		const response = await axiosInstance.post(`${BASE_URI}/`, payload);
		return response;
	}

	async edit(recordId, payload) {
		const response = await axiosInstance.put(`${BASE_URI}/${recordId}`, payload);
		return response;
	}

	async close(recordId, payload) {
		const response = await axiosInstance.put(`${BASE_URI}/closingDate/${recordId}`, payload);
		return response;
	}

	async delete(recordId) {
		const uri = `${BASE_URI}/${recordId}`;
		const response = await axiosInstance.delete(uri);
		return response;
	}

	async getDebtsForContract(contractId) {
		const uri = `${BASE_URI}/contractDebts/${contractId}`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async getContractsForAccount(contractId) {
		const uri = `${BASE_URI}/mine/${contractId}`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async getContractsForProperty(propertyId) {
		const uri = `${BASE_URI}/minecontract/${propertyId}`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async updateContractsInBulk(contractIList, payload) {
		const response = await axiosInstance.put(`${BASE_URI}/${contractIList}`, payload);
		return response;
	}

	async search(query) {
		const uri = `${BASE_URI}/search?${query}`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async updateContractsByIPC(contractIds, payload) {
		const response = await axiosInstance.put(`${BASE_URI}/ids/${contractIds}`, payload);
		return response;
	}

	async closeContractsByID(contractIds, payload) {
		const response = await axiosInstance.put(`${BASE_URI}/closingDateIds/${contractIds}`, payload);
		return response;
	}
}
export default new ContractService();
