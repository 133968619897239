import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { MdAddCircleOutline, MdOutlineAccountTree, MdOutlineMoney } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingBox from '../../components/LoadingBox';
import creditService from '../../services/credit.service';
import { getError } from '../../utils';
import CreditDues from '../Credits/CreditDues';
import CreditInfo from '../Credits/CreditInfo';
import CreditPaymentsCredit from '../Credits/CreditPaymentsCredit';
import CreditWarranties from '../Credits/CreditWarranties';
import HistoryModal from '../../components/HistoryModal';
import './CreditDetails.css';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, credit: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

export default function CreditDetails() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ credit, loading }, dispatch] = useReducer(reducer, {
		credit: {},
		loading: true,
		error: '',
	});

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await creditService.getDetails(id);
				setExpiredCredit(
					result.data.closeDate
						? moment.utc(result.data.closeDate).isBefore(moment.utc())
						: result.data.endDate
							? moment.utc(result.data.endDate).isBefore(moment.utc())
							: false,
				);
				setAllDuesGenerated(
					result.data.generatedDebts && result.data.dues
						? result.data.generatedDebts.length >= result.data.dues
						: false,
				);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchData();
	}, [id]);
	async function editCreditHandler() {
		navigate(`/AdminScreen/credits/editCredit/${credit._id}`);
	}

	async function NewPaymentHandler(e) {
		navigate(`/AdminScreen/credits/credit/${credit._id}/newCreditPayment`);
		e.stopPropagation();
	}
	async function NewWarrantyHandler(e) {
		navigate(`/AdminScreen/credits/credit/${credit._id}/newWarranty`);
		e.stopPropagation();
	}
	const [expiredCredit, setExpiredCredit] = useState();
	const [allDuesGenerated, setAllDuesGenerated] = useState();
	const [showHistoryLog, setShowHistoryLog] = useState(false);

	const handleShowHistoryLog = () => {
		setShowHistoryLog(true);
	};

	const handleCloseHistoryLog = () => {
		setShowHistoryLog(false);
	};


	let nextExpirationDate = null;
	let lastExpirationDate;
	let generationDate = null;

	let periodicityNumMonths = 0;
	if (credit.periodicity === 'mensual') {
		periodicityNumMonths = 1;
	} else if (credit.periodicity === 'semestral') {
		periodicityNumMonths = 6;
	} else if (credit.periodicity === 'anual') {
		periodicityNumMonths = 12;
	} else if (credit.periodicity === 'otra') {
		periodicityNumMonths = credit.periodicityNumMonths;
	}

	let dueNumber = credit.generatedCredits ? Number(credit.generatedCredits.length) + 1 : 1;

	if (credit.generatedCredits && credit.generatedCredits.length > 0) {
		if (
			credit.generatedCredits.length > 0 &&
			credit.generatedCredits[credit.generatedCredits.length - 1].expiration
		) {
			credit.generatedCredits.sort((a, b) => moment.utc(a.expiration).diff(moment.utc(b.expiration)));

			lastExpirationDate = moment.utc(
				credit.generatedCredits[credit.generatedCredits.length - 1].expiration,
			);
		}
		nextExpirationDate = moment
			.utc(lastExpirationDate)
			.add(periodicityNumMonths, 'month')
			.format('DD-MM-YYYY');
		const nextExpirationMoment = moment.utc(nextExpirationDate, 'DD-MM-YYYY');
		generationDate = nextExpirationMoment.startOf('month').format('DD-MM-YYYY');
	} else if (credit.generatedCredits && credit.generatedCredits.length === 0 && credit.firstDueExpiration) {
		nextExpirationDate = moment.utc(credit.firstDueExpiration).format('DD-MM-YYYY');
		const nextExpirationMoment = moment.utc(nextExpirationDate, 'DD-MM-YYYY');
		generationDate = nextExpirationMoment.startOf('month').format('DD-MM-YYYY');
	}

	return (
		<>
			{loading && <LoadingBox></LoadingBox>}
			<div className='container admin-con align-items-center'>
				<Row style={{ margin: '0 0 2.5% 0' }}>
					<Col style={{ alignSelf: 'center' }}>
						<h2>
							<MdOutlineMoney></MdOutlineMoney>Pasividad:{credit.internalId}
						</h2>
						<Col>
							<span onClick={handleShowHistoryLog} style={{ cursor: 'pointer', textDecoration: 'underline' }} className="btn btn-link p-0">historial de cambios pasividad</span>
						</Col>
						<HistoryModal model="credit" show={showHistoryLog} handleClose={handleCloseHistoryLog} id={credit._id} isHistory={true}/>
					</Col>
					<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							style={{ backgroundColor: 'transparent!important' }}
							id='back-btn'
							className='btn m-2'
							value='back'
							onClick={() => navigate(-1)}
						>
							Atras
						</Button>
						<Button
							className='btn btn-dark m-2'
							value='Crear Cuenta'
							onClick={() => editCreditHandler()}
						>
							Editar Pasividad
						</Button>
					</Col>
				</Row>
				<div className='container'>
					<div className='jumbotron'>
						<CreditInfo credit={credit}></CreditInfo>
					</div>
					<div className='jumbotron related-data'>
						<h2 style={{ marginBottom: '2.5%' }}>
							<MdOutlineAccountTree></MdOutlineAccountTree>Datos Relacionados
						</h2>
						<Accordion>
							<Accordion>
								<Accordion.Item eventKey='0'>
									<Accordion.Header>
										Garantias
										<Button id='add-btn' onClick={(e) => NewWarrantyHandler(e)}>
											<MdAddCircleOutline></MdAddCircleOutline>Agregar
										</Button>
									</Accordion.Header>
									<Accordion.Body>
										<CreditWarranties></CreditWarranties>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey='1'>
									<Accordion.Header>
										Pagos
										<Button id='add-btn' onClick={(e) => NewPaymentHandler(e)}>
											<MdAddCircleOutline></MdAddCircleOutline>Agregar
										</Button>
									</Accordion.Header>
									<Accordion.Body>
										<CreditPaymentsCredit></CreditPaymentsCredit>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey='2'>
									<Accordion.Header>
										Cuotas
										<Row>
											<span className='m-3'>
												<small className='text-muted'>
													{expiredCredit
														? 'Pasividad vencida'
														: allDuesGenerated
															? 'Todas las cuotas fueron creadas'
															: `Próximo vencimiento ${nextExpirationDate}, cuota #${dueNumber} a generarse el ${generationDate}`}
												</small>
											</span>
										</Row>
									</Accordion.Header>
									<Accordion.Body>
										<CreditDues></CreditDues>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Accordion>
					</div>
				</div>
			</div>
		</>
	);
}
