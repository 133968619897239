import React, { useState, useRef } from 'react';
import { Row, Col, Table, Form, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import { showCurrency } from '../utils';
import { FaFileExcel } from 'react-icons/fa';
import Accordion from 'react-bootstrap/Accordion';
import * as XLSX from 'xlsx';

const GroupedTable = ({ data, dataName, totalGeneratedDebts }) => {
	const tableRef = useRef(null);
	const [show, setShow] = useState(false);
	const [exportName, setExportName] = useState('');

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	//columnMap to show table
	const columnMap = {
		contracts: {
			customId: 'Inquilino:Cuenta',
			amount: 'Monto',
			periodInit: 'Inicio',
			periodEnd: 'Fin',
			'owner.name': 'Inquilino',
			folder: 'Carpeta',
		},
		debts: {
			customId: 'Cuenta:Concepto',
			'owner.name': 'Deudor',
			capital: 'Capital',
			concept: 'Concepto',
			dues: 'Cuotas',
			amount: 'Monto cuota',
			date: 'Fecha',
			endDate: 'Fecha vto',
			periodicity: 'Periodicidad',
		},
		debtsDues: {
			date: 'Fecha',
			customId: 'Cuota',
			amount: 'Monto',
			expiration: 'Vencimiento',
			paid: 'Pago',
		},
		payments:{
			date:'Fecha de pago',
			amount:'Monto',
		},
		credits: {
			customId: 'Cuenta:Concepto',
			'owner.name': 'Acreedor',
			capital: 'Capital',
			concept: 'Concepto',
			dues: 'Cuotas',
			amount: 'Monto cuota',
			date: 'Fecha',
			endDate: 'Fecha vto',
			periodicity: 'Periodicidad',
		},
		creditDues: {
			date: 'Fecha',
			customId: 'Cuota',
			amount: 'Monto',
			expiration: 'Vencimiento',
			paid: 'Pago',
		},
		creditPayments:{
			date:'Fecha de pago',
			amount:'Monto',
		},
		accounts: {
			name: 'Nombre',
			type: 'Tipo',
			currency: 'moneda',
			folder: 'Carpeta',
		},
		bills: {
			customId: 'Id',
			name: 'Gasto',
			amount: 'Monto',
			expirationDate: 'Vencimiento',
			type: 'Tipo',
			periodicity: 'Periodicidad',
			paid: 'Pago?',
		},
	};

	const exportToXLS = () => {
		const wb = XLSX.utils.book_new();

		data.forEach((account) => {
			const ws_data = [];
			ws_data.push(['Estado Situacion de:', account.name]);

			if (account.debts && account.debts.length > 0) {
				//deudas title
				ws_data.push(['Deudas']);

				account.debts.forEach((debt) => {
					const columnHeadersDebts = Object.keys(columnMap['debts']).map(
						(column) => columnMap['debts'][column],
					);
					ws_data.push([...columnHeadersDebts]);

					const row = Object.keys(columnMap['debts']).map((column) =>
						formatValue(getNestedValue(debt, column)),
					);
					ws_data.push([...row]);

					if(debt.generatedDebts && debt.generatedDebts.length > 0){
						ws_data.push([' ', 'Cuotas']);
						const columnHeadersDues = Object.keys(columnMap['debtsDues']).map(
							(column) => columnMap['debtsDues'][column],
						);
						ws_data.push(['','',...columnHeadersDues]);}

					if(debt.generatedDebts && debt.generatedDebts.length > 0){
						debt.generatedDebts.forEach((due) => {
							const row = Object.keys(columnMap['debtsDues']).map((column) =>
								formatValue(getNestedValue(due, column)),
							);

							ws_data.push(['','Cuota',...row]);


							const paymentsRows = [];
	
							if (due.payments && due.payments.length > 0) {
								due.payments.forEach(payment => {
									const paymentRow = [
										'', 
										'Cobro', 
										formatValue(getNestedValue(payment, 'date')),
										formatValue(getNestedValue(payment, 'amount')), 
									];
									paymentsRows.push(paymentRow);
								});
								ws_data.push(['', 'Cobros']); // Payments header principal
								ws_data.push(['','','Fecha', 'Monto']); // Payments headers
								ws_data.push(...paymentsRows); 
								ws_data.push([]); 

							} 
						});}
				});
				ws_data.push([]);
			} else {
				ws_data.push(['No hay deudas vinculadas']);
			}
			if (account.credits && account.credits.length > 0) {
				ws_data.push(['Creditos']); //creditos title
				
				account.credits.forEach((credit) => {
					const columnHeadersCredits = Object.keys(columnMap['credits']).map(
						(column) => columnMap['credits'][column],
					);
					ws_data.push([...columnHeadersCredits]);
					const row = Object.keys(columnMap['credits']).map((column) =>
						formatValue(getNestedValue(credit, column)),
					);
					ws_data.push([...row]);

					if(credit.generatedCredits && credit.generatedCredits.length > 0){
						ws_data.push([' ', 'Cuotas']);
						const columnHeadersDues = Object.keys(columnMap['creditDues']).map(
							(column) => columnMap['creditDues'][column],
						);
						ws_data.push(['','',...columnHeadersDues]);}

					if(credit.generatedCredits && credit.generatedCredits.length > 0){
						credit.generatedCredits.forEach((due) => {
							const row = Object.keys(columnMap['creditDues']).map((column) =>
								formatValue(getNestedValue(due, column)),
							);
							ws_data.push(['','Cuota',...row]);

							const cpaymentsRows = [];

							if (due.creditPayments && due.creditPayments.length > 0) {
								due.creditPayments.forEach(creditPayment => {
									const paymentRow = [
										'', 
										'Pago', 
										formatValue(getNestedValue(creditPayment, 'date')),
										formatValue(getNestedValue(creditPayment, 'amount')), 
									];
									cpaymentsRows.push(paymentRow);
								});
								ws_data.push(['', 'Pagos']); // Payments header principal
								ws_data.push(['','','Fecha', 'Monto']); // Payments headers
								ws_data.push(...cpaymentsRows); 
								ws_data.push([]); 

							} 
						});}
				});
			} else {
				ws_data.push(['No hay creditos vinculadas']);
			}
			ws_data.push([]);
			if (account.assets && account.assets.length > 0) {
				ws_data.push(['Activos Fijos']);

				account.assets.forEach((asset, index) => {
					if (index === 0) {
						const columnHeaders = Object.keys(columnMap['bills']).map(
							(column) => columnMap['bills'][column],
						);
						ws_data.push(['Activo Fijo', 'Moneda', ...columnHeaders]);
					}
					asset.bills.forEach((bill) => {
						const row = Object.keys(columnMap['bills']).map((column) =>
							formatValue(getNestedValue(bill, column)),
						);
						ws_data.push([asset.customId, asset.currency, ...row]);
					});
				});
			} else {
				ws_data.push(['No hay activos vinculados']);
			}
			const ws = XLSX.utils.aoa_to_sheet(ws_data);
			XLSX.utils.book_append_sheet(wb, ws, account.name.replace(/[:\\/?[\]]/g, '_'));
		});
		XLSX.writeFile(
			wb,
			exportName
				? exportName.replace(/[:\\/?[\]]/g, '_') + '.xlsx'
				: 'Estado situacion ' + moment.utc().format('DD-MM-YYYY'),
		);
		handleClose();
	};

	const excludedColumns = ['credit.concept', 'credit.customId', 'debt.concept', 'debt.customId'];

	//to show with currency UYU:USD
	const currencyColumns = ['amount', 'capital'];

	//to show values like owner.name
	const getNestedValue = (item, column) => {
		const keys = column.split('.');
		let nestedValue = item;
		for (const key of keys) {
			if (nestedValue && nestedValue[key] !== 'undefined') {
				nestedValue = nestedValue[key];
			} else {
				return null;
			}
		}
		return nestedValue;
	};

	const formatValue = (value) => {
		if (value === true) {
			return 'Si';
		} else if (value === false) {
			return 'No';
		}

		const isValidDateFormat = moment.utc(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid();
		if (isValidDateFormat) {
			return moment.utc(value).format('DD-MM-YYYY');
		} else if (!value) {
			return 'N/A';
		} else {
			return value;
		}
	};

	return (
		<div className='container m-3'>
			<Row>
				<Col>
					<Button
						size={'sm'}
						className='mb-3'
						onClick={handleShow}
						style={{ backgroundColor: 'Green' }}
					>
						<FaFileExcel></FaFileExcel> Exportar
					</Button>
				</Col>
			</Row>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Exportar datos</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group as={Row} className='mb-3 align-items-center'>
						<Form.Label column md={6}>
							Nombre del archivo:
						</Form.Label>
						<Col>
							<Form.Control
								type='text'
								placeholder='Ingrese el nombre'
								value={exportName}
								onChange={(e) => setExportName(e.target.value)}
							/>
						</Col>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleClose}>
						Cancelar
					</Button>
					<Button variant='primary' onClick={exportToXLS}>
						Exportar XLS
					</Button>
				</Modal.Footer>
			</Modal>
			<Table style={{ display: 'none' }} ref={tableRef}>
				<thead>
					<tr>
						<td className='text-center' colSpan={12}>
							<h2>Estado Situación</h2>
						</td>
					</tr>
				</thead>
				<tbody>
					{data.map((account) => (
						<React.Fragment key={account._id}>
							<tr>
								<td
									rowSpan={Number(totalGeneratedDebts) + Number(1) + Number(account.debts.length)}
									className='text-center align-middle'
									colSpan={2}
								>
									{account.name}
								</td>
								<td colSpan={12} className='text-center'>
									Deudas
								</td>
							</tr>
							{account.debts.map((debt) =>
								debt && debt.generatedDebts ? (
									<React.Fragment key={debt._id}>
										<tr>
											<td
												rowSpan={Number(debt.generatedDebts.length) + Number(1)}
												className='text-center align-middle'
											>
												{debt.concept}
											</td>
											{Object.keys(columnMap['debtsDues']).map(
												(column) =>
													!excludedColumns.includes(column) && (
														<td key={column} colSpan={2}>
															{columnMap['debtsDues'][column]}
														</td>
													),
											)}
										</tr>
										{debt.generatedDebts.map((due) => (
											<tr key={due._id}>
												{Object.keys(columnMap['debtsDues']).map(
													(column) =>
														!excludedColumns.includes(column) && (
															<td key={column} colSpan={2}>
																{currencyColumns.includes(column)
																	? dataName === 'debtsDues' ||
																		dataName === 'creditDues' ||
																		dataName === 'accounts'
																		? showCurrency(debt.currency, dataName) +
																			' ' +
																			getNestedValue(due, column)
																		: showCurrency(
																			getNestedValue(due, 'currency'),
																			getNestedValue(due, column),
																			dataName,
																		) +
																			' ' +
																			getNestedValue(due, column)
																	: formatValue(getNestedValue(due, column))}
															</td>
														),
												)}
											</tr>
										))}
									</React.Fragment>
								) : (
									''
								),
							)}
						</React.Fragment>
					))}
				</tbody>
			</Table>
			{data.map((account) => (
				<Accordion key={account._id}>
					<Accordion.Item eventKey={account._id} key={account._id}>
						<Accordion.Header>Estado Situacion de: {account.name}</Accordion.Header>
						<Accordion.Body>
							<Accordion key={account._id}>
								<Accordion.Item eventKey='0'>
									<Accordion.Header>Deudas</Accordion.Header>
									<Accordion.Body>
										{account.debts && account.debts.length > 0 ? (
											account.debts.map((debt) => (
												<Accordion key={debt._id}>
													<Accordion.Item eventKey={debt._id} key={debt._id}>
														<Accordion.Header>Deuda {debt.concept}</Accordion.Header>
														<Accordion.Body>
															<Accordion>
																<Accordion.Item eventKey='1'>
																	<Accordion.Header>cuotas</Accordion.Header>
																	<Accordion.Body>
																		{debt && debt.generatedDebts && debt.generatedDebts.length !== 0 ? (
																			<Table>
																				<thead>
																					<tr>
																						{Object.keys(columnMap['debtsDues']).map(
																							(column) =>
																								!excludedColumns.includes(column) && (
																									<th key={column}>
																										{columnMap['debtsDues'][column]}
																									</th >
																								),
																						)}
																						<th colSpan={4}>Pagos</th>
																					</tr>
																				</thead>
																				<tbody className='mb-2'>
																					{debt.generatedDebts.map((due) => (
																						<React.Fragment key={due._id}>
																							<tr>
																								{Object.keys(columnMap['debtsDues']).map(
																									(column) =>
																										!excludedColumns.includes(column) && (
																											<td key={column}>
																												{currencyColumns.includes(column)
																													? dataName === 'debtsDues' ||
																													dataName === 'creditDues' ||
																													dataName === 'accounts' 
																														? showCurrency(debt.currency, dataName) + ' ' + getNestedValue(due, column)
																														: showCurrency(
																															getNestedValue(due, 'currency'),
																															getNestedValue(due, column),
																															dataName, ) + ' ' + getNestedValue(due, column)
																													: formatValue(getNestedValue(due, column))}
																											</td>
																										),
																								)}
																								<td className="accordion-cell" colSpan={4}>																										
																									<Accordion className='custom-accordion'>
																										<Accordion.Item eventKey={due._id}>
																											<Accordion.Header>Ver Pagos</Accordion.Header>
																											<tr>
																												<Accordion.Body>
																													{due && due.payments && due.payments.length !== 0 ? (
																														<Table>
																															<thead>
																																<tr>
																																	{Object.keys(columnMap['payments']).map(
																																		(column) =>
																																			!excludedColumns.includes(column) && (
																																				<th key={column}>
																																					{columnMap['payments'][column]}
																																				</th>
																																			),
																																	)}
																																</tr>
																															</thead>
																															<tbody className='mb-2'>
																																{due.payments.map((payment) => (
																																	<tr key={payment._id}>
																																		{Object.keys(columnMap['payments']).map(
																																			(column) =>
																																				!excludedColumns.includes(column) && (
																																					<td key={column}>
																																						{currencyColumns.includes(column)
																																							? dataName === 'payments' || 
																																							dataName === 'creditDues' || 
																																							dataName === 'accounts'
																																								? showCurrency(debt.currency, dataName) + ' ' + getNestedValue(payment, column)
																																								: showCurrency(
																																									getNestedValue(payment, 'currency'),
																																									getNestedValue(payment, column),
																																									dataName, ) + ' ' + getNestedValue(payment, column)
																																							: formatValue(getNestedValue(payment, column))}
																																					</td>
																																				),
																																		)}
																																	</tr>
																																))}
																															</tbody>
																														</Table>
																													) : (
																														<p>No hay pagos vinculados</p>
																													)}
																												</Accordion.Body>
																											</tr>
																										</Accordion.Item>
																									</Accordion>
																								</td>
																							</tr>
																						</React.Fragment>
																					))}
																				</tbody>
																			</Table>
																		) : (
																			<h2>No hay cuotas vinculadas</h2>
																		)}
																	</Accordion.Body>
																</Accordion.Item>
															</Accordion>
														</Accordion.Body>
													</Accordion.Item>
												</Accordion>
											))
										) : (
											<h2>No hay deudas vinculados</h2>
										)}
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
							<Accordion key={account._id + '_nested'}>
								<Accordion.Item eventKey='1'>
									<Accordion.Header>Creditos</Accordion.Header>
									<Accordion.Body>
										{account.credits && account.credits.length > 0 ? (
											account.credits.map((credit) => (
												<Accordion key={credit._id}>
													<Accordion.Item eventKey={credit._id} key={credit._id}>
														<Accordion.Header>Credito {credit.concept}</Accordion.Header>
														<Accordion.Body>
															<Accordion>
																<Accordion.Item eventKey='1'>
																	<Accordion.Header>cuotas</Accordion.Header>
																	<Accordion.Body>
																		{credit &&
																		credit.generatedCredits &&
																		credit.generatedCredits.length !== 0 ? (
																				<Table>
																					<thead>
																						<tr>
																							{Object.keys(columnMap['creditDues']).map(
																								(column) =>
																									!excludedColumns.includes(column) && (
																										<th key={column}>
																											{columnMap['creditDues'][column]}
																										</th>
																									),
																							)}
																							<th colSpan={4}>Cobros</th>
																						</tr>
																					</thead>
																					<tbody className='mb-2'>
																						{credit.generatedCredits.map((due) => (
																							<React.Fragment key={due._id}>
																								<tr>
																									{Object.keys(columnMap['creditDues']).map(
																										(column) =>
																											!excludedColumns.includes(column) && (
																												<td key={column}>
																													{currencyColumns.includes(column)
																														? dataName === 'debtsDues' ||
																													dataName === 'creditDues' ||
																													dataName === 'accounts'
																															? showCurrency(
																																credit.currency,
																																dataName,
																															) +
																														' ' +
																														getNestedValue(due, column)
																															: showCurrency(
																																getNestedValue(due, 'currency'),
																																getNestedValue(due, column),
																																dataName,
																															) +
																														' ' +
																														getNestedValue(due, column)
																														: formatValue(getNestedValue(due, column))}
																												</td>
																											),
																									)}
																									<td className="accordion-cell" colSpan={4}>
																										<Accordion className='custom-accordion'>
																											<Accordion.Item eventKey={due._id}>
																												<Accordion.Header>Ver Cobros</Accordion.Header>
																												<tr>
																													<Accordion.Body>
																														{due && due.creditPayments && due.creditPayments.length !== 0 ? (
																															<Table>
																																<thead>
																																	<tr>
																																		{Object.keys(columnMap['creditPayments']).map(
																																			(column) =>
																																				!excludedColumns.includes(column) && (
																																					<th key={column}>
																																						{columnMap['creditPayments'][column]}
																																					</th>
																																				),
																																		)}
																																	</tr>
																																</thead>
																																<tbody className='mb-2'>
																																	{due.creditPayments.map((creditPayment) => (
																																		<tr key={creditPayment._id}>
																																			{Object.keys(columnMap['creditPayments']).map(
																																				(column) =>
																																					!excludedColumns.includes(column) && (
																																						<td key={column}>
																																							{currencyColumns.includes(column)
																																								? dataName === 'payments' ||
                                              dataName === 'creditDues' ||
                                              dataName === 'accounts'
																																									? showCurrency(credit.currency, dataName) +
                                                ' ' +
                                                getNestedValue(creditPayment, column)
																																									: showCurrency(
																																										getNestedValue(creditPayment, 'currency'),
																																										getNestedValue(creditPayment, column),
																																										dataName,
																																									) +
                                                ' ' +
                                                getNestedValue(creditPayment, column)
																																								: formatValue(getNestedValue(creditPayment, column))}
																																						</td>
																																					),
																																			)}
																																		</tr>
																																	))}
																																</tbody>
																															</Table>
																														) : (
																															<p>No hay Cobros vinculados</p>
																														)}
																													</Accordion.Body>
																												</tr>
																											</Accordion.Item>
																										</Accordion>
																									</td>
																								</tr>
																							</React.Fragment>
																						))}
																					</tbody>
																				</Table>
																			) : (
																				<h2>No hay cuotas vinculadas</h2>
																			)}
																	</Accordion.Body>
																</Accordion.Item>
															</Accordion>
														</Accordion.Body>
													</Accordion.Item>
												</Accordion>
											))
										) : (
											<h2>No hay creditos vinculados</h2>
										)}
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
							<Accordion key={account._id + '_nested'}>
								<Accordion.Item eventKey='1'>
									<Accordion.Header>Activos</Accordion.Header>
									<Accordion.Body>
										{account.assets && account.assets.length > 0 ? (
											account.assets.map((asset) => (
												<Accordion key={asset._id}>
													<Accordion.Item eventKey={asset._id} key={asset._id}>
														<Accordion.Header>Activo Fijo {asset.customId}</Accordion.Header>
														<Accordion.Body>
															<Accordion>
																<Accordion.Item eventKey='1'>
																	<Accordion.Header>Gastos</Accordion.Header>
																	<Accordion.Body>
																		{asset && asset.bills && asset.bills.length !== 0 ? (
																			<Table>
																				<thead>
																					{asset && asset.bills && asset.bills.length !== 0 ? (
																						<tr>
																							{Object.keys(columnMap['bills']).map(
																								(column) =>
																									!excludedColumns.includes(column) && (
																										<th key={column}>
																											{columnMap['bills'][column]}
																										</th>
																									),
																							)}
																						</tr>
																					) : (
																						<tr>
																							<th className='text-center'>
																								No hay cuotas vinculadas
																							</th>
																						</tr>
																					)}
																				</thead>
																				<tbody className='mb-2'>
																					{asset.bills.map((bill) => (
																						<tr key={bill._id}>
																							{Object.keys(columnMap['bills']).map(
																								(column) =>
																									!excludedColumns.includes(column) && (
																										<td key={column}>
																											{currencyColumns.includes(column)
																												? dataName === 'debtsDues' ||
																													dataName === 'creditDues' ||
																													dataName === 'accounts'
																													? showCurrency(asset.currency, dataName) +
																														' ' +
																														getNestedValue(bill, column)
																													: showCurrency(
																														getNestedValue(asset, 'currency'),
																														getNestedValue(bill, column),
																														dataName,
																													) +
																														' ' +
																														getNestedValue(bill, column)
																												: formatValue(getNestedValue(bill, column))}
																										</td>
																									),
																							)}
																						</tr>
																					))}
																				</tbody>
																			</Table>
																		) : (
																			<h2>No hay gastos vinculados</h2>
																		)}
																	</Accordion.Body>
																</Accordion.Item>
															</Accordion>
														</Accordion.Body>
													</Accordion.Item>
												</Accordion>
											))
										) : (
											<h2>No hay activos vinculados</h2>
										)}
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			))}
		</div>
	);
};

export default GroupedTable;
