import React, { useEffect, useReducer } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import AccountService from '../../services/account.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_ACCOUNTS_REQUEST':
		return { ...state, loadingAccounts: true };
	case 'FETCH_ACCOUNTS_SUCCESS':
		return { ...state, accounts: action.payload, loadingAccounts: false };
	case 'FETCH_ACCOUNTS_FAIL':
		return { ...state, loadingAccounts: false, error: action.payload };
	default:
		return state;
	}
};

function AssetInfo({ asset }) {
	const params = useParams();
	const { id } = params;

	const [{error, loadingAccounts, accounts }, dispatch] = useReducer(reducer, {
		accounts: [],
		loadingAccounts: true,
		error: '',
	});


	const currencies = ['USD', 'UYU'];


	useEffect(() => {
		const fetchAccounts = async () => {
			dispatch({ type: 'FETCH_ACCOUNTS_REQUEST' });
			try {
				const result = await AccountService.list();
				dispatch({ type: 'FETCH_ACCOUNTS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_ACCOUNTS_FAIL', payload: getError(error) });
				console.error(error);
				toast.error('No se pudo obtener lista');
			}
		};
		fetchAccounts();
	}, [id, asset]);


	async function verEscritura(asset) {
		if (asset.escritura) {
			window.open(asset.escritura.fileLink, '_blank', 'noopener,noreferrer');
		}
	}

	return (
		<div>
			{(loadingAccounts) ? (
				<LoadingBox></LoadingBox>
			) : (
				error ? (
					<MessageBox variant='danger'>{error}</MessageBox>
				) : null
			)}			{/* CONFIRM MODAL / BAR LOADER / ERRORS*/}
			<div className='container admin-con align-items-center'>
				<Helmet>
					<title>Información de Activo</title>
				</Helmet>
				{/* <h1 className="my-3">Editar Activo</h1> */}
				<Form onSubmit={(e) => e.preventDefault()}>
					<div className='row align-items-center'>
						<Row className='align-items-center'>
							<Form.Group className='mb-3 col-6' controlId='Name'>
								<Form.Label>Nombre</Form.Label>
								<Form.Control
									type='text'
									defaultValue={asset.name}
									disabled
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3 col-6' controlId='price'>
								<Form.Label>Precio/Valor</Form.Label>
								<Form.Control
									type='number'
									disabled
									defaultValue={asset.price}
								></Form.Control>
							</Form.Group>
						</Row>
						<Row className='align-items-center'>
							<Form.Group className='mb-3 col-6' controlId='type'>
								<Form.Label>Tipo</Form.Label>
								<Form.Control
									type='text'
									defaultValue={asset.type}
									disabled
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3 col-6' controlId='expirationDate'>
								<Form.Label>Ubicacion</Form.Label>
								<Form.Control
									type='text'
									defaultValue={asset.ubication}
									disabled
								></Form.Control>
							</Form.Group>
							<Card>
								<Row>
									<Form.Group
										className=' col-6 form-group d-flex align-items-center'
										controlId='multipleLots'
										style={{ height: '60px' }}
									>
										<Form.Check
											type='checkbox'
											label='El activo tiene multiples padrones?'
											defaultChecked={asset.multipleLots}
											disabled
										/>
									</Form.Group>
									<Form.Group className='mb-3 col-6' controlId='periodicity'>
										<Form.Label>Padrón</Form.Label>
										<Form.Control type='text' defaultValue={asset.lot} disabled></Form.Control>
									</Form.Group>
								</Row>
							</Card>
						</Row>
						<Row className='align-items-center'>
							<Form.Group className='mb-3 col-12' controlId='periodicity'>
								<Form.Label>Descripcion</Form.Label>
								<Form.Control
									type='text'
									defaultValue={asset.description}
									disabled
								></Form.Control>
							</Form.Group>
							{asset.owner ? (
								<Form.Group className='mb-3 col-6'>
									<Form.Label>Propietario</Form.Label>
									<Form.Select value={asset.owner._id} disabled>
										{accounts.map((account) => {
											return (
												<option key={account._id} value={account._id}>
													{account.name}
												</option>
											);
										})}
										<option key='blankChoice' hidden value>
											{' '}
											Elige el propietario{' '}
										</option>
									</Form.Select>
								</Form.Group>
							) : (
								<Form.Group className='mb-3 col-6'>
									<Form.Label>Propietario</Form.Label>
									<Form.Select disabled>
										{accounts.map((account) => {
											return (
												<option key={account._id} value={account._id}>
													{account.name}
												</option>
											);
										})}
										<option key='blankChoice' hidden value>
											{' '}
											No tiene Propietario asignado{' '}
										</option>
									</Form.Select>
								</Form.Group>
							)}
							<Form.Group as={Col} className='mb-3 col-6' controlId='currency'>
								<Form.Label>Moneda</Form.Label>
								<Form.Select
									as='select'
									value={asset.currency ? asset.currency : null}
									disabled
								>
									<option key='default'>Elige Moneda</option>
									{currencies.map((currency, index) => {
										return (
											<option key={index} value={currency}>
												{currency}
											</option>
										);
									})}
								</Form.Select>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group className='mb-3 col-6' controlId='folder'>
								<Form.Label>Carpeta</Form.Label>
								<Form.Control
									value={asset.folder}
									disabled
								></Form.Control>
							</Form.Group>
							<Button
								className='col-2'
								style={{ height: '30px', marginTop: '32px', padding: '0' }}
								disabled={!asset.escritura}
								onClick={() => verEscritura(asset)}
							>
								Ver Escritura
							</Button>
						</Row>
						<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
							<Form.Label>Observaciones</Form.Label>
							<Form.Control
								type='text'
								as='textarea'
								disabled
								value={asset.observations}
								rows={10}
							></Form.Control>
						</Form.Group>
					</div>
					{/* <div className="row align-items-centers">
            <div
              style={{
                padding: 0,
                width: "300px",
                height: "300px",
                margin: "auto",
                border: "1px solid black",
                backgroundImage:
                  image && image.fileLink
                    ? "url(" + image.fileLink + ")"
                    : "url(/images/emptyPhoto.png)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% auto",
                backgroundPosition: "center top",
              }}
            >
              <AvatarImageCropper apply={apply} text="Adjuntar la imagen" />
            </div>
          </div> */}
					{/* <div className="mb-3">
            <button id="goBack" onClick={() => navigate(-1)}>
              <BsArrowLeftCircle /> Atras
            </button>
            <Button onClick={handleShow}>Guardar</Button>
          </div> */}
				</Form>
			</div>
		</div>
	);
}
export default AssetInfo;
