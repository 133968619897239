import React from 'react';
import ClockLoader from 'react-spinners/ClockLoader';
import './LoadingBox.css'; // import the CSS file

function LoadingBox(loading) {
	return (
		<div className='loading-box-container'>
			<div className='loading-box'>
				<ClockLoader color='#764176' loading={loading} />
			</div>
		</div>
	);
}

export default LoadingBox;
