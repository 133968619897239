import React, { useEffect, useReducer, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { Helmet } from 'react-helmet-async';
import { MdOutlinePayments } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import Pagination from '../../components/Pagination';
import creditPaymentService from '../../services/creditPayment.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, creditPayments: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function CreditPaymentList() {
	const [{ loading, error, creditPayments, successDelete }, dispatch] = useReducer(reducer, {
		creditPayments: [],
		loading: true,
		error: '',
	});

	const navigate = useNavigate();

	//pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(15); //maxPostsperPage
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = creditPayments.slice(indexOfFirstPost, indexOfLastPost);
	//change page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await creditPaymentService.list();

				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		} else {
			fetchData();
		}
	}, [successDelete]);

	async function deletePaymentHandler(creditPayment) {
		if (window.confirm('Seguro desea eliminar el pago?')) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await creditPaymentService.delete(creditPayment._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Pago Eliminado');
			} catch (err) {
				toast.error(getError(err));
				dispatch({ type: 'DELETE_FAIL' });
			}
		}
	}

	async function editPaymentHandler(creditpayment) {
		navigate(`/AdminScreen/creditPayments/editCreditPayment/${creditpayment._id}`);
	}
	async function seeDetails(creditpayment) {
		navigate(`/AdminScreen/creditPayments/creditPayment/${creditpayment._id}`);
	}
	async function newPaymentHandler() {
		navigate('/AdminScreen/newCreditPayment');
	}
	return loading ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Pagos</title>
			</Helmet>

			<div className='container admin-con'>
				<div className='row adminProdBtnRow align-items-center'>
					<Row style={{ margin: '5% 0 2.5%' }}>
						<Col>
							<h1 className='text-right'>
								<MdOutlinePayments></MdOutlinePayments>Lista de Pagos
							</h1>
						</Col>
						<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<Button
								className='btn btn-dark m-2'
								value='Crear Cuenta'
								onClick={() => newPaymentHandler()}
							>
								Agregar Pago
							</Button>
						</Col>
					</Row>
					<Table bordered hover responsive size='sm'>
						<thead>
							<tr>
								<th>ID</th>
								<th>Monto</th>
								<th>Pasividad</th>
								<th>Fecha de finalizacion</th>
								<th>Cuenta</th>
								<th>Carpeta</th>
								<th className='col-1'>Opciones</th>
							</tr>
						</thead>
						{creditPayments
							? currentPosts.map((creditPayment) => (
								<tbody key={creditPayment._id}>
									<tr key={creditPayment._id}>
										<td>
											<Link to={`creditPayment/${creditPayment._id}`}>
												{creditPayment.customId}
											</Link>
										</td>
										<td>{creditPayment.amount}</td>
										{creditPayment.credit ? (
											<td>
												<Link to={`/AdminScreen/credits/credit/${creditPayment.credit._id}`}>
													{creditPayment.credit.customId}
												</Link>
											</td>
										) : (
											<td></td>
										)}
										{creditPayment.date ? (
											<td>{creditPayment.date.substring(0, 10)}</td>
										) : (
											<td></td>
										)}
										{creditPayment.credit && creditPayment.credit.owner ? (
											<td>
												<Link
													to={`/AdminScreen/accounts/account/${creditPayment.credit.owner._id}`}
												>
													{creditPayment.credit.owner.name}
												</Link>
											</td>
										) : (
											<td></td>
										)}
										<td>{creditPayment.folder}</td>
										<td>
											<DropdownButton drop='start' title=''>
												<Dropdown.Item eventKey='1' onClick={() => seeDetails(creditPayment)}>
													Ver Detalles
												</Dropdown.Item>
												<Dropdown.Item
													eventKey='1'
													onClick={() => editPaymentHandler(creditPayment)}
												>
													Editar
												</Dropdown.Item>
												<Dropdown.Item
													eventKey='2'
													onClick={() => deletePaymentHandler(creditPayment)}
												>
													Eliminar
												</Dropdown.Item>
											</DropdownButton>
										</td>
									</tr>
								</tbody>
							))
							: ''}
					</Table>
					<Pagination
						postsPerPage={postsPerPage}
						totalPosts={creditPayments.length}
						paginate={paginate}
					></Pagination>
				</div>
			</div>
		</div>
	);
}

export default CreditPaymentList;
