import moment from 'moment';
import React, { useEffect, useReducer,useState } from 'react';
import { Card, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { MdWarning } from 'react-icons/md';
import HistoryModal from '../../components/HistoryModal';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import accountService from '../../services/account.service';
import contractService from '../../services/contract.service';
import { currencies, getError, periodicities } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_ACCOUNTS_REQUEST':
		return { ...state, loadingAccounts: true };
	case 'FETCH_ACCOUNTS_SUCCESS':
		return { ...state, accounts: action.payload, loadingAccounts: false };
	case 'FETCH_ACCOUNTS_FAIL':
		return { ...state, loadingAccounts: false, error: action.payload };
	case 'FETCH_CONTRACTS_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_CONTRACTS_SUCCESS':
		return {
			...state,
			contracts: action.payload,
			loadingFetch: false,
		};
	case 'FETCH_CONTRACTS_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	default:
		return state;
	}
};

function creditInfo({ credit }) {

	const [{ error, loadingAccounts, accounts, contracts }, dispatch] = useReducer(reducer, {
		contracts: [],
		accounts: [],
		loadingAccounts: true,
		error: '',
	});

	const [showHistory, setShowHistory] = useState(false);

	const handleShowHistory = () => {
		setShowHistory(true);
	};

	const handleCloseHistory = () => {
		setShowHistory(false);
	};

	const concepts = ['prestamos-amortizable', 'prestamos-interés'];

	useEffect(() => {
		const fetchAccounts = async () => {
			dispatch({ type: 'FETCH_ACCOUNTS_REQUEST' });
			try {
				const result = await accountService.list();
				dispatch({ type: 'FETCH_ACCOUNTS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		const fetchContractsData = async () => {
			dispatch({ type: 'FETCH_CONTRACTS_REQUEST' });
			try {
				const result = await contractService.list();
				dispatch({ type: 'FETCH_CONTRACTS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_CONTRACTS_FAIL', payload: error.message });
			}
		};
		fetchAccounts();
		fetchContractsData();
	}, [credit]);

	return (
		<div>
			{(loadingAccounts) ? (
				<LoadingBox></LoadingBox>
			) : (
				error ? (
					<MessageBox variant='danger'>{error}</MessageBox>
				) : null
			)}			<div className='container admin-con align-items-center'>
				<Helmet>
					<title>Pasividades</title>
				</Helmet>
				<HistoryModal model='credit' show={showHistory} handleClose={handleCloseHistory} id={credit._id}/>
				<Form onSubmit={(e) => e.preventDefault()}>
					<div className='row align-items-center'>
						<Card className='d-flex mb-3 justify-content-center card-form-debts'>
							<Row>
								<h2 className='text-center m-3'> Datos para la generación de cuotas</h2>
								<span onClick={handleShowHistory} style={{ cursor: 'pointer', textDecoration: 'underline' }} className="btn btn-link p-0">historial de cambios</span>
							</Row>
							<Row className='d-flex mb-3 justify-content-center'>
								<OverlayTrigger
									placement='left'
									overlay={
										<Tooltip id='tooltip-previous-debt'>
											<MdWarning />
											Es la fecha de inicio del crédito, si usted selecciona una fecha anterior al
											día de hoy se crearán las cuotas pasadas, si no es lo que desea seleccione una
											fecha igual o mayor a la de hoy.
										</Tooltip>
									}
								>
									<Form.Group className='mb-3 col-3 form-group required' controlId='Date'>
										<Form.Label>Fecha de inicio del crédito</Form.Label>
										<Form.Control
											type='date'
											value={credit.date ? moment(credit.date).utc().format('YYYY-MM-DD') : null}
											disabled
										></Form.Control>
									</Form.Group>
								</OverlayTrigger>
								<Form.Group className='mb-3 col-3 form-group required' controlId='DaysDropdown'>
									<Form.Label>Día de vencimiento de primer cuota:</Form.Label>
									<Form.Control value={credit.dueDay ? credit.dueDay : 'No hay datos'} />
								</Form.Group>
								<Form.Group
									className='mb-3 col-2 form-group required'
									controlId='selectedMonthOption'
								>
									<Form.Label>Generar a partir de:</Form.Label>
									<Form.Control
										value={credit.selectedMonthOption ? credit.selectedMonthOption : 'No hay datos'}
									/>
								</Form.Group>
								{credit.selectedMonthOption === 'Otro' && (
									<Form.Group className='mb-3 col-3 form-group required' controlId='numMonths'>
										<Form.Label>En cuantos meses:</Form.Label>
										<Form.Control type='number' min='1' value={credit.numMonths} />
									</Form.Group>
								)}
							</Row>
							<Row>
								<div className='d-flex d-inline justify-content-center align-items-center'>
									<p>
										{credit.firstDueExpiration
											? `Primer Vencimiento: ${moment.utc(credit.firstDueExpiration).format('DD-MM-YYYY')}  `
											: ''}
									</p>
								</div>
							</Row>
						</Card>
						{credit.createWithPreviousDues ? (
							<Card className='d-flex align-items-center justify-content-center mb-3 card-form-debts'>
								<Row>
									<h2 className='text-center m-3'>
										<MdWarning />
										Credito con cuotas previas {credit.createWithPreviousPayments ? 'y pagas' : ''}
									</h2>
								</Row>
								<Row>
									<p>
										Ésta crédito fue creado con fecha anterior, por lo que fueron generadas las
										cuotas previas a la fecha de creación
									</p>
								</Row>
							</Card>
						) : (
							''
						)}
						<Card className='d-flex justify-content-center mb-3 card-form-debts'>
							<Row>
								<h2 className='text-center m-3'>Información contable</h2>
							</Row>
							<Form.Group className='mb-3 col-6 form-group required'>
								<Form.Label>Concepto</Form.Label>
								<Form.Select as='select' value={credit.concept} disabled>
									<option key='blankChoice' hidden value>
										{' '}
										Elige un Concepto
									</option>
									{concepts.map((concept, index) => {
										return (
											<option key={index} value={concept}>
												{concept}
											</option>
										);
									})}
								</Form.Select>
							</Form.Group>
							{credit.concept === 'alquileres' && (
								<Form.Group className='mb-3 col-6'>
									<Form.Label>Propiedad:</Form.Label>
									<Form.Select
										as='select'
										value={credit.contract ? [credit.contract._id] : null}
										disabled
									>
										{contracts.map((contract) => {
											return (
												<option key={contract._id} value={contract._id}>
													{contract.customId}
												</option>
											);
										})}
										<option key='blankChoice' hidden value>
											{' '}
											Selecciona la propiedad
										</option>
									</Form.Select>
								</Form.Group>
							)}
							<Row>
								<Form.Group className='mb-3 col-6'>
									<Form.Label>Deudor</Form.Label>
									<Form.Select value={credit.owner ? credit.owner._id : null} disabled>
										{accounts.map((account) => {
											return (
												<option key={account._id} value={account._id}>
													{account.name}
												</option>
											);
										})}
										<option key='blankChoice' hidden value>
											{' '}
											Elige la Cuenta vinculada
										</option>
									</Form.Select>
								</Form.Group>
								<Form.Group as={Col} className='mb-3 col-6' controlId='currency'>
									<Form.Label>Moneda</Form.Label>
									<Form.Select as='select' disabled value={credit.currency}>
										<option key='default'>Elige Moneda</option>
										{currencies.map((currency, index) => {
											return (
												<option key={index} value={currency}>
													{currency}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>
								{credit.concept && credit.concept.includes('prestamos') && (
									<>
										<Form.Group className='mb-3 col-md-4  form-group required'>
											<Form.Label>Periodicidad</Form.Label>
											<Form.Select
												as='select'
												value={credit.periodicity ? credit.periodicity : null}
												disabled
											>
												<option key={0}>Elige la periodicidad</option>
												{periodicities.map((periodicity, index) => {
													return (
														<option key={index} value={periodicity}>
															{periodicity}
														</option>
													);
												})}
											</Form.Select>
										</Form.Group>
										<Form.Group className='mb-3 col-md-3 form-group required' controlId='amount'>
											<Form.Label>Capital</Form.Label>
											<Form.Control type='number' value={credit.capital} disabled></Form.Control>
										</Form.Group>
										<Form.Group className='mb-3 col-md-2 form-group required' controlId='amount'>
											<Form.Label>Interes %</Form.Label>
											<Form.Control
												type='number'
												defaultValue={credit.interest}
												disabled
											></Form.Control>
										</Form.Group>
										{credit.concept === 'prestamos-amortizable' && (
											<Form.Group className='mb-3  col-md-3 ' controlId='dues'>
												<Form.Label>Cuotas</Form.Label>
												<Form.Control
													type='number'
													defaultValue={credit.dues}
													disabled
												></Form.Control>
											</Form.Group>
										)}
										{credit.concept !== 'prestamos-amortizable' &&
											credit.concept !== 'ventas-a-plazo' && (
											<Form.Group className='mb-3  col-md-3  form-group' controlId='endDate'>
												<Form.Label>Fecha de fin</Form.Label>
												<Form.Control
													type='date'
													disabled
													value={
														credit.endDate ? moment(credit.endDate).format('YYYY-MM-DD') : null
													}
												></Form.Control>
											</Form.Group>
										)}
									</>
								)}
								<Card className='d-flex mb-3 flex-row align-items-center justify-content-evenly'>
									<Form.Group className='mb-3 col-md-2 form-group required' controlId='amount'>
										<Form.Label>
											Monto {credit.concept && credit.concept.includes('prestamos') ? 'cuota' : ''}
										</Form.Label>
										<Form.Control type='number' value={credit.amount} disabled></Form.Control>
									</Form.Group>
									<Form.Group className='mb-3 col-md-2 form-group' controlId='endDate'>
										<Form.Label>Total Garantizado</Form.Label>
										<Form.Control
											type='number'
											disabled
											value={credit.guaranteedAmount}
										></Form.Control>
									</Form.Group>
								</Card>
							</Row>
						</Card>
					</div>
					<Card className='d-flex  justify-content-between mb-3'>
						<Row>
							<h2 className='text-center mb-3'>Registro fuera del sistema</h2>
						</Row>
						<Row className='d-flex flex-row align-items-center'>
							<OverlayTrigger
								placement='left'
								overlay={
									<Tooltip id='tooltip-previous-debt'>
										Los datos que se completan aqui son únicamente informativos, con el fin de
										registrar la contabilidad fuera del sistema
									</Tooltip>
								}
							>
								<Form.Group
									className=' col-3 form-group d-flex align-items-center'
									controlId='hasPreviousCredits'
									style={{ height: '60px' }}
								>
									<Form.Check
										className='d-flex align-items-center'
										type='checkbox'
										label='Tiene credito anterior?'
										checked={credit.hasPreviousCredits}
										disabled
									/>
								</Form.Group>
							</OverlayTrigger>
							<Form.Group className='mb-3 col-4 form-group ' controlId='previousCredit'>
								<Form.Label>Deuda Anterior</Form.Label>
								<Form.Control
									type='number'
									defaultValue={credit.previousCredit}
									disabled
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3 col-4 form-group ' controlId='previousCreditDate'>
								<Form.Label>Fecha credito anterior</Form.Label>
								<Form.Control
									type='date'
									value={
										credit.previousCreditDate
											? moment(credit.previousCreditDate).utc().format('YYYY-MM-DD')
											: null
									}
									disabled
								></Form.Control>
							</Form.Group>
						</Row>
					</Card>
				</Form>
			</div>
		</div>
	);
}
export default creditInfo;
