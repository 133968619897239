import React, { useEffect, useReducer, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { MdMonetizationOn } from 'react-icons/md';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { getError } from '../../utils';

import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import debtService from '../../services/debt.service';
import interestService from '../../services/interest.service';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_DEBTS_REQUEST':
		return { ...state, loadingDebts: true };
	case 'FETCH_DEBTS_SUCCESS':
		return { ...state, debts: action.payload, loadingDebts: false };
	case 'FETCH_DEBTS_FAIL':
		return { ...state, loadingDebts: false, error: action.payload };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, interest: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};

function InterestInfo() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, error, interest, loadingDebts, debts }, dispatch] = useReducer(reducer, {
		debts: [],
		loadingDebts: true,
		interest: {},
		loading: true,
		error: '',
	});

	const [percentage, setPercentage] = useState();
	const [type, setType] = useState();
	const [debt, setDebt] = useState();
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await interestService.getDetails(id);
				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				setPercentage(result.data.percentage);
				setType(result.data.type);
				setDebt(result.data.debt);
				setFolder(result.data.folder);
				setObservations(result.data.observations);
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
			// setdebts(result.data);
		};
		const fetchDebts = async () => {
			dispatch({ type: 'FETCH_DEBTS_REQUEST' });
			try {
				const result = await debtService.list();
				dispatch({ type: 'FETCH_DEBTS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchDebts();
		fetchData();
	}, [id]);

	async function editPaymentHandler() {
		navigate(`/AdminScreen/interests/editinterest/${id}`);
	}

	return loading || loadingDebts ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<div className='container admin-con align-items-center'>
				<Row style={{ margin: '0 0 2.5% 0' }}>
					<Col style={{ alignSelf: 'center' }}>
						<h2>
							<MdMonetizationOn></MdMonetizationOn>Interes:{interest.internalId}
						</h2>
					</Col>
					<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							style={{ backgroundColor: 'transparent!important' }}
							id='back-btn'
							className='btn m-2'
							value='back'
							onClick={() => navigate(-1)}
						>
							Atras
						</Button>
						<Button
							className='btn btn-dark m-2'
							value='Crear Cuenta'
							onClick={() => editPaymentHandler()}
						>
							Editar Interes
						</Button>
					</Col>
				</Row>
				<Form onSubmit={(e) => e.preventDefault()}>
					<div className='row align-items-center'>
						<Form.Group className='mb-3 col-6' controlId='amount'>
							<Form.Label>Porcentaje</Form.Label>
							<Form.Control
								type='number'
								defaultValue={percentage}
								disabled
								onChange={(e) => setPercentage(e.target.value)}
							></Form.Control>
						</Form.Group>
						<Form.Group className='mb-3 col-6' controlId='date'>
							<Form.Label>Tipo</Form.Label>
							<Form.Control
								type='text'
								defaultValue={type}
								disabled
								onChange={(e) => setType(e.target.value)}
							></Form.Control>
						</Form.Group>
						{debt ? (
							<Form.Group className='mb-3 col-6'>
								<Form.Label>Deuda vinculada</Form.Label>
								<Form.Select value={debt._id} disabled>
									{debts.map((debt) => {
										return (
											<option key={debt._id} value={debt._id}>
												{debt.owner ? debt.owner.name + ': ' + debt.internalId : debt.internalId}
											</option>
										);
									})}
									<option key='blankChoice' hidden value>
										{' '}
										Elige la Deuda vinculada
									</option>
								</Form.Select>
							</Form.Group>
						) : (
							<Form.Group className='mb-3 col-6'>
								<Form.Label>Deuda vinculada</Form.Label>
								<Form.Select value={debt} disabled>
									{debts.map((debt) => {
										return (
											<option key={debt._id} value={debt._id}>
												{debt.owner ? debt.owner.name + ': ' + debt.internalId : debt.internalId}
											</option>
										);
									})}
									<option key='blankChoice' hidden value>
										{' '}
										No tiene Deuda vinculada
									</option>
								</Form.Select>
							</Form.Group>
						)}
						<Form.Group as={Col} className='mb-3 col-6' controlId='folder'>
							<Form.Label>Carpeta</Form.Label>
							<Form.Control
								value={folder}
								disabled
								onChange={(e) => setFolder(e.target.value)}
							></Form.Control>
						</Form.Group>
						<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
							<Form.Label>Observaciones</Form.Label>
							<Form.Control
								type='text'
								as='textarea'
								value={observations}
								disabled
								rows={10}
								onChange={(e) => setObservations(e.target.value)}
							></Form.Control>
						</Form.Group>
					</div>
				</Form>
			</div>
		</div>
	);
}
export default InterestInfo;
