import axiosInstance from '../RestClient';

const BASE_URI = '/historyLogs';

class HistoryLogsService {
	async list() {
		const uri = `${BASE_URI}/`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async listFromId(recordId) {
		const uri = `${BASE_URI}/${recordId}`;
		const response = await axiosInstance.get(uri);
		return response;
	}
}
export default new HistoryLogsService();