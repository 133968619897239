import React, { createContext, useReducer } from 'react';

export const Store = createContext();
const initialState = {
	userInfo: localStorage.getItem('userInfo')
		? JSON.parse(localStorage.getItem('userInfo'))
		: null,
	cart: {
		shippingAddress: localStorage.getItem('shippingAddress')
			? JSON.parse(localStorage.getItem('shippingAddress'))
			: {},
		cartItems: localStorage.getItem('cartItems')
			? JSON.parse(localStorage.getItem('cartItems'))
			: [],
		paymentMethod: localStorage.getItem('paymentMethod')
			? localStorage.getItem('paymentMethod')
			: '',
	},
};
function reducer(state, action) {
	switch (action.type) {

	case 'USER_SIGNIN':
		return { ...state, userInfo: action.payload };
	case 'UPDATE_USER_INFO':
		// localStorage.setItem("userInfo", JSON.stringify(action.payload));
		return { ...state, userInfo: action.payload };
	case 'USER_SIGNOUT':
		return {
			...state,
			userInfo: null,
			cart: {
				cartItems: [],
				shippingAddress: {},
				paymentMethod: '',
			},
		};

	default:
		return state;
	}
}
export function StoreProvider(props) {
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = { state, dispatch };
	return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
