import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { Card, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { MdWarning } from 'react-icons/md';
import HistoryModal from '../../components/HistoryModal';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import accountService from '../../services/account.service';
import assetSaleService from '../../services/assetSale.service';
import contractService from '../../services/contract.service';
import { concepts, currencies, getError, periodicities } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_ACCOUNTS_REQUEST':
		return { ...state, loadingAccounts: true };
	case 'FETCH_ACCOUNTS_SUCCESS':
		return { ...state, accounts: action.payload, loadingAccounts: false };
	case 'FETCH_ACCOUNTS_FAIL':
		return { ...state, loadingAccounts: false, error: action.payload };
	case 'FETCH_CONTRACTS_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_CONTRACTS_SUCCESS':
		return {
			...state,
			contracts: action.payload,
			loadingFetch: false,
		};
	case 'FETCH_CONTRACTS_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'FETCH_ASSETSALES_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_ASSETSALES_SUCCESS':
		return { ...state, assetSales: action.payload, loadingFetch: false };
	case 'FETCH_ASSETSALES_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	default:
		return state;
	}
};

function DebtInfo({ debt }) {

	const [{ error, loadingAccounts, accounts, contracts, assetSales }, dispatch] = useReducer(
		reducer,
		{
			contracts: [],
			accounts: [],
			assetSales: [],
			loadingAccounts: true,
			error: '',
		},
	);

	const [showHistory, setShowHistory] = useState(false);

	const handleShowHistory = () => {
		setShowHistory(true);
	};

	const handleCloseHistory = () => {
		setShowHistory(false);
	};

	useEffect(() => {
		const fetchAccounts = async () => {
			dispatch({ type: 'FETCH_ACCOUNTS_REQUEST' });
			try {
				const result = await accountService.list();
				dispatch({ type: 'FETCH_ACCOUNTS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		const fetchContractsData = async () => {
			dispatch({ type: 'FETCH_CONTRACTS_REQUEST' });
			try {
				const result = await contractService.list();
				dispatch({ type: 'FETCH_CONTRACTS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_CONTRACTS_FAIL', payload: error.message });
			}
		};
		const fetchAssetSalesData = async () => {
			dispatch({ type: 'FETCH_ASSETSALES_REQUEST' });
			try {
				const result = await assetSaleService.list();
				dispatch({ type: 'FETCH_ASSETSALES_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_ASSETSALES_FAIL', payload: error.message });
			}
		};
		fetchAssetSalesData();
		fetchAccounts();
		fetchContractsData();
	}, [debt]);

	return (
		<div>
			{(loadingAccounts) ? (
				<LoadingBox></LoadingBox>
			) : (
				error ? (
					<MessageBox variant='danger'>{error}</MessageBox>
				) : null
			)}			<div className='container admin-con align-items-center'>
				<Helmet>
					<title>Deudas</title>
				</Helmet>
				<HistoryModal model='debt' show={showHistory} handleClose={handleCloseHistory} id={debt._id}/>
				<Form onSubmit={(e) => e.preventDefault()}>
					<div className='row align-items-center'>
						<Card className='d-flex mb-3 justify-content-center card-form-debts'>
							<Row>
								<h2 className='text-center m-3 d-flex flex-column'> Datos para la generación de cuotas
									<span onClick={handleShowHistory} style={{ cursor: 'pointer', textDecoration: 'underline' }} className="btn btn-link p-0">historial de cambios</span>
								</h2>
							</Row>
							<Row className='d-flex mb-3 justify-content-center'>
								<OverlayTrigger
									placement='left'
									overlay={
										<Tooltip id='tooltip-previous-debt'>
											<MdWarning />
											Es la fecha de inicio de la deuda, si usted selecciona una fecha anterior al
											día de hoy se crearán las cuotas pasadas, si no es lo que desea seleccione una
											fecha igual o mayor a la de hoy.
										</Tooltip>
									}
								>
									<Form.Group className='mb-3 col-3 form-group required' controlId='Date'>
										<Form.Label>Fecha de inicio de la deuda</Form.Label>
										<Form.Control
											type='date'
											value={debt.date ? moment(debt.date).utc().format('YYYY-MM-DD') : null}
											disabled
										></Form.Control>
									</Form.Group>
								</OverlayTrigger>
								<Form.Group className='mb-3 col-3 form-group required' controlId='DaysDropdown'>
									<Form.Label>Día de vencimiento de primer cuota:</Form.Label>
									<Form.Control value={debt.dueDay ? debt.dueDay : 'No hay datos'} />
								</Form.Group>
								<Form.Group
									className='mb-3 col-2 form-group required'
									controlId='selectedMonthOption'
								>
									<Form.Label>Generar a partir de:</Form.Label>
									<Form.Control
										value={debt.selectedMonthOption ? debt.selectedMonthOption : 'No hay datos'}
									/>
								</Form.Group>
								{debt.selectedMonthOption === 'Otro' && (
									<Form.Group className='mb-3 col-3 form-group required' controlId='numMonths'>
										<Form.Label>En cuantos meses:</Form.Label>
										<Form.Control type='number' min='1' value={debt.numMonths} />
									</Form.Group>
								)}
							</Row>
							<Row>
								<div className='d-flex d-inline justify-content-center align-items-center'>
									<p>
										{debt.firstDueExpiration
											? `Primer Vencimiento: ${moment.utc(debt.firstDueExpiration).format('DD-MM-YYYY')}  `
											: ''}
									</p>
								</div>
							</Row>
						</Card>
						{debt.createWithPreviousDues ? (
							<Card className='d-flex align-items-center justify-content-center mb-3 card-form-debts'>
								<Row>
									<h2 className='text-center m-3'>
										<MdWarning />
										Deuda con cuotas previas {debt.createWithPreviousPayments ? 'y pagas' : ''}
									</h2>
								</Row>
								<Row>
									<p>
										Ésta deuda fue creada con fecha anterior, por lo que fueron generadas las cuotas
										previas a la fecha de creación
									</p>
								</Row>
							</Card>
						) : (
							''
						)}
						<Card className='d-flex justify-content-center mb-3 card-form-debts'>
							<Row>
								<h2 className='text-center m-3'>Información contable</h2>
							</Row>
							<Form.Group className='mb-3 col-6 form-group required'>
								<Form.Label>Concepto</Form.Label>
								<Form.Select as='select' value={debt.concept} disabled>
									<option key='blankChoice' hidden value>
										{' '}
										Elige un Concepto
									</option>
									{concepts.map((concept, index) => {
										return (
											<option key={index} value={concept}>
												{concept}
											</option>
										);
									})}
								</Form.Select>
							</Form.Group>

							{debt.concept === 'alquileres' && (
								<Form.Group className='mb-3 col-6'>
									<Form.Label>Propiedad:</Form.Label>
									<Form.Select
										as='select'
										value={debt.contract ? [debt.contract._id] : ''}
										disabled
									>
										{contracts.map((contract) => {
											return (
												<option key={contract._id} value={contract._id}>
													{contract.customId}
												</option>
											);
										})}
										<option key='blankChoice' hidden value>
											{' '}
											Selecciona la propiedad
										</option>
									</Form.Select>
								</Form.Group>
							)}
							{debt.concept === 'ventas-a-plazo' && (
								<Form.Group className='mb-3 col-6'>
									<Form.Label>Venta:</Form.Label>
									<Form.Select
										as='select'
										value={debt.assetSale ? debt.assetSale._id : ''}
										disabled
									>
										{assetSales.map((assetSale) => {
											return (
												<option key={assetSale._id} value={assetSale._id}>
													{assetSale.customId}
												</option>
											);
										})}
										<option key='blankChoice' hidden value>
											No hay venta seleccionada
										</option>
									</Form.Select>
								</Form.Group>
							)}
							<Row>
								<Form.Group className='mb-3 col-6'>
									<Form.Label>Deudor</Form.Label>
									<Form.Select value={debt.owner ? debt.owner._id : null} disabled>
										{accounts.map((account) => {
											return (
												<option key={account._id} value={account._id}>
													{account.name}
												</option>
											);
										})}
										<option key='blankChoice' hidden value>
											{' '}
											Elige la Cuenta vinculada
										</option>
									</Form.Select>
								</Form.Group>
								<Form.Group as={Col} className='mb-3 col-6' controlId='currency'>
									<Form.Label>Moneda</Form.Label>
									<Form.Select as='select' disabled value={debt.currency}>
										<option key='default'>Elige Moneda</option>
										{currencies.map((currency, index) => {
											return (
												<option key={index} value={currency}>
													{currency}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>

								<Form.Group className='mb-3 col-4 form-group required'>
									<Form.Label>Periodicidad</Form.Label>
									<Form.Select
										as='select'
										value={debt.periodicity ? debt.periodicity : null}
										disabled
									>
										<option key={0}>Elige la periodicidad</option>
										{periodicities.map((periodicity, index) => {
											return (
												<option key={index} value={periodicity}>
													{periodicity}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>
								{debt.periodicity === 'otra' && (
									<Form.Group className='mb-3 col-md-3' controlId='periodicityNumMonths'>
										<Form.Label>Periodicidad en meses</Form.Label>
										<Form.Control
											type='number'
											value={debt.periodicityNumMonths}
											disabled
										></Form.Control>
									</Form.Group>
								)}
								{debt.concept === 'alquileres' && (
									<Form.Group className='mb-3 col-3 form-group' controlId='endDate'>
										<Form.Label>Fecha Finalizacion de Contrato</Form.Label>
										<Form.Control
											type='date'
											disabled
											value={debt.closeDate ? moment(debt.closeDate).format('YYYY-MM-DD') : null}
										></Form.Control>
									</Form.Group>
								)}
								{debt.concept &&
									(debt.concept.includes('prestamos') || debt.concept.includes('ventas')) && (
									<>
										<Form.Group className='mb-3 col-3 form-group required' controlId='amount'>
											<Form.Label>Capital</Form.Label>
											<Form.Control type='number' value={debt.capital} disabled></Form.Control>
										</Form.Group>
										<Form.Group className='mb-3 col-2 form-group required' controlId='amount'>
											<Form.Label>Interes %</Form.Label>
											<Form.Control
												type='number'
												defaultValue={debt.interest}
												disabled
											></Form.Control>
										</Form.Group>
										{debt.concept !== 'prestamos-amortizable' &&
												!debt.concept.includes('ventas') && (
											<Form.Group className='mb-3 col-3 form-group' controlId='endDate'>
												<Form.Label>Fecha de fin</Form.Label>
												<Form.Control
													type='date'
													disabled
													value={
														debt.endDate ? moment(debt.endDate).format('YYYY-MM-DD') : null
													}
												></Form.Control>
											</Form.Group>
										)}
										{(debt.concept === 'prestamos-amortizable' ||
												debt.concept.includes('ventas')) && (
											<Form.Group className='mb-3 col-2' controlId='dues'>
												<Form.Label>Cuotas</Form.Label>
												<Form.Control
													type='number'
													defaultValue={debt.dues}
													disabled
												></Form.Control>
											</Form.Group>
										)}
									</>
								)}
								<Card className='d-flex mb-3 flex-row align-items-center justify-content-evenly'>
									<Form.Group className='mb-3 col-2 form-group required' controlId='amount'>
										<Form.Label>
											Monto {debt.concept && debt.concept.includes('prestamos') ? 'cuota' : ''}
										</Form.Label>
										<Form.Control type='number' value={debt.amount} disabled></Form.Control>
									</Form.Group>
								</Card>
							</Row>
						</Card>
					</div>
					<Card className='d-flex  justify-content-between mb-3'>
						<Row>
							<h2 className='text-center mb-3'>Registro fuera del sistema</h2>
						</Row>
						<Row className='d-flex flex-row align-items-center'>
							<OverlayTrigger
								placement='left'
								overlay={
									<Tooltip id='tooltip-previous-debt'>
										Los datos que se completan aqui son únicamente informativos, con el fin de
										registrar la contabilidad fuera del sistema
									</Tooltip>
								}
							>
								<Form.Group
									className=' col-3 form-group d-flex align-items-center'
									controlId='hasPreviousDebts'
									style={{ height: '60px' }}
								>
									<Form.Check
										className='d-flex align-items-center'
										type='checkbox'
										label='Tiene deuda anterior?'
										checked={debt.hasPreviousDebts}
										disabled
									/>
								</Form.Group>
							</OverlayTrigger>
							<Form.Group className='mb-3 col-4 form-group ' controlId='previousDebt'>
								<Form.Label>Deuda Anterior</Form.Label>
								<Form.Control
									type='number'
									defaultValue={debt.previousDebt}
									disabled
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3 col-4 form-group ' controlId='previousDebtDate'>
								<Form.Label>Fecha deuda anterior</Form.Label>
								<Form.Control
									type='date'
									value={
										debt.previousDebtDate
											? moment(debt.previousDebtDate).utc().format('YYYY-MM-DD')
											: null
									}
									disabled
								></Form.Control>
							</Form.Group>
						</Row>
					</Card>
				</Form>
			</div>
		</div>
	);
}
export default DebtInfo;
