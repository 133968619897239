import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, gotasettings: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};
const DataInfo = () => {
	const [{ loading, error }, dispatch] = useReducer(reducer, {
		loading: true,
		error: '',
	});
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo } = state;
	const [ipc, setIpc] = useState();
	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await axios.get('/api/config/public');
				setIpc(data.ipc);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, []);
	const setGotasettings = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			const { data } = await axios.put(
				'/api/config/ipc',
				{
					ipc,
				},
				{
					headers: { Authorization: `Bearer ${userInfo.token}` },
				},
			);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'UPDATE_SUCCESS', payload: data });
			toast.success('Empresa actualizada Correctamente');
		} catch (error) {
			dispatch({ type: 'FETCH_FAIL' });
			toast.error(error.message);
		}
	};
	return (
		<div className='Admin-info'>
			<div className='container  ps-0 mt-2'>
				{(loading) ? (
					<LoadingBox></LoadingBox>
				) : (
					error ? (
						<MessageBox variant='danger'>{error}</MessageBox>
					) : null
				)}
				<Form>
					<Form.Group as={Row} className='mb-3 align-items-center' controlId='ipcUpdated'>
						<Form.Label column md={3}>
							Porcentaje(%)
						</Form.Label>
						<Col md={5}>
							<Form.Control
								col={6}
								type='number'
								step='0.01'
								max='100'
								onChange={(e) => setIpc(e.target.value)}
								defaultValue={ipc}
							/>
						</Col>
					</Form.Group>
					<Row>
						<Col>
							<Button md={2} onClick={setGotasettings}>
								Actualizar
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	);
};
export default DataInfo;
