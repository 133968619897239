import axios from 'axios';
import { customHistory } from './CustomBrowserRouter';
import axiosInstance from './RestClient.js';

export const getError = (error) => {
	return error.response && error.response.data.message
		? error.response.data.message
		: error.message;
};
export const getStatus = (error) => {
	return error.response && error.response.status
		? error.response.status
		: error.message;
};
export const showCurrency = (currency, capital, list) => {
	if (list === 'debts') {
		if (!capital || capital === '' || capital === null) {
			return '';
		} else if (currency === 'UYU') {
			return '$U';
		} else if (currency === 'USD') {
			return 'U$S';
		}
	} else {
		if (currency === 'UYU') {
			return '$U';
		} else if (currency === 'USD') {
			return 'U$S';
		}
	}
};
//lists for dropdowns
export const types = ['Persona', 'Empresa'];
export const concepts = ['prestamos-amortizable', 'prestamos-interés', 'alquileres', 'ventas-a-plazo'];
export const periodicities = ['mensual', 'semestral', 'anual', 'otra'];
export const currencies = ['USD', 'UYU'];
export const monthOptions = ['Mes actual', 'Mes siguiente', 'En tres meses', 'En seis meses', 'Otro'];
export const dueDays = Array.from({ length: 31 }, (_, index) => (index + 1).toString());
/**
 * @param {String} hex Six digit hexadecimal color
 * @param {Number} alpha Number between 0 and 1
 */
export const addAlphaToHex = (hex, alpha) => {
	let convertedColor = hex;
	if (hex.length === 6 && alpha > 0 && alpha <= 1) {
		const hexValues = hex.match(/.{1,2}/g);
		const rgbArray = [
			parseInt(hexValues[0], 16),
			parseInt(hexValues[1], 16),
			parseInt(hexValues[2], 16),
		];
		convertedColor = `rgb(${rgbArray[0]},${rgbArray[1]},${rgbArray[2]},${alpha})`;
	}
	return convertedColor;
};

export const regexEmail =
	/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export async function refreshToken() {
	const refresTkn = localStorage.getItem('refreshToken');
	const response = await axiosInstance.post('/auth/refreshToken', { refresTkn });
	const { newRefreshToken, tokenn } = response.data;
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	userInfo.token = tokenn;
	localStorage.removeItem('userInfo');
	localStorage.setItem('userInfo', JSON.stringify(userInfo));
	if (newRefreshToken) {
		localStorage.removeItem('refreshToken');
		localStorage.setItem('refreshToken', newRefreshToken);
	}
	return tokenn;
}

export const signoutHandler = async () => {
	const refreshToken = localStorage.getItem('refreshToken');
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	if (userInfo && refreshToken) {
		try {
			await axios.post('/api/auth/logout', {
				refreshToken,
				userId: userInfo._id,
			});
		} catch (error) {
			console.error('SIGNOUT HANDLER ERROR: ', error);
		}
	}
	if (userInfo) {
		localStorage.removeItem('userInfo');
	}
	if (refreshToken) {
		localStorage.removeItem('refreshToken');
	}
	customHistory.push('/signin');
};

export const logoutHandler = async () => {
	// const { state, dispatch: ctxDispatch } = useContext(Store);

	const refreshToken = localStorage.getItem('refreshToken');
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	if (userInfo && refreshToken) {
		await axios.post('/api/auth/logout', {
			refreshToken,
			userId: userInfo._id,
		});
	}
	// localStorage.removeItem("userInfo");
	// localStorage.removeItem("refreshToken");
	localStorage.clear();
	customHistory.push('/signin');
};

export function getToken() {
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	const token = userInfo ? userInfo.token : null;
	return token;
}

export function getUserInfo() {
	const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	return userInfo;
}