import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Card, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Helmet } from 'react-helmet-async';
import { MdLibraryAdd, MdWarning } from 'react-icons/md';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import CustomTypeahead from '../../components/CustomTypeahead';
import MessageBox from '../../components/MessageBox';
import FormatedNumberInput from '../../components/FormatedNumberInput';
import LoadingBox from '../../components/LoadingBox';
import GenericDropdown from '../../components/SelectDropdown';
import accountService from '../../services/account.service';
import assetService from '../../services/asset.service';
import contractService from '../../services/contract.service';
import { currencies, dueDays, getError, monthOptions, periodicities } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return { ...state, loadingFetch: false };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, error: action.payload };
	case 'FETCH_ACCOUNTS_SUCCESS':
		return { ...state, accounts: action.payload};
	case 'FETCH_ASSETS_SUCCESS':
		return { ...state, assets: action.payload};
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function AddContract() {
	const { state: locationState } = useLocation();
	const { currentContract } = locationState || {};

	const navigate = useNavigate();
	const { state } = useContext(Store);

	const [periodInit, setPeriodInit] = useState();
	const [periodEnd, setPeriodEnd] = useState();
	const [amount, setAmount] = useState();
	const [owner, setOwner] = useState();
	const [field, setField] = useState();
	const [rental] = useState(true);
	const [property, setProperty] = useState();
	const [ubication] = useState();
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();
	const [paymentMethod, setPaymentMethod] = useState();
	const [currency, setCurrency] = useState();
	const [hasPreviousDebts] = useState(false);
	const [previousDebt] = useState();
	const [previousDebtDate] = useState();
	const [disabledOwner, setDisabledOwner] = useState(false);
	const [disabledProperty, setDisabledProperty] = useState(false);

	//for debt generation
	const [periodicity, setPeriodicity] = useState('mensual');
	const [periodicityNumMonths, setPeriodicityNumMonths] = useState();
	const currentDate = moment.utc();
	const [createWithPreviousDues, setCreateWithPreviousDues] = useState(false);
	const [createWithPreviousPayments, setCreateWithPreviousPayments] = useState(false);
	const [dueDay, setDueDay] = useState('10'); //first due generation year always next to selected date
	const [firstDueExpiration, setFirstDueExpiration] = useState(); //first due generation date always next to selected date
	const [numMonths, setNumMonths] = useState();
	const [selectedMonthOption, setSelectedMonthOption] = useState('Mes actual');
	const [date, setDate] = useState();

	const [{ loadingFetch, loadingCreate, accounts, assets, error: errorFetch}, dispatch] = useReducer(reducer, {
		loadingFetch: false,
		accounts: [],
		assets: [],
		loadingCreate: false,
		error: '',
	});

	const params = useParams();
	const { id } = params;
	const location = useLocation();
	const route = location.pathname;

	useEffect(() => {
		if (currentContract) {
			setProperty(currentContract.property);
			setOwner(currentContract.owner);
			setPeriodInit(currentContract.periodInit);
			setPeriodEnd(currentContract.periodEnd);
			setAmount(currentContract.amount);
			setCurrency(currentContract.currency);
			setField(currentContract.field);
			setFolder(currentContract.folder);
			setPaymentMethod(currentContract.paymentMethod);
			setObservations(currentContract.observations);
		}
		fetchData();
	}, []);

	async function fetchData() {
		dispatch({ type: 'FETCH_REQUEST'});
		try {
			await Promise.all([fetchAccounts(), fetchAssets()]);
			dispatch({ type: 'FETCH_SUCCESS'});
		} catch(ex) {
			console.error(ex);
			dispatch({ type: 'FETCH_FAIL', payload: getError(ex) });
		}
	}
	const fetchAccounts = async () => {
		const result = await accountService.list();
		if (route.includes('account')) {
			const selectedAccount = result.data.filter((accountData) => accountData._id === id);
			setOwner(selectedAccount[0]);
			setDisabledOwner(true);
			setCurrency(selectedAccount[0].currency);
		}
		dispatch({ type: 'FETCH_ACCOUNTS_SUCCESS', payload: result.data });
	};

	const fetchAssets = async () => {
		const { data } = await assetService.list();
		if (route.includes('asset')) {
			const selectedAsset = data.filter((assetData) => assetData._id === id);
			setProperty(selectedAsset[0]);
			setFolder(selectedAsset[0].folder);
			setDisabledProperty(true);
		}
		dispatch({ type: 'FETCH_ASSETS_SUCCESS', payload: data });
	};

	const addPaymentHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			if (!owner) {
				toast.error('Debe seleccionar una cuenta como inquilino');
				return;
			}
			if (!property) {
				toast.error('Debe seleccionar una cuenta como propietario');
				return;
			}
			const requestData = {
				date,
				amount,
				periodInit,
				periodEnd,
				owner: owner[0] || owner,
				field,
				rental,
				property: property[0] || property,
				ubication,
				folder,
				observations,
				paymentMethod,
				currency,
				periodicity,
				dueDay,
				selectedMonthOption,
				firstDueExpiration: moment.utc(firstDueExpiration),
				createWithPreviousDues,
				createWithPreviousPayments,
				createdBy: state.userInfo._id,
			};
			if (periodicity === 'otra') {
				requestData.periodicityNumMonths = periodicityNumMonths;
			}
			if (hasPreviousDebts) {
				requestData.hasPreviousDebts = hasPreviousDebts;
				requestData.previousDebt = previousDebt;
				requestData.previousDebtDate = previousDebtDate;
			}
			//check if is dont 'hasPreviousDebts' to unset other fields
			if (!hasPreviousDebts) {
				requestData.hasPreviousDebts = false;
				requestData.previousDebt = '';
				requestData.previousDebtDate = '';
			}
			if (selectedMonthOption === 'otro') {
				requestData.numMonths = numMonths;
			}

			await contractService.create({
				...requestData,
				createWithPreviousDues,
				createWithPreviousPayments,
			});
			dispatch({ type: 'CREATE_SUCCESS' });
			toast.success('Contrato creado');
			navigate(-1);
		} catch (ex) {
			toast.error(getError(ex));
			dispatch({ type: 'CREATE_FAIL' });
		}
	};

	const [isInvalid, setIsInvalid] = useState(false);
	const [isPropertyInvalid, setIsPropertyInvalid] = useState(false);

	const handleAccountChange = (selected) => {
		const selectedAccount = selected[0];
		const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
		setIsInvalid(!isValid);
		if (isValid) {
			setOwner(selected[0]);
		} else {
			setOwner(null);
		}
	};

	const handlePropertyChange = (selected) => {
		if (!selected || selected.length === 0) {
			// Reset the state variables if nothing is selected
			const selectedProperty = selected[0];
			const isPropertyInvalid = selectedProperty ? assets.includes(selectedProperty) : false;
			setIsPropertyInvalid(!isPropertyInvalid);
			setProperty(null);
			setFolder('');
		} else {
			const selectedProperty = selected[0];
			const isPropertyInvalid = selectedProperty ? assets.includes(selectedProperty) : false;
			setIsPropertyInvalid(!isPropertyInvalid);
			setProperty(selectedProperty);
			setFolder(selectedProperty.folder);
		}
	};
	const isAddContractButtonEnabled = () => {
		const emptyFields = [];
		if (!owner) {
			emptyFields.push('inquilino');
		}
		if (!property) {
			emptyFields.push('propiedad');
		}
		if (!periodInit) {
			emptyFields.push('inicio');
		}
		if (!amount) {
			emptyFields.push('monto');
		}
		return emptyFields.length === 0;
	};

	const dateHandler = () => {
		if (moment.utc(firstDueExpiration).isBefore(moment.utc(currentDate))) {
			setCreateWithPreviousDues(true);
		} else {
			setCreateWithPreviousDues(false);
			setCreateWithPreviousPayments(false);
		}
	};

	const generateDate = () => {
		const currentDate = moment.utc(date, 'YYYY-MM-DD');
		let futureDate;

		if (selectedMonthOption === 'Mes actual') {
			futureDate = currentDate.clone();
		} else if (selectedMonthOption === 'Mes siguiente') {
			futureDate = currentDate.clone().add(1, 'month');
		} else if (selectedMonthOption === 'En tres meses' || selectedMonthOption === 'En seis meses') {
			const monthsToAdd = selectedMonthOption === 'En tres meses' ? 3 : 6;
			futureDate = currentDate.clone().add(monthsToAdd, 'month');
		} else if (selectedMonthOption === 'Otro') {
			futureDate = currentDate.clone().add(numMonths, 'month');
		}

		futureDate.date(Math.min(dueDay, futureDate.daysInMonth()));

		setFirstDueExpiration(moment.utc(futureDate).format('YYYY-MM-DD'));
		return firstDueExpiration;
	};
	useEffect(() => {
		generateDate();
		dateHandler();
	}, [date, selectedMonthOption, dueDay, numMonths]);

	return (
		<div>
			<Modal size='xl' show={true} animation={false}>
				{loadingFetch && <LoadingBox></LoadingBox>}
				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Agregar Alquiler</title>
					</Helmet>
					<h1>
						<MdLibraryAdd></MdLibraryAdd>Agregar Alquiler
					</h1>
					{!errorFetch ? <Form onSubmit={(e) => e.preventDefault()}>
						{loadingCreate && <LoadingBox></LoadingBox>}
						<div className='row align-items-center'>
							<Card className='d-flex mb-3 justify-content-center card-form-debts'>
								<Row>
									<h2 className='text-center m-3'> Datos para la generación de cuotas</h2>
								</Row>
								<Row className='d-flex mb-3 justify-content-center'>
									<OverlayTrigger
										placement='left'
										overlay={
											<Tooltip id='tooltip-previous-debt'>
												<MdWarning />
												Es la fecha de inicio de la deuda, si usted selecciona una fecha anterior al
												día de hoy se crearán las cuotas pasadas, si no es lo que desea seleccione
												una fecha igual o mayor a la de hoy.
											</Tooltip>
										}
									>
										<Form.Group className='mb-3 col-3 form-group required' controlId='Date'>
											<Form.Label>Fecha de inicio de deuda</Form.Label>
											<Form.Control
												type='date'
												onChange={(e) => setDate(e.target.value)}
											></Form.Control>
										</Form.Group>
									</OverlayTrigger>
									<Form.Group className='mb-3 col-3 form-group required' controlId='DaysDropdown'>
										<GenericDropdown
											label='Día vencimiento de 1er cuota:'
											items={dueDays}
											selected={dueDay}
											onChange={(e) => setDueDay(e.value)}
										/>
									</Form.Group>
									<Form.Group
										className='mb-3 col-2 form-group required'
										controlId='selectedMonthOption'
									>
										<GenericDropdown
											label='Generar a partir de:'
											items={monthOptions}
											selected={selectedMonthOption}
											onChange={(e) => setSelectedMonthOption(e.value)}
										/>
									</Form.Group>
									{selectedMonthOption === 'Otro' && (
										<Form.Group className='mb-3 col-3 form-group required' controlId='numMonths'>
											<Form.Label>Ingrese en cuantos meses:</Form.Label>
											<Form.Control
												type='number'
												min='24'
												value={numMonths}
												onChange={(e) => setNumMonths(parseInt(e.target.value, 10))}
												onInput={(e) => {
													const inputValue = parseInt(e.target.value, 10);
													const maxValue = 24;
													if (maxValue !== null && inputValue > maxValue) {
														e.target.value = maxValue;
													}
												}}
											/>
										</Form.Group>
									)}
									<Form.Group className='mb-3 col-md-4 form-group required'>
										<GenericDropdown
											label='Periodicidad'
											items={periodicities}
											selected={periodicity}
											onChange={(e) => setPeriodicity(e.value)}
										/>
									</Form.Group>
									{periodicity === 'otra' && (
										<Form.Group className='mb-3 col-md-3' controlId='periodicityNumMonths'>
											<Form.Label>Periodicidad en meses</Form.Label>
											<FormatedNumberInput
												type='number'
												onChange={(e) => setPeriodicityNumMonths(e.target.value)}
												min={1}
												noDecimal={true}
											></FormatedNumberInput>
										</Form.Group>
									)}
								</Row>
								<Row>
									<div className='d-flex d-inline justify-content-center align-items-center'>
										<p>
											{date
												? `Primer Vencimiento: ${moment.utc(firstDueExpiration).format('DD-MM-YYYY')}  `
												: ''}
										</p>
									</div>
								</Row>
							</Card>
							{createWithPreviousDues ? (
								<Card className='d-flex align-items-center justify-content-center mb-3 card-form-debts'>
									<Row>
										<h2 className='text-center m-3'>
											<MdWarning />
											Deuda con cuotas previas
										</h2>
									</Row>
									<Row>
										<p>
											Usted está por crear una deuda con fecha anterior a la fecha de hoy, si no
											desea crear cuotas previas debe ingresar una fecha igual o posterior al día de
											hoy
										</p>
									</Row>
									<Form.Group
										as={Row}
										className='mb-3 align-items-center col-8'
										controlId='createWithPreviousPayments'
									>
										<Form.Check
											type='checkbox'
											className='mb-3 align-items-center col-5 justify-content-center text-center'
											label='Marcar cuotas previas como pagas'
											checked={createWithPreviousPayments}
											onChange={() => setCreateWithPreviousPayments(!createWithPreviousPayments)}
										/>
									</Form.Group>
								</Card>
							) : (
								''
							)}
							<Card className='d-flex justify-content-center mb-3 card-form-debts'>
								<Row>
									<h2 className='text-center m-3'>Datos Contractuales</h2>
								</Row>
								<Row>
									<Form.Group className='mb-3 col-6 form-group required '>
										<Form.Label>Propiedad</Form.Label>
										<CustomTypeahead
											id='assets'
											labelKey={(option) => `${option.name}`}
											onChange={handlePropertyChange}
											options={assets}
											disabled={disabledProperty}
											selected={property ? [property] : null}
											placeholder='Buscar una propiedad...'
											linkToCreate='/AdminScreen/newAsset'
											newOption='nueva propiedad'
										/>
										{isPropertyInvalid && <div style={{ color: 'red' }}>Propiedad invalida</div>}
									</Form.Group>
									<Form.Group className='mb-3  col-6 form-group required'>
										<Form.Label>Inquilino</Form.Label>
										<CustomTypeahead
											id='accounts'
											labelKey={(option) => `${option.name}`}
											onChange={handleAccountChange}
											options={accounts}
											disabled={disabledOwner}
											selected={owner ? [owner] : null}
											placeholder='Buscar Inquilino... '
											isInvalid={isInvalid}
											linkToCreate='/AdminScreen/newAccount'
											newOption='nueva cuenta de inquilino'
										/>
										{isInvalid && <div style={{ color: 'red' }}>Cuenta invalida</div>}
									</Form.Group>
								</Row>
								<Row>
									<Form.Group className='mb-3 col-3 form-group required' controlId='date'>
										<Form.Label>Inicio</Form.Label>
										<Form.Control
											type='date'
											defaultValue={periodInit ? moment.utc(periodInit).format('YYYY-MM-DD') : null}
											onChange={(e) => setPeriodInit(e.target.value)}
										></Form.Control>
									</Form.Group>
									<Form.Group className='mb-3 col-3 form-group' controlId='date'>
										<Form.Label>Fin</Form.Label>
										<Form.Control
											type='date'
											defaultValue={periodEnd ? moment.utc(periodEnd).format('YYYY-MM-DD') : null}
											onChange={(e) => setPeriodEnd(e.target.value)}
										></Form.Control>
									</Form.Group>
									<Form.Group className='mb-3 col-3 form-group required' controlId='amount'>
										<Form.Label>Monto</Form.Label>
										<FormatedNumberInput
											type='number'
											value={amount}
											onChange={(e) => setAmount(e.target.value)}
											min={0}
										></FormatedNumberInput>
									</Form.Group>
									<Form.Group className='mb-3 col-3 ' controlId='currency'>
										<GenericDropdown
											label='Moneda'
											items={currencies}
											selected={currency}
											onChange={(e) => setCurrency(e.value)}
										/>
									</Form.Group>
								</Row>
								<Form.Group
									style={{ marginBottom: '5%' }}
									className='mb-3 col-12'
									controlId='field'
								>
									<Form.Label>Detalles</Form.Label>
									<Form.Control
										type='text'
										as='textarea'
										rows={10}
										defaultValue={field ? field : null}
										onChange={(e) => setField(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3' controlId=''>
									<Form.Label>Carpeta</Form.Label>
									<Form.Control
										type='text'
										defaultValue={folder ? folder : null}
										onChange={(e) => setFolder(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Form.Group className='mb-3' controlId=''>
									<Form.Label>Forma de cobro</Form.Label>
									<Form.Control
										type='text'
										defaultValue={paymentMethod ? paymentMethod : null}
										onChange={(e) => setPaymentMethod(e.target.value)}
									></Form.Control>
								</Form.Group>
								<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
									<Form.Label>Observaciones</Form.Label>
									<Form.Control
										type='text'
										as='textarea'
										rows={10}
										defaultValue={observations ? observations : null}
										onChange={(e) => setObservations(e.target.value)}
									></Form.Control>
								</Form.Group>
							</Card>
						</div>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button
									className='guardar'
									disabled={!isAddContractButtonEnabled()}
									onClick={addPaymentHandler}
								>
									Agregar
								</Button>
							</Col>
						</Row>
					</Form> : 
						<MessageBox variant = "danger">{errorFetch}</MessageBox>
					}
				</div>
			</Modal>
		</div>
	);
}
export default AddContract;
